import styles from "@components/Common/Header/Header.module.scss";
import Row from "@components/Modules/Utils/Row/Row";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import { MenuItemModel } from "../Menu.model";
import MenuItemExpanded from "../MenuItem/MenuItemExpanded";
import { MenuItemCard } from "../MenuItemCard";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";

export const LayoutJournal: React.FC<{item: MenuItemModel}> = ({item}) => {
  
  const items: MenuItemModel[] = item?.items.filter(el => el?.field_layout !== 'card')
  const cards: MenuItemModel[] = item?.items.filter(el => el?.field_layout === 'card')
  return (
    <Box className={styles.dropdownMenu}>
      <Container maxWidth={false}>
        <Row spacing={0} justifyContent={'space-between'}>
          
          {cards?.map(card => (
            <Grid key={card.id} item lg={4}>
              <MenuItemCard item={card} />
            </Grid>
          ))}

          <Grid item lg={4} position="relative" ml={'auto'}>
            
            <List disablePadding component="div">

              {items && items[0]?.description && (
                <ListItemButton sx={{
                  "&:hover": {
                    cursor: "initial",
                    bgcolor: 'transparent'
                  }
                }}>
                  <ListItemText
                    primary={items[0]?.description}
                    primaryTypographyProps={{
                      variant: 'bodyS',
                      color: 'secondary',
                      fontWeight: '500',
                      textTransform: 'uppercase'
                    }}
                  />
                </ListItemButton>
              )}

              {items?.map((item: MenuItemModel) => <MenuItemExpanded key={item.id} item={item} />)}
            </List>
          </Grid>

        </Row>
      </Container>
    </Box>
  )
}
export default LayoutJournal