export default function RuoloAzienda() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M76.397 74.915c8.035 0 14.55-6.514 14.55-14.55 0-8.034-6.515-14.548-14.55-14.548s-14.55 6.514-14.55 14.549 6.515 14.55 14.55 14.55zM41.28 92c6.887 0 12.47-5.583 12.47-12.47 0-6.888-5.583-12.471-12.47-12.471-6.888 0-12.471 5.583-12.471 12.47 0 6.888 5.583 12.471 12.47 12.471zM34.785 53.57c11.479 0 20.784-9.306 20.784-20.785C55.57 21.305 46.264 12 34.785 12 23.305 12 14 21.306 14 32.785c0 11.479 9.306 20.784 20.785 20.784z"
      ></path>
      <path
        fill="#fff"
        d="M79.128 63.691H58.344L62.5 17.965h12.47l4.157 45.727zM53.148 63.691H32.363l4.157-45.726h12.471l4.157 45.727z"
      ></path>
      <path fill="#fff" d="M82.248 63.691H44.836v20.785h37.412V63.691z"></path>
      <path
        fill="#BAE1A2"
        d="M55.227 70.966h27.02v13.51H53.148V73.045c0-1.144.936-2.079 2.079-2.079z"
      ></path>
      <path
        fill="#284B9C"
        d="M82.246 85.256H44.834a.785.785 0 01-.78-.78V63.691c0-.426.354-.779.78-.779h37.412c.427 0 .78.353.78.78v20.784c0 .426-.353.78-.78.78zm-36.632-1.56h35.853V64.472H45.614v19.226z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M75.356 22.122h-5.663a2.08 2.08 0 00-2.079 1.985l-1.798 39.584H79.14l-3.782-41.569z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M49.376 22.122h-5.664a2.08 2.08 0 00-2.078 1.985l-1.798 39.584h13.323l-3.783-41.569z"
      ></path>
      <path
        fill="#284B9C"
        d="M79.127 64.47H58.343a.766.766 0 01-.769-.842l4.157-45.736a.78.78 0 01.78-.706h12.47c.405 0 .738.312.78.706l4.156 45.727a.766.766 0 01-.769.841l-.02.01zm-19.932-1.558h19.08l-4.011-44.167H63.217l-4.012 44.167h-.01zM53.147 64.47H32.362a.766.766 0 01-.77-.842l4.148-45.736a.78.78 0 01.78-.706h12.47c.405 0 .738.312.78.706l4.156 45.727a.765.765 0 01-.769.841l-.01.01zm-19.932-1.558h19.08l-4.012-44.167H37.236l-4.011 44.167h-.01z"
      ></path>
      <path
        fill="#fff"
        d="M76.398 33.554H61.09l.571-6.236h14.165l.572 6.236zM50.428 33.554H35.109l.562-6.236h14.175l.582 6.236z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M76.106 30.436H65.713c-.54 0-.987.405-1.039.946l-.197 2.172h11.899l-.28-3.118h.01z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M50.133 30.436H39.74c-.54 0-.988.405-1.04.946l-.197 2.172h11.92l-.291-3.118z"
      ></path>
      <path
        fill="#284B9C"
        d="M76.397 34.333H61.089a.786.786 0 01-.572-.25.779.779 0 01-.197-.592l.572-6.235a.78.78 0 01.779-.707h14.175c.405 0 .738.312.78.707l.571 6.235a.765.765 0 01-.769.842h-.031zM61.94 32.774h13.593l-.426-4.676H62.357l-.426 4.676h.01zM50.425 34.333H35.097a.786.786 0 01-.572-.25.779.779 0 01-.197-.592l.571-6.235a.78.78 0 01.78-.707h14.175a.78.78 0 01.78.707l.581 6.235a.802.802 0 01-.197.603.758.758 0 01-.572.26l-.02-.02zM35.96 32.774h13.614l-.436-4.676H36.385l-.426 4.676zM84.325 85.255h-66.51a.785.785 0 01-.78-.779c0-.426.354-.78.78-.78h66.51c.426 0 .78.354.78.78 0 .426-.354.78-.78.78z"
      ></path>
      <path fill="#fff" d="M44.836 42.907H19.895v41.569h24.941v-41.57z"></path>
      <path
        fill="#B8D6EC"
        d="M28.207 50.181h16.628v34.295H26.13V52.26c0-1.143.935-2.079 2.078-2.079z"
      ></path>
      <path
        fill="#284B9C"
        d="M44.834 85.255H19.893a.785.785 0 01-.78-.779v-41.57c0-.425.354-.779.78-.779h24.941c.426 0 .78.354.78.78v41.57c0 .425-.354.778-.78.778zm-24.162-1.558h23.383v-40.01H20.672v40.01zM61.461 80.06H50.029a.785.785 0 01-.779-.78c0-.426.353-.78.78-.78H61.46c.426 0 .78.354.78.78 0 .426-.354.78-.78.78zM77.05 80.06H65.62a.785.785 0 01-.78-.78c0-.426.353-.78.78-.78h11.43c.427 0 .78.354.78.78 0 .426-.353.78-.78.78zM61.461 74.863H50.029a.785.785 0 01-.779-.78c0-.425.353-.779.78-.779H61.46c.426 0 .78.353.78.78 0 .426-.354.779-.78.779zM77.05 74.863H65.62a.785.785 0 01-.78-.78c0-.425.353-.779.78-.779h11.43c.427 0 .78.353.78.78 0 .426-.353.779-.78.779zM61.461 69.667H50.029a.785.785 0 01-.779-.78c0-.425.353-.779.78-.779H61.46c.426 0 .78.353.78.78 0 .426-.354.779-.78.779zM77.05 69.667H65.62a.785.785 0 01-.78-.78c0-.425.353-.779.78-.779h11.43c.427 0 .78.353.78.78 0 .426-.353.779-.78.779z"
      ></path>
      <path
        fill="#284B9C"
        d="M30.284 80.06h-5.196a.785.785 0 01-.78-.78c0-.426.354-.78.78-.78h5.196c.426 0 .78.354.78.78 0 .426-.354.78-.78.78zM39.64 80.06h-5.197a.785.785 0 01-.779-.78c0-.426.353-.78.78-.78h5.196c.426 0 .78.354.78.78 0 .426-.354.78-.78.78zM30.284 74.863h-5.196a.785.785 0 01-.78-.78c0-.425.354-.779.78-.779h5.196c.426 0 .78.353.78.78 0 .426-.354.779-.78.779zM39.64 74.863h-5.197a.785.785 0 01-.779-.78c0-.425.353-.779.78-.779h5.196c.426 0 .78.353.78.78 0 .426-.354.779-.78.779zM30.284 69.667h-5.196a.785.785 0 01-.78-.78c0-.425.354-.779.78-.779h5.196c.426 0 .78.353.78.78 0 .426-.354.779-.78.779zM39.64 69.667h-5.197a.785.785 0 01-.779-.78c0-.425.353-.779.78-.779h5.196c.426 0 .78.353.78.78 0 .426-.354.779-.78.779zM30.284 64.471h-5.196a.785.785 0 01-.78-.78c0-.426.354-.779.78-.779h5.196c.426 0 .78.353.78.78 0 .426-.354.779-.78.779zM39.64 64.471h-5.197a.785.785 0 01-.779-.78c0-.426.353-.779.78-.779h5.196c.426 0 .78.353.78.78 0 .426-.354.779-.78.779zM30.284 59.275h-5.196a.785.785 0 01-.78-.78c0-.426.354-.78.78-.78h5.196c.426 0 .78.354.78.78 0 .426-.354.78-.78.78zM39.64 59.275h-5.197a.785.785 0 01-.779-.78c0-.426.353-.78.78-.78h5.196c.426 0 .78.354.78.78 0 .426-.354.78-.78.78zM30.284 54.078h-5.196a.785.785 0 01-.78-.779c0-.426.354-.78.78-.78h5.196c.426 0 .78.354.78.78 0 .426-.354.78-.78.78zM39.64 54.078h-5.197a.785.785 0 01-.779-.779c0-.426.353-.78.78-.78h5.196c.426 0 .78.354.78.78 0 .426-.354.78-.78.78zM30.284 48.882h-5.196a.785.785 0 01-.78-.78c0-.425.354-.778.78-.778h5.196c.426 0 .78.353.78.779 0 .426-.354.78-.78.78zM39.64 48.882h-5.197a.785.785 0 01-.779-.78c0-.425.353-.778.78-.778h5.196c.426 0 .78.353.78.779 0 .426-.354.78-.78.78zM21.971 37.45h-4.156a.785.785 0 01-.78-.779c0-.426.354-.779.78-.779h4.156c.427 0 .78.353.78.78 0 .426-.353.779-.78.779z"
      ></path>
      <path
        fill="#284B9C"
        d="M19.893 39.53a.785.785 0 01-.78-.78v-4.157c0-.426.354-.78.78-.78.426 0 .78.354.78.78v4.157c0 .426-.354.78-.78.78zM31.323 29.137h-4.157a.785.785 0 01-.78-.78c0-.425.354-.779.78-.779h4.157c.426 0 .78.354.78.78 0 .426-.354.779-.78.779z"
      ></path>
      <path
        fill="#284B9C"
        d="M29.244 31.215a.785.785 0 01-.78-.779V26.28c0-.426.354-.78.78-.78.426 0 .78.354.78.78v4.157c0 .426-.354.78-.78.78zM56.264 24.98h-4.157a.785.785 0 01-.779-.78c0-.425.353-.779.78-.779h4.157c.426 0 .779.354.779.78 0 .426-.353.779-.78.779z"
      ></path>
      <path
        fill="#284B9C"
        d="M54.19 27.058a.785.785 0 01-.78-.779v-4.157c0-.426.354-.78.78-.78.426 0 .779.354.779.78v4.157c0 .426-.353.78-.78.78zM84.335 36.412h-4.157a.785.785 0 01-.78-.78c0-.426.354-.78.78-.78h4.157c.426 0 .78.354.78.78 0 .426-.354.78-.78.78z"
      ></path>
      <path
        fill="#284B9C"
        d="M82.256 38.49a.785.785 0 01-.78-.78v-4.156c0-.426.354-.78.78-.78.426 0 .78.354.78.78v4.157c0 .426-.354.78-.78.78zM26.128 20.823H21.97a.785.785 0 01-.78-.78c0-.425.354-.779.78-.779h4.157c.426 0 .78.354.78.78 0 .426-.354.779-.78.779z"
      ></path>
      <path
        fill="#284B9C"
        d="M24.049 22.902a.785.785 0 01-.78-.78v-4.157c0-.426.354-.78.78-.78.426 0 .78.354.78.78v4.157c0 .426-.354.78-.78.78zM73.828 51.823a.787.787 0 01-.78-.706l-.998-10.964a.788.788 0 01.707-.852c.426-.042.81.28.842.706l.998 10.964a.788.788 0 01-.707.852h-.073.01zM74.4 58.153a.787.787 0 01-.78-.707l-.218-2.432a.788.788 0 01.707-.852c.415-.042.81.28.842.706l.218 2.432a.788.788 0 01-.707.853h-.073.01z"
      ></path>
    </svg>
  );
}