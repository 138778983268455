import Row from "@components/Modules/Utils/Row/Row";
import Logo from "@components/UI/Logo/Logo";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import Link from "next/link";
import { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from "react";
import styles from './Header.module.scss';
import { renderMainMenu, renderMainMenuMobile } from "@helper/menu";
import IconButton from "@mui/material/IconButton";

import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import { MaterialUISwitch } from "@components/Modules/Utils/SwitchDarkMode/SwitchDarkMode";
import Button from "@mui/material/Button";

import { useTranslation } from "next-i18next";
import { NextRouter, useRouter } from 'next/router';
import { DarkModeContext } from "@contexts/darkMode";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Dialog } from "@mui/material";
import SearchView from "./SearchView";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import React from "react";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  children?: React.ReactElement<any>;
}

function ElevationScroll(props: Props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return children ? React.cloneElement(children, { elevation: trigger ? 19 : 0, }) : null;
}

export default function HeaderNew(props) {
  const { menus, langData } = props;

  const { t } = useTranslation();
  const router: NextRouter = useRouter();

  const headerRef: MutableRefObject<HTMLElement> = useRef(null);

  const { darkMode, setDarkMode } = useContext(DarkModeContext);
  const [lang, setLang] = useState(router.locale)
  const [sticky, setSticky] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [mobileOpen, setMobileOpen] = useState(false);

  const openLangSelector: boolean = Boolean(anchorEl);

  // Search
  const [searchOpen, setSearchOpen] = useState(false);

  const handleSearchOpen = () => {
    setSearchOpen(true);
  };

  const handleSearchClose = () => {
    setSearchOpen(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = async (event) => {
    setAnchorEl(null);

    langData?.map((el) => {
      if (el.lang == event.target.innerText.toLowerCase()) {
        setLang(el.lang);
        delete router.query.slug
        router.push(
          {
            pathname: el.path,
            query: { ...router?.query }
          }, '', {
          locale: el.lang
        }
        )
      }
    })
  };

  const handleScroll = useCallback(() => {
    const header = headerRef.current;
    if (header && window.scrollY > header.offsetHeight * 1.5) {
      setSticky(true);
      document.querySelector("body").classList.add('sticky');
    } else {
      setSticky(false);
      document.querySelector("body").classList.remove('sticky')
    }
  }, []);

  const handleChangeSwitch = (event) => {
    setDarkMode(event.target.checked)
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener("scroll", handleScroll);
      return () => {
        document.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleScroll]);

  useEffect(() => {
    setSearchOpen(false);
    setMobileOpen(false);
  }, [router])

  return (
    <>
      <ElevationScroll {...props}>
        <AppBar ref={headerRef} className={`${styles.header} ${sticky ? styles.headerSticky : ''}`} position="relative" component="header" sx={{ backgroundColor: 'rgba(0, 26, 112, 0.9)', opacity: searchOpen ? 0 : 1, transition: 'all 0.3s ease-in' }}>
          <Toolbar >

            <Container maxWidth={false}>

              {/* First Row */}
              <Row spacing={20} justifyContent={{ xs: "space-between", xl: "flex-start" }}>
                <Grid
                  item
                  xs={6}
                  lg={sticky ? 'auto' : 6}
                  display='flex'
                  alignItems='center'
                >
                  <Box className={styles.logo} display='flex' alignItems='center'>
                    <Link href="/" aria-label="Logo Edison NEXT">
                      <Logo color="#fff" />
                    </Link>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={6}
                  lg={sticky ? 'auto' : 6}
                  display="flex"
                  justifyContent={'flex-end'}
                  gap={{ xs: 20, md: 40 }}
                  alignItems='center'
                  order={sticky ? 3 : 2}
                  className={styles.formControlLabel}
                >

                  <FormControlLabel
                    label={<Typography variant="description">{t('header.darkMode')}</Typography>}
                    labelPlacement="start"
                    control={<MaterialUISwitch onChange={handleChangeSwitch} checked={darkMode} />}
                  />

                  <Box sx={{ display: { xs: 'block', xl: 'none' } }}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="end"
                      onClick={handleDrawerToggle}
                      sx={{ display: { xl: 'none' } }}
                    >
                      <MenuIcon />
                    </IconButton>
                  </Box>

                  {!sticky && (
                    <>
                      <Button
                        id="lang-selector"
                        aria-controls={openLangSelector ? 'lang-selector-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={openLangSelector ? 'true' : undefined}
                        onClick={handleClick}
                        variant="linkwhite"
                        className={styles.langSelector}
                        endIcon={openLangSelector ? <ExpandLess /> : <ExpandMore />}
                        sx={{ display: { xs: 'none', xl: 'inline-flex' } }}
                      >
                        {lang}
                      </Button>
                      <Menu
                        id="lang-selector-menu"
                        anchorEl={anchorEl}
                        open={openLangSelector}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'lang-selector',
                        }}
                      >
                        {langData?.map((el: { lang: string, path: string }) => <MenuItem key={`${el.path}_${el.lang}`} onClick={handleClose}>{el.lang.toUpperCase()}</MenuItem>)}
                      </Menu>

                      <Button
                        component={Link}
                        variant="contained"
                        color="secondary"
                        id="header-contact-us"
                        size="small"
                        href={t('contact_us_url')}
                        sx={{ display: { xs: 'none', xl: 'flex' } }}
                      >{t('header.button')}</Button>
                    </>
                  )}

                </Grid>

                <Grid
                  item
                  xs={sticky ? 'auto' : 6}
                  gap={{ xs: 20, md: 40 }}
                  alignItems='center'
                  className={`${styles.menu} ${styles.mainMenuPrimary}`}
                  order={sticky ? 2 : 3}
                  sx={{ display: { xs: 'none', xl: 'flex' } }}
                >
                  {renderMainMenu(menus.mainMenu)}
                </Grid>

                <Grid
                  item
                  xs={sticky ? 'auto' : 6}
                  justifyContent={'flex-end'}
                  gap={{ xs: 20, md: 40 }}
                  alignItems='center'
                  className={`${styles.menu} ${styles.mainMenuSecondary}`}
                  order={sticky ? 2 : 4}
                  sx={{
                    display: { xs: 'none', xl: 'flex' },
                    marginLeft: sticky ? 'auto' : 0
                  }}
                >
                  {renderMainMenu(menus.mainMenuSecondary)}

                  {!sticky &&
                    <Box sx={{ display: { xs: 'none', xl: 'block' } }}>
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleSearchOpen}
                        sx={{ mr: 2, }}
                        className={styles.noButton}
                      >
                        <SearchIcon />
                      </IconButton>
                    </Box>
                  }

                </Grid>
              </Row>

            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>

      {/* Mobile menu */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        SlideProps={{
          direction: 'left'
        }}
        ModalProps={{
          keepMounted: true,
          disableEnforceFocus: true
        }}
        sx={{
          display: { xs: 'block', xl: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
        }}
      >
        <Box sx={{ textAlign: 'center' }} >
          <Container maxWidth={false} className={styles.header_drawer}>

            <Box className={styles.drawerTop} display='flex' alignItems='center' justifyContent='space-between'>
              <Box className={styles.logo}>
                <Link href={'/'}>
                  <Logo />
                </Link>
              </Box>

              <div className={styles.searchIcon}>
                <IconButton
                  color="inherit"
                  aria-label="open search"
                  onClick={handleSearchOpen}
                >
                  <SearchIcon />
                </IconButton>

                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerToggle}
                  sx={{ display: { xl: 'none' } }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </Box>

            <Box sx={{ mt: 23 }}> </Box>

            <Box sx={{ mt: 23 }} className={styles.mainMenuPrimary}>
              <List>
                {mobileOpen && renderMainMenuMobile(menus.mainMenu, handleDrawerToggle, mobileOpen)}
              </List>
            </Box>
            <Divider />
            <Box>
              <List>
                {mobileOpen && renderMainMenuMobile(menus.mainMenuSecondary, handleDrawerToggle, mobileOpen)}
              </List>
            </Box>

            <Divider />

            <Row pt={27} pb={27} justifyContent={'flex-end'}>
              <Grid item xs={6} sx={{ display: 'flex' }}>
                <Button
                  id="lang-selector-mob"
                  aria-controls={openLangSelector ? 'lang-selector-menu-mobile' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openLangSelector ? 'true' : undefined}
                  onClick={handleClick}
                  variant="link"
                  endIcon={openLangSelector ? <ExpandLess /> : <ExpandMore />}
                  sx={{ minWidth: 'auto' }}
                >{lang}</Button>
                <Menu
                  id="lang-selector-menu-mobile"
                  anchorEl={anchorEl}
                  open={openLangSelector}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'lang-selector-mob',
                  }}
                >
                  {langData?.map((el: { lang: string, path: string }) => <MenuItem key={`${el.path}_${el.lang}`} onClick={handleClose}>{el.lang.toUpperCase()}</MenuItem>)}
                </Menu>
              </Grid>
              <Grid item xs={6} display={'flex'} justifyContent={'flex-end'}>
                <FormControlLabel
                  label={!sticky && <Typography variant="description">{t('header.darkMode')}</Typography>}
                  labelPlacement="start"
                  control={<MaterialUISwitch onChange={handleChangeSwitch} checked={darkMode} />}
                />
              </Grid>
            </Row>

            <Button
              className={styles.buttonDrawer}
              id="header-mobile-contact-us"
              variant="contained"
              href={t('contact_us_url')}
            >{t('header.button')}</Button>

          </Container>

        </Box>
      </Drawer>

      {/* Search */}
      <Dialog
        fullScreen
        maxWidth={false}
        open={searchOpen}
        onClose={handleSearchClose}
        PaperProps={{
          className: styles.searchDialog,
          sx: {
            paddingTop: 20,
            paddingRight: 8,
            color: 'common.white',
          }
        }}
      >
        <Container maxWidth={false}>
          <Row spacing={20} justifyContent={{ xs: "space-between", xl: "flex-start" }}>
            <Grid
              item
              xs={6}
              lg={6}
              display='flex'
              alignItems='center'
            >
              <Box className={styles.logo} display='flex' alignItems='center'>
                <Link href="/" aria-label="Logo Edison NEXT">
                  <Logo color="#fff" />
                </Link>
              </Box>
            </Grid>

            <Grid
              item
              xs={6}
              lg={6}
              display="flex"
              justifyContent={'flex-end'}
              gap={{ xs: 20, md: 40 }}
              alignItems='center'
              className={styles.formControlLabel}
            >

              <FormControlLabel
                label={<Typography variant="description">{t('header.darkMode')}</Typography>}
                labelPlacement="start"
                control={<MaterialUISwitch onChange={handleChangeSwitch} checked={darkMode} />}
              />

              {!sticky && (
                <>
                  <Button
                    id="lang-selector"
                    aria-controls={openLangSelector ? 'lang-selector-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openLangSelector ? 'true' : undefined}
                    onClick={handleClick}
                    variant="linkwhite"
                    className={styles.langSelector}
                    endIcon={openLangSelector ? <ExpandLess /> : <ExpandMore />}
                    sx={{ display: { xs: 'none', xl: 'inline-flex' } }}
                  >
                    {lang}
                  </Button>
                  <Menu
                    id="lang-selector-menu"
                    anchorEl={anchorEl}
                    open={openLangSelector}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'lang-selector',
                    }}
                  >
                    {langData?.map((el: { lang: string, path: string }) => <MenuItem key={`${el.path}_${el.lang}`} onClick={handleClose}>{el.lang.toUpperCase()}</MenuItem>)}
                  </Menu>

                  <Button
                    component={Link}
                    variant="contained"
                    color="secondary"
                    id="header-contact-us"
                    size="small"
                    href={t('contact_us_url')}
                    sx={{ display: { xs: 'none', xl: 'flex' } }}
                  >{t('header.button')}</Button>
                </>
              )}

            </Grid>

            
            <Grid item xs={12} justifyContent="flex-end" display="flex">
              <IconButton className={styles.noButton} onClick={handleSearchClose} sx={{ color: 'white' }} >
                <CloseIcon sx={{  width: "40px", height: "40px" }} />
              </IconButton>
            </Grid>

          </Row>
          
          <SearchView />
        </Container>
      </Dialog>

    </>
  );
}
