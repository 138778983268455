export default function GreenGas() {
  return (
<svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M78.283 50.75c7.972 0 14.435-6.175 14.435-13.792 0-7.616-6.463-13.791-14.435-13.791s-14.435 6.175-14.435 13.791c0 7.617 6.463 13.792 14.435 13.792zM47.26 89.97c6.833 0 12.373-5.293 12.373-11.821 0-6.529-5.54-11.821-12.373-11.821-6.834 0-12.373 5.292-12.373 11.82 0 6.53 5.54 11.822 12.373 11.822zM32.622 53.404c11.389 0 20.621-8.821 20.621-19.702C53.243 22.82 44.011 14 32.622 14 21.232 14 12 22.82 12 33.702c0 10.881 9.233 19.702 20.622 19.702z"
      ></path>
      <path
        fill="#fff"
        d="M49.5 74.443c14.635 0 26.5-11.865 26.5-26.5 0-14.636-11.865-26.5-26.5-26.5-14.636 0-26.5 11.864-26.5 26.5 0 14.635 11.864 26.5 26.5 26.5z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M76 47.563c0-3.864-.81-7.544-2.257-10.862a26.047 26.047 0 00-14.281-4.258C44.85 32.443 33 44.48 33 59.323c0 3.864.81 7.543 2.258 10.86a26.047 26.047 0 0014.28 4.26C64.15 74.443 76 62.405 76 47.563z"
      ></path>
      <path
        stroke="#284B9C"
        strokeWidth="1.55"
        d="M49.5 74.443c14.635 0 26.5-11.865 26.5-26.5 0-14.636-11.865-26.5-26.5-26.5-14.636 0-26.5 11.864-26.5 26.5 0 14.635 11.864 26.5 26.5 26.5z"
      ></path>
      <path
        fill="#fff"
        d="M28.874 29.323h-9.28c0 11.53 9.35 20.88 20.88 20.88h9.28c0-11.53-9.35-20.88-20.88-20.88zM70.634 42.083h9.28c0 11.53-9.35 20.88-20.88 20.88h-9.28c0-11.53 9.35-20.88 20.88-20.88z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M19.594 29.323c0 11.53 8.1 20.88 18.1 20.88h12.06c-15.96-4.3-30.16-20.88-30.16-20.88z"
      ></path>
      <path
        fill="#284B9C"
        d="M49.753 51.073h-9.28c-11.99 0-21.75-9.76-21.75-21.75 0-.48.39-.87.87-.87h9.28c11.99 0 21.75 9.76 21.75 21.75 0 .48-.39.87-.87.87zm-29.27-20.88c.45 10.64 9.25 19.14 19.99 19.14h8.39c-.45-10.63-9.25-19.14-19.99-19.14h-8.39z"
      ></path>
      <path
        fill="#284B9C"
        d="M49.751 51.073c-.07 0-.15 0-.23-.03-9.96-2.69-19.2-9.98-25.2-15.61a.86.86 0 01-.03-1.23.86.86 0 011.23-.03c5.85 5.51 14.84 12.61 24.45 15.2a.87.87 0 01-.23 1.71l.01-.01z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M79.914 42.083c0 11.53-8.1 20.88-18.1 20.88h-12.06c15.96-4.3 30.16-20.88 30.16-20.88z"
      ></path>
      <path
        fill="#284B9C"
        d="M59.033 63.833h-9.28a.87.87 0 01-.87-.87c0-11.99 9.76-21.75 21.75-21.75h9.28c.48 0 .87.39.87.87 0 11.99-9.76 21.75-21.75 21.75zm-8.39-1.74h8.39c10.74 0 19.53-8.5 19.99-19.14h-8.39c-10.74 0-19.53 8.51-19.99 19.14z"
      ></path>
      <path
        fill="#284B9C"
        d="M49.75 63.833c-.38 0-.73-.26-.84-.64-.13-.46.15-.94.61-1.07 9.62-2.59 18.61-9.7 24.45-15.2.35-.32.9-.31 1.23.03.32.35.31.9-.03 1.23-6 5.64-15.23 12.93-25.2 15.61-.08.02-.15.03-.23.03l.01.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M49.753 78.192a.87.87 0 01-.87-.87v-27.12a.87.87 0 011.74 0v27.12c0 .48-.39.87-.87.87zM22.898 23.276h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.739 0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M20.836 25.246c-.423 0-.773-.335-.773-.739v-3.94c0-.404.35-.74.773-.74.423 0 .773.336.773.74v3.94c0 .404-.35.739-.773.739zM77.898 31.276h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.739 0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M75.836 33.246c-.423 0-.773-.335-.773-.739v-3.94c0-.404.35-.74.773-.74.422 0 .773.336.773.74v3.94c0 .404-.35.739-.773.739zM31.898 77.89h-4.125c-.422 0-.773-.335-.773-.738 0-.404.35-.74.773-.74h4.125c.422 0 .773.336.773.74 0 .403-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M29.836 79.86c-.423 0-.773-.334-.773-.738v-3.94c0-.404.35-.74.773-.74.423 0 .773.336.773.74v3.94c0 .404-.35.739-.773.739z"
      ></path>
      <path fill="#fff" d="M62.75 47.193a4 4 0 100-8 4 4 0 000 8z"></path>
      <path
        fill="#BAE1A2"
        d="M63.972 39.443c.444.658.714 1.458.714 2.329 0 2.238-1.726 4.05-3.857 4.05-.29 0-.559-.04-.829-.1.694 1.042 1.842 1.72 3.143 1.72 2.131 0 3.857-1.812 3.857-4.05 0-1.934-1.292-3.544-3.028-3.95z"
      ></path>
      <path
        fill="#284B9C"
        d="M62.75 47.943c-2.62 0-4.75-2.13-4.75-4.75s2.13-4.75 4.75-4.75 4.75 2.13 4.75 4.75-2.13 4.75-4.75 4.75zm0-8c-1.79 0-3.25 1.46-3.25 3.25s1.46 3.25 3.25 3.25 3.25-1.46 3.25-3.25-1.46-3.25-3.25-3.25z"
      ></path>
      <path
        fill="#fff"
        d="M57.902 28.48a5.137 5.137 0 100-10.274 5.137 5.137 0 000 10.274z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M59.006 18.334c.59.835.95 1.85.95 2.954a5.135 5.135 0 01-5.137 5.137c-.385 0-.745-.052-1.104-.129A5.093 5.093 0 0057.9 28.48a5.135 5.135 0 005.137-5.136c0-2.453-1.72-4.495-4.032-5.009z"
      ></path>
      <path
        stroke="#284B9C"
        strokeWidth="1.5"
        d="M62.29 23.343a4.387 4.387 0 11-8.775 0 4.387 4.387 0 018.774 0z"
      ></path>
      <path fill="#fff" d="M60 78.443a6 6 0 100-12 6 6 0 000 12z"></path>
      <path
        fill="#BAE1A2"
        d="M61.29 66.593c.69.974 1.11 2.16 1.11 3.45 0 3.314-2.686 6-6 6-.45 0-.87-.06-1.29-.15a5.948 5.948 0 004.89 2.55c3.314 0 6-2.686 6-6 0-2.865-2.01-5.25-4.71-5.85z"
      ></path>
      <path
        stroke="#284B9C"
        strokeWidth="1.5"
        d="M65.25 72.443a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
      ></path>
      <path
        fill="#fff"
        d="M38.17 64.98a3.368 3.368 0 10-.001-6.738 3.368 3.368 0 000 6.737z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M38.723 58.158c.388.548.623 1.213.623 1.937a3.367 3.367 0 01-3.368 3.369 3.41 3.41 0 01-.724-.084 3.34 3.34 0 002.745 1.431 3.367 3.367 0 00.724-6.653z"
      ></path>
      <path
        stroke="#284B9C"
        strokeWidth="1.55"
        d="M38.17 64.98a3.368 3.368 0 100-6.737 3.368 3.368 0 000 6.736z"
      ></path>
      <path fill="#fff" d="M42.953 37.993a4 4 0 100-8 4 4 0 000 8z"></path>
      <path
        fill="#BAE1A2"
        d="M43.811 30.093c.46.65.74 1.44.74 2.3 0 2.21-1.79 4-4 4-.3 0-.58-.04-.86-.1.72 1.03 1.91 1.7 3.26 1.7a3.998 3.998 0 00.86-7.9z"
      ></path>
      <path
        fill="#284B9C"
        d="M42.953 38.743c-2.62 0-4.75-2.13-4.75-4.75s2.13-4.75 4.75-4.75 4.75 2.13 4.75 4.75-2.13 4.75-4.75 4.75zm0-8c-1.79 0-3.25 1.46-3.25 3.25s1.46 3.25 3.25 3.25 3.25-1.46 3.25-3.25-1.46-3.25-3.25-3.25z"
      ></path>
    </svg>
  );
}