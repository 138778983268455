export default function RuoloEdison() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M49.45 89.33c7.731 0 14-6.268 14-14s-6.269-14-14-14c-7.733 0-14 6.268-14 14s6.267 14 14 14zM75.05 44.89c6.628 0 12-5.373 12-12 0-6.628-5.372-12-12-12-6.627 0-12 5.372-12 12 0 6.627 5.373 12 12 12zM36 55c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20z"
      ></path>
      <path
        fill="#fff"
        d="M52.68 58.58c11.045 0 20-8.954 20-20s-8.955-20-20-20c-11.046 0-20 8.954-20 20s8.954 20 20 20z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M72.68 38.58c0-6.82-3.42-12.84-8.64-16.45-9.18 1.81-16.11 9.9-16.11 19.62 0 6.82 3.42 12.84 8.64 16.45 9.18-1.81 16.11-9.9 16.11-19.62z"
      ></path>
      <path
        fill="#284B9C"
        d="M52.672 59.33c-11.44 0-20.75-9.31-20.75-20.75s9.31-20.75 20.75-20.75 20.75 9.31 20.75 20.75-9.31 20.75-20.75 20.75zm0-40c-10.61 0-19.25 8.64-19.25 19.25s8.64 19.25 19.25 19.25 19.25-8.64 19.25-19.25-8.63-19.25-19.25-19.25z"
      ></path>
      <path
        fill="#fff"
        d="M69.72 28.11c-.63-.13-1.35.29-1.66 1.08-.74 1.89-2.7 1.02-3.4-1.29-.53-1.73-3.88-4.19-6.14.7-.81 1.74-2.71 3.74-.08 4.4 3.82.97 1.22 4.45-.99 3.22-2.21-1.24-3.26-2.28-5.45-1.05-2.19 1.24-2.37 4.38 0 4.66 1.84.22 2.38 3.13 1.33 3.62-1.75.81-2.86 3.71-1.43 5.9 1.43 2.19 2.95 1.05 3.62 0 .67-1.05.8-1.79 1.93-3.14.84-1.01 3.21-1.14 3.21-1.14s2-.38 1.24-4.09 3.72-2.41 4.35.12c.53 2.11-1.98 5.04 1.64 5.02 1.62 0 2.11.89 2.6 1.52a19.87 19.87 0 002.18-9.07c0-3.84-1.08-7.42-2.96-10.47l.01.01z"
      ></path>
      <path
        fill="#fff"
        d="M42.762 51.78c3.78 1.53 5.68-8.01 1.62-6.99-4.51 1.15-.97-4.43-5.03-3.87-4.06.55-3.51-3.13.15-3.75 1.09-.19 4-.64 4.41-3.72.43-3.24-.9-6.16-.9-6.16s9.33 2.49 8.9-1.4c-.4-3.51-2.76-1.43-3.89-2.18-1.67-1.12-.02-2.8-2.01-3.99-7.77 2.74-13.34 10.15-13.34 18.86 0 5.72 2.41 10.88 6.26 14.52.94-1.01 2.3-1.94 3.83-1.32z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M43.923 33.45c.31-2.38-.32-4.58-.67-5.58a20.003 20.003 0 00-4.3 9.43c.18-.05.37-.1.56-.13 1.09-.19 4-.64 4.41-3.72zM44.393 44.79c-4.51 1.15-.97-4.43-5.03-3.87-.24.03-.45.05-.66.05.08 3.93 1.29 7.57 3.32 10.63.24.02.49.07.75.17 3.78 1.53 5.68-8.01 1.62-6.99v.01zM48.031 23.71s-.03-.02-.04-.03a20.13 20.13 0 00-4.37 3.76c2.05.47 8.67 1.74 8.3-1.54-.4-3.51-2.76-1.43-3.89-2.18v-.01zM69.722 28.11c-.63-.13-1.35.29-1.66 1.08-.74 1.89-2.7 1.02-3.4-1.29-.03-.11-.08-.22-.13-.33 0 0 0 .02-.01.03-.81 1.74-2.71 3.74-.08 4.4 3.82.97 1.22 4.46-.99 3.22-1.52-.85-2.49-1.59-3.65-1.58 1.62 1.33-.49 3.62-2.35 2.58-.32-.18-.6-.35-.88-.51-.63 1.36-.21 2.92 1.43 3.13 1.83.22 2.38 3.13 1.33 3.62-1.27.59-2.19 2.27-2.07 3.99.06-.08.11-.15.18-.23.84-1.01 3.21-1.14 3.21-1.14s2-.38 1.24-4.09 3.72-2.41 4.35.12c.24.97-.15 2.11-.3 3.06.41-.06.71-.08.71-.08s2-.38 1.24-4.09 3.72-2.41 4.35.12c.32 1.28-.48 2.87-.34 3.91.49-1.73.76-3.55.76-5.44 0-3.84-1.08-7.42-2.96-10.47l.02-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M38.932 53.85c-.19 0-.38-.07-.52-.21-4.19-3.96-6.49-9.31-6.49-15.07 0-8.78 5.56-16.65 13.84-19.57.21-.07.44-.05.63.06 1.38.83 1.45 1.92 1.5 2.72.04.67.08.98.55 1.29.15.1.57.04.92 0 .69-.09 1.54-.19 2.27.38.57.45.91 1.21 1.03 2.34.12 1.09-.35 1.75-.77 2.11-1.63 1.41-5.58.84-7.7.41.36 1.22.75 3.15.47 5.21-.47 3.59-3.87 4.16-4.99 4.35-1.74.3-2.46 1.3-2.39 1.79.05.36.69.67 1.97.49 2.7-.37 3.01 1.61 3.17 2.67.08.53.17 1.08.42 1.24.13.09.48.19 1.36-.03 1.13-.29 2.11.06 2.68.96.98 1.56.52 4.69-.94 6.44-.96 1.14-2.22 1.51-3.46 1.01-.93-.38-1.94 0-3 1.14a.77.77 0 01-.53.24h-.02v.03zm6.98-33.29c-7.48 2.81-12.49 10-12.49 18.02 0 5.1 1.95 9.85 5.51 13.47 1.67-1.48 3.14-1.36 4.11-.97.23.09.94.38 1.74-.58 1.11-1.32 1.4-3.76.82-4.68-.15-.24-.39-.47-1.04-.3-1.12.28-1.95.23-2.55-.17-.8-.53-.95-1.49-1.07-2.26-.19-1.19-.24-1.59-1.49-1.42-2.44.33-3.5-.74-3.65-1.75-.21-1.38 1.07-3.06 3.58-3.48 1.73-.29 3.5-.88 3.79-3.08.39-2.99-.82-5.72-.84-5.75a.737.737 0 01.11-.79.76.76 0 01.76-.24c2.35.62 6.52 1.24 7.7.22.15-.13.32-.34.26-.81-.08-.68-.24-1.16-.47-1.33-.23-.18-.68-.13-1.15-.07-.62.08-1.33.17-1.94-.24-1.11-.74-1.17-1.73-1.21-2.45-.04-.58-.06-.97-.49-1.33l.01-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M53.68 51.37c-.63 0-1.55-.28-2.4-1.59-.77-1.18-.98-2.67-.57-4.08.38-1.33 1.25-2.41 2.31-2.91.07-.06.18-.48 0-1.06-.15-.5-.53-1.06-1.11-1.13-1.22-.15-2.08-.92-2.31-2.07-.29-1.48.56-3.16 2.03-3.99 2.25-1.27 3.6-.47 5.31.54.27.16.56.33.88.51.65.37 1.22.03 1.37-.09.32-.23.49-.56.44-.84-.07-.37-.59-.72-1.37-.92-.93-.24-1.51-.65-1.77-1.26-.43-1.01.2-2.11.81-3.17.19-.34.39-.68.54-1.01 1.33-2.88 3.01-3.38 4.18-3.3 1.63.11 2.99 1.46 3.37 2.7.37 1.21 1.07 1.77 1.45 1.79.25 0 .44-.3.54-.55.43-1.11 1.5-1.76 2.52-1.54.2.04.37.17.48.34 2.01 3.26 3.07 7.02 3.07 10.86 0 3.31-.76 6.47-2.26 9.41-.12.23-.34.38-.6.41-.26.02-.5-.08-.66-.29l-.16-.21c-.43-.57-.76-1.02-1.83-1.02h-.04c-1.12 0-1.87-.26-2.31-.81-.63-.78-.41-1.82-.2-2.82.15-.69.3-1.41.16-1.96-.31-1.22-1.81-2-2.55-1.83-.5.11-.46.82-.34 1.39.35 1.7.21 3.03-.4 3.95-.57.85-1.34 1.02-1.43 1.04-.77.05-2.3.31-2.78.88-.67.81-.95 1.37-1.25 1.96-.18.36-.37.71-.62 1.11-.63.99-1.55 1.59-2.46 1.59h-.01l-.03-.03zm.13-15.98c-.43 0-.88.14-1.44.45-1.04.59-1.44 1.67-1.3 2.39.1.51.45.8 1.02.87 1.45.18 2.29 1.48 2.48 2.7.17 1.1-.18 2-.92 2.35-.67.31-1.25 1.06-1.5 1.96-.17.59-.34 1.73.39 2.85.27.41.69.91 1.15.91.47 0 .94-.49 1.21-.9.23-.35.39-.67.55-.99.33-.64.66-1.3 1.44-2.23.95-1.15 3.17-1.37 3.67-1.41.16-.06 1.17-.55.62-3.2-.41-1.98.5-2.93 1.48-3.15 1.54-.35 3.83.93 4.34 2.93.22.89.02 1.82-.15 2.64-.13.6-.29 1.34-.1 1.56.05.06.32.26 1.17.25 1.17.02 1.94.42 2.43.89 1.06-2.42 1.6-5 1.6-7.69 0-3.42-.91-6.77-2.62-9.71-.21.08-.42.28-.54.59-.39.99-1.13 1.54-1.99 1.51-1.19-.04-2.32-1.19-2.82-2.85-.19-.62-1.02-1.57-2.03-1.64-1.26-.1-2.17 1.27-2.71 2.43-.17.37-.39.75-.6 1.12-.37.64-.87 1.51-.73 1.84.02.05.14.24.76.4 1.9.48 2.37 1.52 2.48 2.09.16.85-.25 1.76-1.04 2.33-.89.64-2.03.71-2.98.18-.33-.18-.63-.36-.91-.53-.99-.59-1.65-.98-2.37-.98l-.04.04z"
      ></path>
      <path
        fill="#fff"
        d="M79.96 38.07c1.08.7 1.71 1.92 1.64 3.21l-.77 14.31-6.58-.97.52-4.74c1.15-5.41.61-9.17 1.41-11.03.74-1.71 2.52-1.74 3.77-.78h.01z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M81.601 41.28c.03-.55-.08-1.09-.28-1.58-1.16-.52-2.51-.28-3.13 1.15-.8 1.85-.26 5.61-1.41 11.03l-.34 3.06 4.39.65.77-14.31z"
      ></path>
      <path
        fill="#284B9C"
        d="M80.83 56.34h-.11l-6.58-.97a.753.753 0 01-.64-.82l.52-4.74c.6-2.85.74-5.19.85-7.07.1-1.77.18-3.17.62-4.17.39-.92 1.09-1.55 1.96-1.77.95-.25 2.04 0 2.93.67 1.29.84 2.04 2.32 1.96 3.87l-.77 14.31a.747.747 0 01-.74.71v-.02zm-5.75-2.36l5.05.74.73-13.49c.05-1.02-.44-1.99-1.3-2.54-.02 0-.03-.02-.05-.03-.53-.41-1.15-.57-1.66-.43-.42.11-.75.42-.96.92-.33.76-.4 2.04-.5 3.66-.11 1.93-.25 4.33-.87 7.22l-.43 3.95h-.01z"
      ></path>
      <path
        fill="#fff"
        d="M80.77 36.11c-1.75.86.05 6.21-2.48 16.9.09-1.52-.43-2.3-1.13-2.68-3.17-1.68-4.28 1.22-4.28 1.22-6.31 14.61-4.83 2.02-13.74 21.74l12.74 5.68s17.1-18.47 11.77-40.61c0 0-.58-3.38-2.87-2.26l-.01.01z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M84.16 40.98c-.37-.15-.83-.15-1.39.13-1.75.86.05 6.21-2.48 16.9.09-1.52-.43-2.3-1.13-2.68-3.17-1.68-4.28 1.22-4.28 1.22-5.91 13.68-4.99 3.52-12.15 18.34l9.16 4.08s15.76-17.04 12.29-38l-.02.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M71.88 79.73c-.1 0-.21-.02-.31-.07l-12.74-5.68a.784.784 0 01-.4-.42.75.75 0 01.02-.57c4.84-10.73 6.73-12.1 8.4-13.32 1.33-.97 2.48-1.8 5.33-8.41.01-.03.57-1.46 2.01-2 .99-.37 2.1-.23 3.31.41.21.11.42.26.63.46.79-4.26.89-7.51.96-9.79.08-2.77.13-4.3 1.35-4.9.98-.48 1.72-.31 2.18-.08 1.35.69 1.73 2.66 1.76 2.88C89.74 60.46 72.6 79.3 72.43 79.49c-.15.16-.35.24-.55.24zm-11.75-6.81l11.55 5.15c2.39-2.79 15.99-19.8 11.23-39.52-.09-.52-.44-1.57-.98-1.85-.08-.04-.33-.16-.83.09-.41.2-.46 1.85-.51 3.6-.08 2.8-.21 7.04-1.57 12.8-.09.38-.45.63-.84.57a.762.762 0 01-.64-.79c.09-1.53-.53-1.86-.74-1.97-.81-.43-1.52-.54-2.08-.33-.8.3-1.14 1.15-1.14 1.16-3.03 7.02-4.33 7.96-5.84 9.06-1.48 1.08-3.16 2.3-7.6 12.03h-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M73.552 64.7a.75.75 0 01-.64-1.14c3.68-6.03 4.54-9.16 4.62-10.71.02-.41.41-.73.78-.71.41.02.73.37.71.78-.12 2.52-1.75 6.36-4.84 11.42-.14.23-.39.36-.64.36h.01z"
      ></path>
      <path
        fill="#fff"
        d="M25.391 37.97c-1.08.7-1.71 1.92-1.64 3.21l.77 14.31 6.58-.97-.52-4.74c-1.15-5.41-.61-9.17-1.41-11.03-.74-1.71-2.52-1.74-3.77-.78h-.01z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M30.572 49.78c-1.1-5.14-.67-8.77-1.31-10.71-1 .71-1.58 1.88-1.51 3.11l.68 12.73 2.66-.39-.52-4.74z"
      ></path>
      <path
        fill="#284B9C"
        d="M24.52 56.34a.747.747 0 01-.74-.71l-.77-14.31c-.08-1.54.67-3.02 1.96-3.87.89-.67 1.98-.92 2.93-.67.87.23 1.57.86 1.96 1.77.43 1 .51 2.4.62 4.17.11 1.88.25 4.22.84 7l.54 4.81c.04.4-.24.77-.64.82l-6.58.97h-.11l-.01.02zm2.62-18.15c-.42 0-.89.17-1.29.48-.02.01-.03.02-.05.03-.86.55-1.35 1.52-1.3 2.54l.73 13.49 5.05-.74-.44-4.02c-.6-2.82-.74-5.22-.86-7.15-.09-1.62-.17-2.9-.5-3.66-.21-.5-.54-.81-.96-.92-.12-.03-.24-.05-.37-.05h-.01z"
      ></path>
      <path
        fill="#fff"
        d="M24.583 36.11c1.75.86-.05 6.21 2.48 16.9-.09-1.52.43-2.3 1.13-2.68 3.17-1.68 4.28 1.22 4.28 1.22 6.31 14.61 4.83 2.02 13.74 21.74l-12.74 5.68s-17.1-18.46-11.76-40.6c0 0 .58-3.38 2.87-2.26z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M32.48 51.55s-.65-1.68-2.37-1.74c1.88 12.9 9.49 22.76 11.73 25.43l4.38-1.95c-8.91-19.72-7.43-7.13-13.74-21.74z"
      ></path>
      <path
        fill="#284B9C"
        d="M33.473 79.73c-.2 0-.41-.08-.55-.24-.17-.19-17.31-19.02-11.94-41.29.03-.17.4-2.14 1.75-2.83.45-.23 1.2-.41 2.18.08 1.22.6 1.27 2.13 1.35 4.9.07 2.28.16 5.53.96 9.79.2-.2.42-.35.63-.46 1.21-.64 2.33-.78 3.31-.41 1.43.54 2 1.96 2.02 2.02 2.84 6.58 3.99 7.42 5.32 8.38 1.67 1.21 3.56 2.59 8.4 13.32.08.18.09.39.02.57a.72.72 0 01-.4.42l-12.74 5.68c-.1.04-.2.07-.31.07zm-9.77-43.1c-.15 0-.24.04-.28.07-.54.27-.89 1.33-.97 1.8-4.77 19.78 8.83 36.77 11.22 39.57l11.55-5.15c-4.45-9.74-6.12-10.96-7.6-12.03-1.51-1.1-2.81-2.04-5.83-9.03-.01-.03-.37-.9-1.17-1.19-.57-.21-1.26-.1-2.08.33-.2.11-.82.44-.74 1.97.02.39-.26.73-.64.79a.745.745 0 01-.84-.57c-1.37-5.76-1.49-10-1.57-12.8-.05-1.75-.1-3.4-.51-3.6-.24-.12-.42-.15-.55-.15l.01-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M31.801 64.7c-.25 0-.5-.13-.64-.36-3.09-5.06-4.72-8.9-4.84-11.42a.75.75 0 011.5-.07c.07 1.56.94 4.68 4.62 10.71a.75.75 0 01-.64 1.14z"
      ></path>
      <path
        fill="#fff"
        d="M58.822 70.24l-1.67 12.34h17.73l1.64-7.18-17.7-5.16z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M75.911 78.05l-10.86-3.17a2 2 0 00-2.54 1.65l-.82 6.04h13.19l1.04-4.53-.01.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M74.883 83.33h-17.73c-.22 0-.42-.09-.56-.26a.749.749 0 01-.18-.59l1.67-12.34c.03-.22.15-.41.33-.53.18-.12.41-.15.62-.09l17.7 5.16c.38.11.61.5.52.89l-1.64 7.19c-.08.34-.38.58-.73.58v-.01zm-16.87-1.5h16.27l1.35-5.91-16.18-4.72-1.44 10.63z"
      ></path>
      <path
        fill="#284B9C"
        d="M71.06 77.91c.53.17.81.73.64 1.26-.17.53-.73.81-1.26.65-.53-.17-.81-.73-.65-1.26.17-.53.73-.81 1.26-.65h.01z"
      ></path>
      <path
        fill="#fff"
        d="M46.532 70.24l1.67 12.34h-17.73l-1.64-7.18 17.7-5.16z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M47.071 74.25l-12.43 3.62c-1.02.3-1.63 1.33-1.39 2.37l.54 2.35h14.41l-1.13-8.33v-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M48.202 83.33h-17.73c-.35 0-.65-.24-.73-.58l-1.64-7.19a.75.75 0 01.52-.89l17.7-5.16c.21-.06.44-.03.62.09s.31.31.33.53l1.67 12.34a.76.76 0 01-.74.85v.01zm-17.13-1.5h16.27l-1.44-10.63-16.18 4.72 1.35 5.91z"
      ></path>
      <path
        fill="#284B9C"
        d="M43.292 77.91c-.53.17-.81.73-.65 1.26.17.53.73.81 1.26.65.53-.17.81-.73.64-1.26-.17-.53-.73-.81-1.26-.65h.01zM37.898 19.833h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.74 0 .403-.35.738-.773.738z"
      ></path>
      <path
        fill="#284B9C"
        d="M35.836 21.803c-.423 0-.773-.335-.773-.739v-3.94c0-.404.35-.739.773-.739.422 0 .773.335.773.74v3.94c0 .403-.35.738-.773.738zM23.898 29.833h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.74 0 .403-.35.738-.773.738z"
      ></path>
      <path
        fill="#284B9C"
        d="M21.836 31.803c-.423 0-.773-.335-.773-.738v-3.94c0-.405.35-.74.773-.74.423 0 .773.335.773.74v3.94c0 .403-.35.738-.773.738zM79.898 26.833h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.74 0 .403-.35.738-.773.738z"
      ></path>
      <path
        fill="#284B9C"
        d="M77.836 28.803c-.423 0-.773-.335-.773-.738v-3.94c0-.405.35-.74.773-.74.422 0 .773.335.773.74v3.94c0 .403-.35.738-.773.738z"
      ></path>
    </svg>
  );
}