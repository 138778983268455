import LayoutDefault from "./LayoutDefault";
import LayoutExpanded from "./LayoutExpanded";
import LayoutJournal from "./LayoutJournal";
import LayoutThreeCards from "./LayoutThreeCards";

import { MenuLayoutModel } from "../Menu.model";

export const MenuLayout: React.FC<MenuLayoutModel> = ({variant, item}) => {
  
  const layoutVariants = {
    default: LayoutDefault,
    expanded: LayoutExpanded,
    journal: LayoutJournal,
    three_cards_column: LayoutThreeCards
  }

  const Component = layoutVariants[variant] || LayoutDefault;
  return <Component item={item} />;
}

export default MenuLayout