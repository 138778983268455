export default function SmartCity() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M78.283 50.75c7.972 0 14.435-6.175 14.435-13.792 0-7.616-6.463-13.791-14.435-13.791s-14.435 6.175-14.435 13.791c0 7.617 6.463 13.792 14.435 13.792zM47.26 89.97c6.833 0 12.373-5.293 12.373-11.821 0-6.529-5.54-11.821-12.373-11.821-6.834 0-12.373 5.292-12.373 11.82 0 6.53 5.54 11.822 12.373 11.822zM32.622 53.404c11.389 0 20.621-8.821 20.621-19.702C53.243 22.82 44.011 14 32.622 14 21.232 14 12 22.82 12 33.702c0 10.881 9.233 19.702 20.622 19.702z"
      ></path>
      <path
        fill="#284B9C"
        d="M26.898 22.276h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.739 0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M24.836 24.246c-.423 0-.773-.335-.773-.739v-3.94c0-.404.35-.74.773-.74.423 0 .773.336.773.74v3.94c0 .404-.35.739-.773.739zM53.898 37.276h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.739 0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.836 39.246c-.423 0-.773-.335-.773-.739v-3.94c0-.404.35-.74.773-.74.422 0 .773.336.773.74v3.94c0 .404-.35.739-.773.739zM46.898 21.276h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.739 0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M44.836 23.246c-.423 0-.773-.335-.773-.739v-3.94c0-.404.35-.74.773-.74.422 0 .773.336.773.74v3.94c0 .404-.35.739-.773.739zM76.79 29.881a.756.756 0 01-1.027-.264 5.262 5.262 0 00-7.19-1.85l-5.166 3.053a.756.756 0 01-1.027-.264.755.755 0 01.264-1.027l5.166-3.053c3.203-1.892 7.353-.825 9.245 2.378a.756.756 0 01-.264 1.027z"
      ></path>
      <path
        fill="#284B9C"
        d="M73.79 32.281a.756.756 0 01-1.027-.264c-.93-1.574-1.764-1.074-4.19.15l-2.166 1.053a.756.756 0 01-1.027-.264.755.755 0 01.264-1.027l2.166-1.053c3.189-1.433 4.953-2.142 6.245.378a.756.756 0 01-.264 1.027zM78.391 27.003a.755.755 0 01-1.027-.264 7.255 7.255 0 00-9.93-2.554l-6.027 3.561a.756.756 0 01-1.027-.264.756.756 0 01.264-1.027l6.027-3.561c4.158-2.457 9.527-1.076 11.984 3.082a.756.756 0 01-.264 1.027z"
      ></path>
      <path
        fill="#2B5185"
        d="M79.75 74.943h-64c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h64c.41 0 .75.34.75.75s-.34.75-.75.75z"
      ></path>
      <path fill="#BAE1A2" d="M76.75 74.193v-35h-15v35h15z"></path>
      <path fill="#fff" d="M72 69.193v-30H62v30h10z"></path>
      <path
        fill="#284B9C"
        d="M61 74.193v-35c0-.41.34-.75.75-.75h15c.41 0 .75.34.75.75v35c0 .41-.34.75-.75.75h-15c-.41 0-.75-.34-.75-.75zm1.5-34.25v33.5H76v-33.5H62.5z"
      ></path>
      <path
        fill="#284B9C"
        d="M66 69.193c0-.41.34-.75.75-.75h5c.41 0 .75.34.75.75s-.34.75-.75.75h-5c-.41 0-.75-.34-.75-.75zM66 64.193c0-.41.34-.75.75-.75h5c.41 0 .75.34.75.75s-.34.75-.75.75h-5c-.41 0-.75-.34-.75-.75zM66 59.193c0-.41.34-.75.75-.75h5c.41 0 .75.34.75.75s-.34.75-.75.75h-5c-.41 0-.75-.34-.75-.75zM66 54.193c0-.41.34-.75.75-.75h5c.41 0 .75.34.75.75s-.34.75-.75.75h-5c-.41 0-.75-.34-.75-.75zM66 49.193c0-.41.34-.75.75-.75h5c.41 0 .75.34.75.75s-.34.75-.75.75h-5c-.41 0-.75-.34-.75-.75zM66 44.193c0-.41.34-.75.75-.75h5c.41 0 .75.34.75.75s-.34.75-.75.75h-5c-.41 0-.75-.34-.75-.75z"
      ></path>
      <path fill="#BAE1A2" d="M41 74.443v-45H19v45h22z"></path>
      <path fill="#fff" d="M36 69.443v-40H19v40h17z"></path>
      <path
        fill="#284B9C"
        d="M18 74.193v-45c0-.41.34-.75.75-.75h22c.41 0 .75.34.75.75v45c0 .41-.34.75-.75.75h-22c-.41 0-.75-.34-.75-.75zm1.5-44.25v43.5H40v-43.5H19.5z"
      ></path>
      <path
        fill="#284B9C"
        d="M33.75 49.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM33.75 59.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM33.75 44.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM33.75 39.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM33.75 34.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM33.75 54.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM33.75 64.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75z"
      ></path>
      <path fill="#BAE1A2" d="M61.75 44.193h-31v30h31v-30z"></path>
      <path fill="#fff" d="M56.75 44.193h-26v25h26v-25z"></path>
      <path
        fill="#284B9C"
        d="M61.75 74.943h-31c-.41 0-.75-.34-.75-.75v-30c0-.41.34-.75.75-.75h31c.41 0 .75.34.75.75v30c0 .41-.34.75-.75.75zm-30.25-1.5H61v-28.5H31.5v28.5z"
      ></path>
      <path
        fill="#284B9C"
        d="M43.75 64.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM43.75 59.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM43.75 54.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM43.75 49.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM52.75 64.943h-4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75zM43.75 69.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM52.75 69.943h-4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75zM52.75 59.943h-4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75zM56.75 54.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75zM56.75 49.943h-8c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8c.41 0 .75.34.75.75s-.34.75-.75.75z"
      ></path>
    </svg>
  );
}