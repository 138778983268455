import {useEffect, useRef, useState} from 'react'

import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import {useTranslation} from "next-i18next";

import styles from '../StickyBar.module.scss'

export default function RegisterBar() {

  const { t } = useTranslation();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [sticky, setSticky] = useState(false);
  
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const refSticky = useRef(null);

  useEffect(() => {
    document.addEventListener("scroll", () => {
      if (window.scrollY > window.innerHeight*2/3) setSticky(true)
      else setSticky(false)
    }, false);
  }, [])

  const handleScroll = () => {
    const anchor = document.querySelector('#contactform');
    anchor.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });

    isMobile && setTimeout(handleClose, 500);
  }

  return(
    <div ref={refSticky} className={`${styles.stickyBar} ${sticky ? styles.stickyOn : ''}`}>

      {isMobile ? (
        <>
          <Fab color="primary" aria-label="add" aria-describedby={id} onClick={handleClick}>
            <DriveFileRenameOutlineIcon />
          </Fab>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            slotProps={{paper: {className: styles.paper}}}
            disableScrollLock
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            <div className={styles.popover}>
              <Typography component="p" variant="bodyL">{t('stickyBar.register.help')}</Typography>
              <Typography id='sticky-register-btn' variant="bodyL" component="a" onClick={handleScroll} fontWeight={'bold'} color={'#181B20'}>{t('stickyBar.register.click')}</Typography>
            </div>
          </Popover>
        </>
      ) : (
        <>
          <Typography variant="bodyL" fontWeight={'light'}>{t('stickyBar.register.help')}</Typography>
          <Button
            id='sticky-register-btn'
            variant='contained'
            color='primary'
            size="small"
            onClick={handleScroll}
          >
            {t('stickyBar.register.button')}
          </Button>
        </>
      )}

    </div>
  )
}
