export default function Decarbonizzazione() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M78.283 50.75c7.972 0 14.435-6.175 14.435-13.792 0-7.616-6.463-13.791-14.435-13.791s-14.435 6.175-14.435 13.791c0 7.617 6.463 13.792 14.435 13.792zM47.26 89.97c6.833 0 12.373-5.293 12.373-11.821 0-6.529-5.54-11.821-12.373-11.821-6.834 0-12.373 5.292-12.373 11.82 0 6.53 5.54 11.822 12.373 11.822zM32.622 53.404c11.389 0 20.621-8.821 20.621-19.702C53.243 22.82 44.011 14 32.622 14 21.232 14 12 22.82 12 33.702c0 10.881 9.233 19.702 20.622 19.702z"
      ></path>
      <path
        fill="#284B9C"
        d="M31.898 18.276h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.739 0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M29.836 20.246c-.423 0-.773-.335-.773-.739v-3.94c0-.404.35-.74.773-.74.423 0 .773.336.773.74v3.94c0 .404-.35.739-.773.739zM60.898 79.276h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.739 0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M58.836 81.246c-.423 0-.773-.335-.773-.739v-3.94c0-.404.35-.74.773-.74.422 0 .773.336.773.74v3.94c0 .404-.35.739-.773.739zM80.898 37.276h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.739 0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M78.836 39.246c-.423 0-.773-.335-.773-.739v-3.94c0-.404.35-.74.773-.74.422 0 .773.336.773.74v3.94c0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#fff"
        d="M72.11 54.843l7.65-7.64-7.65-7.65a5.897 5.897 0 01-1.73-4.17v-10.82H59.57c-1.56 0-3.06-.62-4.17-1.73l-7.65-7.64-7.65 7.64a5.897 5.897 0 01-4.17 1.73H25.12v10.81c0 1.56-.62 3.07-1.73 4.17l-7.64 7.65 7.64 7.64a5.897 5.897 0 011.73 4.17v10.81h10.81c1.56 0 3.06.62 4.17 1.73l7.65 7.65 7.64-7.65a5.897 5.897 0 014.17-1.73h10.81v-10.81c0-1.56.62-3.06 1.73-4.17l.01.01z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M72.111 39.543a5.897 5.897 0 01-1.73-4.17v-10.81h-7.32c.45 2.14.69 4.35.69 6.63 0 17.67-14.33 32-32 32-2.27 0-4.49-.24-6.63-.69v7.32h10.81c1.56 0 3.06.62 4.17 1.73l7.65 7.65 7.64-7.65a5.897 5.897 0 014.17-1.73h10.81v-10.81c0-1.56.62-3.06 1.73-4.17l7.65-7.64-7.65-7.65.01-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M47.75 79.943a.75.75 0 01-.53-.22l-7.65-7.65a5.13 5.13 0 00-3.64-1.51H25.12c-.41 0-.75-.34-.75-.75v-10.81c0-1.38-.54-2.67-1.51-3.64l-7.64-7.64a.75.75 0 010-1.06l7.64-7.65a5.13 5.13 0 001.51-3.64v-10.81c0-.41.34-.75.75-.75h10.81c1.38 0 2.67-.54 3.64-1.51l7.65-7.64c.29-.29.77-.29 1.06 0l7.65 7.64a5.13 5.13 0 003.64 1.51h10.81c.41 0 .75.34.75.75v10.81c0 1.38.54 2.67 1.51 3.64l7.64 7.65a.75.75 0 010 1.06l-7.64 7.64a5.13 5.13 0 00-1.51 3.64v10.81c0 .41-.34.75-.75.75H59.57c-1.38 0-2.67.54-3.64 1.51l-7.65 7.65a.75.75 0 01-.53.22zm-21.88-10.87h10.06c1.78 0 3.45.69 4.7 1.95l7.11 7.12 7.11-7.12a6.57 6.57 0 014.7-1.95h10.06v-10.06c0-1.78.69-3.45 1.95-4.7l7.11-7.11-7.11-7.12a6.605 6.605 0 01-1.95-4.7v-10.07H59.55a6.57 6.57 0 01-4.7-1.95l-7.11-7.11-7.11 7.11a6.57 6.57 0 01-4.7 1.95H25.87v10.06c0 1.78-.69 3.45-1.95 4.7l-7.11 7.12 7.11 7.11a6.605 6.605 0 011.95 4.7v10.07z"
      ></path>
      <path
        fill="#fff"
        d="M47.75 65.193c9.941 0 18-8.06 18-18 0-9.941-8.059-18-18-18s-18 8.058-18 18c0 9.94 8.059 18 18 18z"
      ></path>
      <path
        fill="#284B9C"
        d="M47.75 65.943c-10.34 0-18.75-8.41-18.75-18.75s8.41-18.75 18.75-18.75 18.75 8.41 18.75 18.75-8.41 18.75-18.75 18.75zm0-36c-9.51 0-17.25 7.74-17.25 17.25s7.74 17.25 17.25 17.25S65 56.703 65 47.193s-7.74-17.25-17.25-17.25z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M66.11 38.803l-15.42 3 5.38-26.61-26.68 40.7 15.42-2.98-5.55 26.28 26.85-40.39z"
      ></path>
      <path
        fill="#fff"
        d="M54.14 45.193l-11.51 2.24c-.8.16-1.44-.67-1.1-1.41l14.54-30.83-26.68 40.7 15.42-2.99-5.55 26.28 15.96-32.58c.36-.74-.28-1.58-1.09-1.42l.01.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M39.26 79.942c-.1 0-.2-.02-.29-.06a.744.744 0 01-.44-.85l5.31-25.18-14.31 2.77c-.29.05-.6-.07-.77-.32a.74.74 0 010-.83l26.68-40.69c.2-.3.58-.42.91-.28.33.14.52.49.45.84l-5.16 25.51 14.32-2.78c.3-.05.6.07.77.32.17.25.17.58 0 .83l-26.85 40.39c-.14.21-.38.33-.62.33zm5.55-27.78c.2 0 .39.08.53.22.18.18.25.44.2.68l-4.74 22.46 23.69-35.64-13.66 2.65c-.25.05-.5-.03-.68-.21a.742.742 0 01-.2-.68l4.61-22.79-23.57 35.97 13.68-2.65s.1-.01.14-.01z"
      ></path>
    </svg>
  );
}