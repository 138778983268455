export default function Digitale() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M66.734 97c8.107 0 14.678-6.572 14.678-14.679S74.84 67.642 66.734 67.642c-8.107 0-14.68 6.572-14.68 14.679S58.628 97 66.735 97zM79.066 55.197c6.95 0 12.582-5.634 12.582-12.582 0-6.95-5.633-12.582-12.582-12.582-6.948 0-12.582 5.633-12.582 12.582 0 6.948 5.634 12.581 12.582 12.581zM33.97 58.94c11.581 0 20.97-9.389 20.97-20.97S45.55 17 33.97 17 13 26.389 13 37.97s9.389 20.97 20.97 20.97z"
      ></path>
      <path
        fill="#fff"
        d="M19.55 38.42h62.91c1.153 0 2.097.944 2.097 2.097v35.65H17.453v-35.65c0-1.153.944-2.097 2.097-2.097zM63.59 83.506H38.426v7.34H63.59v-7.34z"
      ></path>
      <path
        fill="#fff"
        d="M17.453 76.166h67.104v5.243a2.103 2.103 0 01-2.097 2.097H19.55a2.103 2.103 0 01-2.097-2.097v-5.243z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M84.558 53.1H67.782c-12.74 0-23.067 10.327-23.067 23.066h39.843V53.1zM63.588 90.845H44.715a3.674 3.674 0 013.67-3.67h15.203v3.67zM82.46 83.506H27.937a3.674 3.674 0 013.67-3.67h52.948v1.573a2.103 2.103 0 01-2.097 2.097z"
      ></path>
      <path
        fill="#284B9C"
        d="M84.558 76.953H17.454a.792.792 0 01-.786-.787V40.517a2.882 2.882 0 012.883-2.883h62.91a2.882 2.882 0 012.883 2.883v35.65c0 .429-.356.785-.786.785zM18.24 75.38h65.53V40.517c0-.723-.587-1.31-1.31-1.31H19.55c-.723 0-1.31.587-1.31 1.31V75.38zM63.587 91.631H38.423a.792.792 0 01-.786-.786v-7.34c0-.43.356-.786.786-.786h25.164c.43 0 .786.357.786.787v7.34c0 .429-.356.785-.786.785zM39.209 90.06H62.8v-5.767H39.21v5.767z"
      ></path>
      <path
        fill="#284B9C"
        d="M82.46 84.292H19.552a2.882 2.882 0 01-2.883-2.883v-5.243c0-.43.357-.786.786-.786h67.104c.43 0 .786.356.786.786v5.243a2.882 2.882 0 01-2.883 2.883zm-64.22-7.34v4.457c0 .723.588 1.31 1.311 1.31h62.91c.723 0 1.31-.587 1.31-1.31v-4.456h-65.53zM71.976 91.631h-41.94a.792.792 0 01-.786-.786c0-.43.357-.786.786-.786h41.94c.43 0 .786.356.786.786 0 .43-.356.786-.786.786z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.005 80.884a1.048 1.048 0 100-2.097 1.048 1.048 0 000 2.097zM80.365 72.695a.792.792 0 01-.787-.786v-9.216c0-.43.357-.786.787-.786.43 0 .786.356.786.786v9.216c0 .43-.357.786-.786.786zM80.365 59.296a.792.792 0 01-.787-.786v-2.318c0-.43.357-.786.787-.786.43 0 .786.357.786.786v2.318c0 .43-.357.786-.786.786zM21.65 62.21a.792.792 0 01-.787-.786V49.063c0-.43.357-.787.787-.787.43 0 .786.357.786.786v12.362c0 .43-.357.787-.786.787zM21.65 45.655a.792.792 0 01-.787-.786V42.55c0-.43.357-.786.787-.786.43 0 .786.357.786.786v2.318c0 .43-.357.786-.786.786zM46.926 16.207h-4.194a.792.792 0 01-.787-.787c0-.43.357-.786.787-.786h4.194c.43 0 .786.357.786.786 0 .43-.357.787-.786.787z"
      ></path>
      <path
        fill="#284B9C"
        d="M44.83 18.304a.792.792 0 01-.787-.787v-4.194c0-.43.357-.786.786-.786.43 0 .787.357.787.786v4.194c0 .43-.357.787-.787.787zM84.832 32.983h-4.194a.792.792 0 01-.786-.787c0-.43.356-.786.786-.786h4.194c.43 0 .786.356.786.786 0 .43-.356.787-.786.787z"
      ></path>
      <path
        fill="#284B9C"
        d="M82.736 35.08a.792.792 0 01-.787-.787V30.1c0-.43.357-.786.787-.786.43 0 .786.357.786.786v4.194c0 .43-.357.787-.786.787zM47.472 60.177H43.28a.792.792 0 01-.787-.787c0-.43.357-.786.787-.786h4.193c.43 0 .787.357.787.786 0 .43-.357.787-.787.787z"
      ></path>
      <path
        fill="#284B9C"
        d="M45.376 62.273a.792.792 0 01-.786-.786v-4.194c0-.43.356-.786.786-.786.43 0 .787.356.787.786v4.194c0 .43-.357.786-.787.786zM35.281 28.45h-4.194a.792.792 0 01-.786-.787c0-.43.356-.787.786-.787h4.194c.43 0 .787.357.787.787 0 .43-.357.786-.787.786z"
      ></path>
      <path
        fill="#284B9C"
        d="M33.185 30.546a.792.792 0 01-.787-.786v-4.194c0-.43.357-.787.787-.787.43 0 .786.357.786.787v4.194c0 .43-.356.786-.786.786z"
      ></path>
      <path
        fill="#fff"
        d="M32.688 20.113L18.8 34h7.809v42.002h12.156V34h7.809L32.688 20.113z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M46.574 33.997h-7.809V76h-7.777V32.406c0-1.175.962-2.137 2.137-2.137h9.731l3.728 3.728h-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M38.765 76.967H26.608a.807.807 0 01-.8-.801V34.965H18.8a.791.791 0 01-.738-.492.808.808 0 01.171-.876L32.12 19.71a.806.806 0 011.133 0L47.14 33.597a.802.802 0 01-.566 1.367h-7.008v41.202a.807.807 0 01-.801.8zM27.42 75.365h10.554V34.163c0-.438.364-.8.802-.8h5.875L32.697 21.408 20.744 33.362h5.875c.438 0 .801.363.801.801v41.202z"
      ></path>
      <path
        fill="#fff"
        d="M65.803 7.799L48.71 24.89h9.614v51.276H73.28V24.89h9.614L65.803 7.8z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M82.894 24.89H73.28v51.275H62.277V22.23c0-1.175.962-2.136 2.137-2.136h13.684l4.796 4.796z"
      ></path>
      <path
        fill="#284B9C"
        d="M73.28 76.967H58.323a.807.807 0 01-.801-.802V25.691H48.71a.791.791 0 01-.737-.49.808.808 0 01.17-.877L65.236 7.232a.806.806 0 011.133 0l17.091 17.092a.802.802 0 01-.566 1.367H74.08v50.474a.807.807 0 01-.8.802zm-14.155-1.603h13.353V24.89c0-.438.363-.8.801-.8h7.68L65.803 8.93 50.643 24.09h7.68c.439 0 .802.362.802.8v50.474z"
      ></path>
    </svg>
  );
}