export default function Hunting() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="116"
      height="106"
      fill="none"
      viewBox="0 0 116 106"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M57.878 39.619c10.75 0 19.464-8.714 19.464-19.463 0-10.75-8.714-19.464-19.464-19.464-10.749 0-19.463 8.714-19.463 19.464 0 10.749 8.714 19.463 19.463 19.463zM98.584 94.241c9.214 0 16.683-7.469 16.683-16.682 0-9.214-7.469-16.683-16.683-16.683-9.213 0-16.683 7.469-16.683 16.683 0 9.213 7.47 16.682 16.683 16.682zM28.266 105.308c15.356 0 27.805-12.449 27.805-27.805S43.622 49.698 28.266 49.698.461 62.147.461 77.503s12.449 27.805 27.805 27.805z"
      ></path>
      <path
        fill="#fff"
        d="M62.785 92.796c20.731 0 37.537-16.806 37.537-37.537 0-20.73-16.806-37.536-37.537-37.536-20.73 0-37.536 16.805-37.536 37.536s16.806 37.537 37.536 37.537z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M87.157 26.718c5.602 6.561 8.994 15.07 8.994 24.37 0 20.729-16.807 37.537-37.536 37.537-9.3 0-17.809-3.392-24.37-8.995 6.88 8.05 17.113 13.166 28.54 13.166 20.73 0 37.537-16.808 37.537-37.537 0-11.428-5.116-21.66-13.165-28.541z"
      ></path>
      <path
        fill="#284B9C"
        d="M62.786 93.838c-21.271 0-38.58-17.308-38.58-38.579 0-21.27 17.309-38.579 38.58-38.579 21.27 0 38.579 17.309 38.579 38.58 0 21.27-17.309 38.578-38.58 38.578zm0-75.073c-20.117 0-36.494 16.377-36.494 36.494s16.377 36.494 36.494 36.494c20.116 0 36.493-16.377 36.493-36.494S82.902 18.765 62.786 18.765z"
      ></path>
      <path
        fill="#284B9C"
        d="M107.273 56.302H18.298a1.05 1.05 0 01-1.043-1.043c0-.57.473-1.042 1.043-1.042h88.975c.57 0 1.043.472 1.043 1.042a1.05 1.05 0 01-1.043 1.043z"
      ></path>
      <path
        fill="#284B9C"
        d="M62.786 100.789a1.05 1.05 0 01-1.043-1.042V10.772c0-.57.473-1.043 1.043-1.043s1.042.473 1.042 1.043v88.975a1.05 1.05 0 01-1.042 1.042z"
      ></path>
      <path
        fill="#fff"
        d="M32.215 20.503a2.78 2.78 0 100-5.56 2.78 2.78 0 000 5.56z"
      ></path>
      <path
        fill="#284B9C"
        d="M32.214 21.546a3.821 3.821 0 01-3.823-3.823 3.821 3.821 0 013.823-3.823 3.821 3.821 0 013.823 3.823 3.821 3.821 0 01-3.823 3.823zm0-5.561a1.738 1.738 0 10.001 3.477 1.738 1.738 0 000-3.477z"
      ></path>
      <path
        fill="#fff"
        d="M91.98 20.503a2.78 2.78 0 100-5.56 2.78 2.78 0 000 5.56z"
      ></path>
      <path
        fill="#284B9C"
        d="M91.98 21.546a3.821 3.821 0 01-3.822-3.823A3.821 3.821 0 0191.98 13.9a3.821 3.821 0 013.823 3.823 3.821 3.821 0 01-3.823 3.823zm0-5.561a1.738 1.738 0 10.002 3.477 1.738 1.738 0 00-.001-3.477z"
      ></path>
      <path
        fill="#fff"
        d="M100.322 30.235a2.78 2.78 0 100-5.56 2.78 2.78 0 000 5.56z"
      ></path>
      <path
        fill="#284B9C"
        d="M100.322 31.278a3.821 3.821 0 01-3.823-3.824 3.821 3.821 0 013.823-3.823 3.821 3.821 0 013.823 3.823 3.821 3.821 0 01-3.823 3.824zm0-5.561a1.738 1.738 0 10.002 3.476 1.738 1.738 0 00-.002-3.476z"
      ></path>
      <path
        fill="#fff"
        d="M26.654 85.844a2.78 2.78 0 100-5.56 2.78 2.78 0 000 5.56z"
      ></path>
      <path
        fill="#284B9C"
        d="M26.653 86.887a3.821 3.821 0 01-3.823-3.823 3.821 3.821 0 013.823-3.823 3.821 3.821 0 013.823 3.823 3.821 3.821 0 01-3.823 3.823zm0-5.56a1.739 1.739 0 10.002 3.476 1.739 1.739 0 00-.002-3.477z"
      ></path>
      <path
        fill="#fff"
        d="M36.385 95.576a2.78 2.78 0 100-5.56 2.78 2.78 0 000 5.56z"
      ></path>
      <path
        fill="#284B9C"
        d="M36.385 96.619a3.821 3.821 0 01-3.823-3.823 3.821 3.821 0 013.823-3.824 3.821 3.821 0 013.823 3.824 3.821 3.821 0 01-3.823 3.823zm0-5.561a1.738 1.738 0 10.001 3.477 1.738 1.738 0 000-3.477z"
      ></path>
      <path
        fill="#fff"
        d="M98.932 90.015a2.78 2.78 0 100-5.56 2.78 2.78 0 000 5.56z"
      ></path>
      <path
        fill="#284B9C"
        d="M98.932 91.058a3.821 3.821 0 01-3.823-3.823 3.821 3.821 0 013.823-3.823 3.821 3.821 0 013.823 3.823 3.821 3.821 0 01-3.823 3.823zm0-5.561a1.738 1.738 0 10.001 3.477 1.738 1.738 0 00-.001-3.477zM18.312 28.497a1.05 1.05 0 01-1.043-1.043V10.772c0-.57.473-1.043 1.043-1.043h16.683c.57 0 1.042.473 1.042 1.043a1.05 1.05 0 01-1.042 1.042h-15.64v15.64a1.05 1.05 0 01-1.043 1.043zM107.273 28.497a1.05 1.05 0 01-1.042-1.043v-15.64h-15.64a1.05 1.05 0 01-1.043-1.042c0-.57.472-1.043 1.043-1.043h16.682c.57 0 1.043.473 1.043 1.043v16.682a1.05 1.05 0 01-1.043 1.043z"
      ></path>
      <path
        fill="#284B9C"
        d="M107.273 100.789H90.591a1.05 1.05 0 01-1.043-1.042c0-.57.472-1.043 1.043-1.043h15.64v-15.64c0-.57.472-1.043 1.042-1.043.57 0 1.043.473 1.043 1.043v16.683a1.05 1.05 0 01-1.043 1.042zM34.98 100.789H18.313a1.05 1.05 0 01-1.043-1.042V83.064c0-.57.473-1.043 1.043-1.043s1.042.473 1.042 1.043v15.64h15.64c.57 0 1.043.473 1.043 1.043a1.05 1.05 0 01-1.042 1.042h-.014z"
      ></path>
      <path
        fill="#fff"
        d="M62.786 83.064c15.356 0 27.805-12.449 27.805-27.805S78.14 27.454 62.786 27.454c-15.356 0-27.805 12.449-27.805 27.805S47.43 83.064 62.786 83.064z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M80.247 33.627a27.708 27.708 0 016.172 17.462c0 15.348-12.442 27.804-27.804 27.804-6.618 0-12.68-2.322-17.462-6.172 5.102 6.311 12.888 10.343 21.632 10.343 15.363 0 27.805-12.443 27.805-27.805 0-8.744-4.045-16.53-10.343-21.632z"
      ></path>
      <path
        fill="#284B9C"
        d="M62.785 84.107c-15.904 0-28.847-12.944-28.847-28.848 0-15.904 12.943-28.847 28.847-28.847 15.905 0 28.848 12.943 28.848 28.847 0 15.904-12.943 28.848-28.848 28.848zm0-55.61c-14.75 0-26.762 12.012-26.762 26.762s12.012 26.762 26.762 26.762S89.548 70.023 89.548 55.26c0-14.764-12.012-26.762-26.763-26.762z"
      ></path>
      <path
        fill="#fff"
        d="M62.786 74.722c10.749 0 19.463-8.713 19.463-19.463 0-10.75-8.714-19.463-19.463-19.463-10.75 0-19.464 8.714-19.464 19.463 0 10.75 8.714 19.463 19.464 19.463z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M74.38 39.647c2.405 3.239 3.851 7.243 3.851 11.594 0 10.747-8.716 19.464-19.463 19.464-4.352 0-8.355-1.446-11.595-3.851 3.545 4.768 9.204 7.868 15.613 7.868 10.746 0 19.463-8.716 19.463-19.463 0-6.395-3.1-12.053-7.869-15.612z"
      ></path>
      <path
        fill="#284B9C"
        d="M62.786 75.765c-11.303 0-20.506-9.203-20.506-20.506s9.203-20.506 20.506-20.506c11.302 0 20.506 9.203 20.506 20.506s-9.204 20.506-20.506 20.506zm0-38.926c-10.163 0-18.42 8.258-18.42 18.42 0 10.163 8.257 18.42 18.42 18.42 10.162 0 18.42-8.257 18.42-18.42 0-10.163-8.258-18.42-18.42-18.42zM62.786 77.559a1.02 1.02 0 011.015 1.014 1.02 1.02 0 01-1.015 1.015 1.02 1.02 0 01-1.015-1.014 1.02 1.02 0 011.015-1.015zM62.786 30.93a1.02 1.02 0 011.015 1.015 1.02 1.02 0 01-1.015 1.015 1.02 1.02 0 01-1.015-1.015 1.02 1.02 0 011.015-1.015zM86.114 56.274a1.015 1.015 0 100-2.03 1.015 1.015 0 000 2.03zM38.456 55.26a1.02 1.02 0 011.015-1.016 1.02 1.02 0 011.015 1.015 1.02 1.02 0 01-1.015 1.015 1.02 1.02 0 01-1.014-1.015z"
      ></path>
      <path
        fill="#284B9C"
        d="M78.565 71.025c.39-.39 1.043-.39 1.432 0 .39.389.39 1.042 0 1.431-.39.39-1.043.39-1.432 0a1.024 1.024 0 010-1.431zM45.588 38.062c.39-.39 1.043-.39 1.432 0 .39.39.39 1.043 0 1.432-.389.39-1.042.39-1.432 0a1.024 1.024 0 010-1.432zM78.565 39.494a1.024 1.024 0 010-1.432c.39-.39 1.043-.39 1.432 0 .39.39.39 1.043 0 1.432-.39.39-1.043.39-1.432 0zM45.588 72.456a1.024 1.024 0 010-1.431c.39-.39 1.043-.39 1.432 0 .39.389.39 1.042 0 1.431-.389.39-1.042.39-1.432 0zM83.403 63.795a1.001 1.001 0 011.32-.542c.515.209.765.806.543 1.32a1.006 1.006 0 01-1.321.543 1.001 1.001 0 01-.542-1.32zM40.32 45.945a1.001 1.001 0 011.32-.543c.514.223.765.807.542 1.321a1.001 1.001 0 01-1.32.542 1.001 1.001 0 01-.543-1.32zM71.322 34.656a1.001 1.001 0 01-.542-1.32 1.001 1.001 0 011.32-.543c.515.222.765.806.543 1.32a1.001 1.001 0 01-1.321.543zM53.485 77.74a1.001 1.001 0 01-.542-1.321 1.006 1.006 0 011.32-.543c.515.209.765.807.543 1.321a1.001 1.001 0 01-1.321.542zM83.403 46.723a1.013 1.013 0 01.542-1.32 1.013 1.013 0 011.32.542c.21.514-.027 1.112-.541 1.32a1.013 1.013 0 01-1.321-.542zM40.32 64.574a1.013 1.013 0 01.541-1.321c.515-.209 1.113.028 1.321.542a1.013 1.013 0 01-.542 1.321c-.514.209-1.112-.028-1.32-.542zM54.264 34.656a1.013 1.013 0 01-1.321-.542c-.209-.515.028-1.113.542-1.321.514-.209 1.112.028 1.32.542a1.013 1.013 0 01-.541 1.32zM72.1 77.74a1.013 1.013 0 01-1.32-.543c-.209-.514.028-1.112.542-1.32.514-.21 1.112.027 1.32.542a1.013 1.013 0 01-.542 1.32z"
      ></path>
    </svg>
  );
}