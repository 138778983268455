import Link from "next/link";

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import LinkedIn from '@mui/icons-material/LinkedIn';

import Row from "@components/Modules/Utils/Row/Row";
import Logo from "@components/UI/Logo/Logo";
import { renderCopyrightMenu, renderFooterMenu } from "@helper/menu";
import { NextRouter, useRouter } from 'next/router';
import styles from './Footer.module.scss';

import CloseIcon from '@mui/icons-material/Close';
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Newsletter from "@components/Form/Newsletter";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import Box from "@mui/material/Box";

export default function Footer({ menus: { footerMenuColOne, footerMenuColTwo, footerMenuColThree, footerMenuColThreeEng, copyrightMenu } }) {

  const { t } = useTranslation();
  const router: NextRouter = useRouter();
  const [newsletterModal, setNewsletterModal] = useState(false);
  
  const socials = [
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/edison-next/',
      icon: <LinkedIn />
    }
  ]

  const handleOpen = (): void => { setNewsletterModal(true) }
  const handleClose = (): void => { setNewsletterModal(false) }

  return (
    <footer className={styles.footer}>
      <Container maxWidth={false} className={styles.footerContent} >
        <Row>
          <Grid item xs={12} mb={24}>
            <Link href="/" aria-label="Logo Edison NEXT">
              <Logo color="#ffffff" />
            </Link>
          </Grid>

          <Grid item md={4}>

            <Typography variant="bodyS" component="p" mb={16} fontWeight={300}>{t('footer.social')}</Typography>

            <Grid container columnSpacing={22} mt={24} mb={{ xs: 1, md: 2 }}>
              {socials && socials.map((el, i) => {

                return (
                  <Grid item key={i}>
                    <Link href={el.url} passHref aria-label={el.name}>
                      {el.icon}
                    </Link>
                  </Grid>
                )
              })}
              <Grid item>
                <Link href={'https://edison.it/'} passHref aria-label="Edison" target="_blank">
                  <Typography variant="description">edison.it</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link href={'https://edisonnext.es/'} passHref aria-label="Edison" target="_blank">
                  <Typography variant="description">edisonnext.es</Typography>
                </Link>
              </Grid>
            </Grid>

            <Box mt={70}>
              <Typography
                variant="bodyM"
                component="span"
                onClick={handleOpen}
                sx={{
                  textDecoration: 'underline',
                  fontWeight: 500,
                  cursor: 'pointer'
                }}
              >
                {t('newsletter.subscribe')}
              </Typography>
            </Box>

          </Grid>

          <Grid item md={1} />

          <Grid item xs={12} md={2} className={`${styles.footerMenu} ${styles.footerMenuColOne}`}>
            {renderFooterMenu(footerMenuColOne)}
          </Grid>

          <Grid item xs={12} md={2} className={`${styles.footerMenu} ${styles.footerMenuColTwo}`}>
            {renderFooterMenu(footerMenuColTwo)}
          </Grid>

          <Grid item xs={12} md={2} className={`${styles.footerMenu} ${styles.footerMenuColThree}`}>
            {router.locale == "it" ? renderFooterMenu(footerMenuColThree) : renderFooterMenu(footerMenuColThreeEng)}
          </Grid>
        </Row>
      </Container>

      <Container maxWidth={false} className={styles.copyright} >
        <Row flexDirection={{ md: 'row-reverse' }}>

          <Grid item md={5} className={styles.copyrightMenu}>
            {renderCopyrightMenu(copyrightMenu)}
          </Grid>

          <Grid item md={7}>
            <Typography variant="bodyS" component="p" fontWeight={300} mb={16}>Copyright © 2022 Edison Next S.p.A. - Via Acqui 86, 10098 Rivoli (TO)</Typography>
            <Typography variant="bodyS" component="p" fontWeight={300}>
              Società a socio unico soggetta all’attività di direzione e coordinamento di Edison S.p.A.<br />
              Capitale Soc. euro 330.500.000 i.v. - Reg. Imprese di TORINO C.F. 13032970157 - PEC: edisonnext@pec.edison.it<br />
              <Link href={ router.locale === 'it' ? 'https://email.edisonnext.it/hubfs/Informative%20privacy/A4%20informativa_2022_12_web.pdf' : '/en/legal-information-and-certifications'} passHref aria-label="legal" target="_blank">
                <strong>Informazioni legali e certificazioni</strong>
              </Link>
              {' '}
              con i dettagli di contatto per singola legal entity
            </Typography>
          </Grid>

        </Row>
      </Container>

      <Dialog
        open={newsletterModal}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={'xl'}
        PaperProps={{ square: true, elevation: 1 }}
      >
        <Box sx={{ position: 'absolute', right: 12, top: 12 }}>
          <IconButton edge="start" color="primary" onClick={handleClose} aria-label="close-newsletter-modal" size="large" >
            <CloseIcon fontSize="inherit"/>
          </IconButton>
        </Box>
        <Newsletter isModal />
      </Dialog>

    </footer>
  )
}