export default function ConsulenzaEnergetica() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M29 43c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14zM76.25 71.23c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12z"
      ></path>
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M49.012 89.61c11.045 0 20-8.954 20-20s-8.955-20-20-20c-11.046 0-20 8.954-20 20s8.954 20 20 20z"
      ></path>
      <path
        fill="#fff"
        d="M71 33.86H38c-2.21 0-4 1.79-4 4v32c0 2.21 1.79 4 4 4h27l10 8v-44c0-2.21-1.79-4-4-4z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M75 66.87H44c-1.1 0-2 .9-2 2v5h23l10 8v-15z"
      ></path>
      <path
        fill="#284B9C"
        d="M75 82.61c-.17 0-.33-.06-.47-.16l-9.79-7.84H38c-2.62 0-4.75-2.13-4.75-4.75v-32c0-2.62 2.13-4.75 4.75-4.75h33c2.62 0 4.75 2.13 4.75 4.75v44c0 .29-.17.55-.42.68-.1.05-.21.07-.33.07zm-37-48c-1.79 0-3.25 1.46-3.25 3.25v32c0 1.79 1.46 3.25 3.25 3.25h27c.17 0 .34.06.47.17l8.78 7.03V37.86c0-1.79-1.46-3.25-3.25-3.25H38z"
      ></path>
      <path
        fill="#fff"
        d="M62 22.86H22c-2.21 0-4 1.79-4 4v38l10-8h34c2.21 0 4-1.79 4-4v-26c0-2.21-1.79-4-4-4z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M66 49.87H30a2 2 0 00-2 2v5h34c2.21 0 4-1.79 4-4v-3z"
      ></path>
      <path
        fill="#284B9C"
        d="M18 65.61c-.11 0-.22-.02-.33-.07a.744.744 0 01-.42-.68v-38c0-2.62 2.13-4.75 4.75-4.75h40c2.62 0 4.75 2.13 4.75 4.75v26c0 2.62-2.13 4.75-4.75 4.75H28.26l-9.79 7.84c-.14.11-.3.16-.47.16zm4-42c-1.79 0-3.25 1.46-3.25 3.25V63.3l8.78-7.03c.13-.11.3-.16.47-.16h34c1.79 0 3.25-1.46 3.25-3.25v-26c0-1.79-1.46-3.25-3.25-3.25H22z"
      ></path>
      <path
        fill="#284B9C"
        d="M23 37.98a.75.75 0 100-1.5.75.75 0 000 1.5zM23 42.48a.75.75 0 100-1.5.75.75 0 000 1.5zM23 46.98a.75.75 0 100-1.5.75.75 0 000 1.5zM47.78 71.61H39c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8.78c.41 0 .75.34.75.75s-.34.75-.75.75zM54 71.61h-2.21c-.41 0-.75-.34-.75-.75s.34-.75.75-.75H54c.41 0 .75.34.75.75s-.34.75-.75.75zM54.78 54.61H46c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h8.78c.41 0 .75.34.75.75s-.34.75-.75.75zM61 54.61h-2.21c-.41 0-.75-.34-.75-.75s.34-.75.75-.75H61c.41 0 .75.34.75.75s-.34.75-.75.75zM83 31.61h-4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75z"
      ></path>
      <path
        fill="#284B9C"
        d="M81 33.61c-.41 0-.75-.34-.75-.75v-4c0-.41.34-.75.75-.75s.75.34.75.75v4c0 .41-.34.75-.75.75zM27 71.62h-4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75z"
      ></path>
      <path
        fill="#284B9C"
        d="M25 73.62c-.41 0-.75-.34-.75-.75v-4c0-.41.34-.75.75-.75s.75.34.75.75v4c0 .41-.34.75-.75.75zM46 82.62h-4c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h4c.41 0 .75.34.75.75s-.34.75-.75.75z"
      ></path>
      <path
        fill="#284B9C"
        d="M44 84.62c-.41 0-.75-.34-.75-.75v-4c0-.41.34-.75.75-.75s.75.34.75.75v4c0 .41-.34.75-.75.75z"
      ></path>
    </svg>
  );
}