export default function Delivery() {
  return (
    <svg width="104" height="104" viewBox="0 0 104 104" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M76.5471 45.0458C84.2617 45.0458 90.5157 38.7919 90.5157 31.0772C90.5157 23.3626 84.2617 17.1086 76.5471 17.1086C68.8324 17.1086 62.5785 23.3626 62.5785 31.0772C62.5785 38.7919 68.8324 45.0458 76.5471 45.0458Z" fill="black" fillOpacity="0.1"/>
      <path d="M51.1043 92.0001C57.7168 92.0001 63.0774 86.6396 63.0774 80.027C63.0774 73.4145 57.7168 68.054 51.1043 68.054C44.4918 68.054 39.1312 73.4145 39.1312 80.027C39.1312 86.6396 44.4918 92.0001 51.1043 92.0001Z" fill="black" fillOpacity="0.1"/>
      <path d="M32.9551 51.9102C43.976 51.9102 52.9102 42.976 52.9102 31.9551C52.9102 20.9342 43.976 12 32.9551 12C21.9342 12 13 20.9342 13 31.9551C13 42.976 21.9342 51.9102 32.9551 51.9102Z" fill="black" fillOpacity="0.1"/>
      <path d="M21.4211 30.8179H69.3134V82.7012C69.3134 83.7987 68.4154 84.6967 67.3179 84.6967H23.4166C22.3191 84.6967 21.4211 83.7987 21.4211 82.7012V30.8179Z" fill="white"/>
      <path d="M67.3178 84.6967H27.4076C27.4076 65.9588 42.5934 50.7729 61.3313 50.7729H69.3133V82.7011C69.3133 83.7987 68.4154 84.6967 67.3178 84.6967Z" fill="#BAE1A2"/>
      <path d="M67.3178 85.445H23.4166C21.9 85.445 20.6727 84.2178 20.6727 82.7012V30.8179C20.6727 30.4088 21.012 30.0696 21.421 30.0696H69.3133C69.7224 30.0696 70.0616 30.4088 70.0616 30.8179V82.7012C70.0616 84.2178 68.8344 85.445 67.3178 85.445ZM22.1694 31.5662V82.7012C22.1694 83.3897 22.7281 83.9484 23.4166 83.9484H67.3178C68.0063 83.9484 68.565 83.3897 68.565 82.7012V31.5662H22.1694Z" fill="#284B9C"/>
      <path d="M23.4166 20.8403H67.3179C68.4154 20.8403 69.3134 21.7383 69.3134 22.8358V30.8179H21.4211V22.8358C21.4211 21.7383 22.3191 20.8403 23.4166 20.8403Z" fill="white"/>
      <path d="M37.3852 25.8291H69.3134V30.8179H35.3896V27.8246C35.3896 26.7271 36.2876 25.8291 37.3852 25.8291Z" fill="#B8D6EC"/>
      <path d="M69.3133 31.566H21.421C21.012 31.566 20.6727 31.2268 20.6727 30.8177V22.8356C20.6727 21.319 21.9 20.0918 23.4166 20.0918H67.3178C68.8344 20.0918 70.0616 21.319 70.0616 22.8356V30.8177C70.0616 31.2268 69.7224 31.566 69.3133 31.566ZM22.1694 30.0694H68.565V22.8356C68.565 22.1472 68.0063 21.5884 67.3178 21.5884H23.4166C22.7281 21.5884 22.1694 22.1472 22.1694 22.8356V30.0694Z" fill="#284B9C"/>
      <path d="M54.347 26.8368C54.0776 26.8368 53.8182 26.727 53.6386 26.5375C53.449 26.3579 53.3492 26.0985 53.3492 25.839C53.3492 25.5796 53.449 25.3202 53.6386 25.1306C53.9978 24.7615 54.6763 24.7615 55.0454 25.1306C55.235 25.3202 55.3448 25.5696 55.3448 25.839C55.3448 26.1084 55.235 26.3579 55.0454 26.5375C54.8658 26.727 54.6064 26.8368 54.347 26.8368Z" fill="#284B9C"/>
      <path d="M58.338 25.8391C58.338 25.2803 58.777 24.8413 59.3358 24.8413C59.8845 24.8413 60.3335 25.2803 60.3335 25.8391C60.3335 26.3878 59.8845 26.8368 59.3358 26.8368C58.777 26.8368 58.338 26.3878 58.338 25.8391Z" fill="#284B9C"/>
      <path d="M64.3245 26.8368C64.0551 26.8368 63.8057 26.727 63.6161 26.5375C63.4266 26.3579 63.3268 26.0985 63.3268 25.839C63.3268 25.5796 63.4266 25.3102 63.6161 25.1306C63.9853 24.7615 64.6538 24.7615 65.023 25.1306C65.2125 25.3202 65.3223 25.5696 65.3223 25.839C65.3223 26.1084 65.2125 26.3579 65.023 26.5375C64.8434 26.727 64.584 26.8368 64.3245 26.8368Z" fill="#284B9C"/>
      <path d="M40.7975 35.5672H30.0417C29.6326 35.5672 29.2934 35.228 29.2934 34.8189C29.2934 34.4098 29.6326 34.0706 30.0417 34.0706H40.7975C41.2066 34.0706 41.5458 34.4098 41.5458 34.8189C41.5458 35.228 41.2066 35.5672 40.7975 35.5672Z" fill="#284B9C"/>
      <path d="M46.9935 35.5672H44.7885C44.3794 35.5672 44.0402 35.228 44.0402 34.8189C44.0402 34.4098 44.3794 34.0706 44.7885 34.0706H46.9935C47.4026 34.0706 47.7418 34.4098 47.7418 34.8189C47.7418 35.228 47.4026 35.5672 46.9935 35.5672Z" fill="#284B9C"/>
      <path d="M27.4076 47.2208L35.3896 55.2029V50.2141H51.3537V44.2275H35.3896V39.2388L27.4076 47.2208Z" fill="white"/>
      <path d="M35.3897 50.2142H51.3537V47.2209H35.22C34.6912 47.2209 34.1824 47.4305 33.8132 47.8096L30.9097 50.7131L35.3996 55.203V50.2142H35.3897Z" fill="#B8D6EC"/>
      <path d="M35.3897 55.9512C35.1901 55.9512 35.0005 55.8714 34.8609 55.7317L26.8788 47.7496C26.5895 47.4603 26.5895 46.9814 26.8788 46.692L34.8609 38.71C35.0704 38.5004 35.3997 38.4306 35.679 38.5503C35.9584 38.6701 36.138 38.9395 36.138 39.2388V43.4792H51.3538C51.7628 43.4792 52.1021 43.8185 52.1021 44.2276V50.2141C52.1021 50.6232 51.7628 50.9624 51.3538 50.9624H36.138V55.2029C36.138 55.5022 35.9584 55.7816 35.679 55.8913C35.5892 55.9312 35.4895 55.9512 35.3897 55.9512ZM28.4652 47.2208L34.6414 53.3969V50.2141C34.6414 49.805 34.9806 49.4658 35.3897 49.4658H50.6055V44.9759H35.3897C34.9806 44.9759 34.6414 44.6366 34.6414 44.2276V41.0447L28.4652 47.2208Z" fill="#284B9C"/>
      <path d="M41.3762 67.1859L33.3942 59.2039V64.1926H21.4211V70.1792H33.3942V75.168L41.3762 67.1859Z" fill="white"/>
      <path d="M41.3762 67.186L36.3874 62.1973V65.1905C36.3874 66.2881 35.4894 67.186 34.3919 67.186H28.4054C27.3078 67.186 26.4099 68.084 26.4099 69.1816V70.1793H33.3941V75.1681L41.3762 67.186Z" fill="#B8D6EC"/>
      <path d="M33.3941 75.9163C33.2943 75.9163 33.2045 75.8963 33.1048 75.8564C32.8254 75.7367 32.6458 75.4673 32.6458 75.168V70.9275H21.421C21.012 70.9275 20.6727 70.5883 20.6727 70.1792V64.1927C20.6727 63.7836 21.012 63.4443 21.421 63.4443H32.6458V59.2039C32.6458 58.9046 32.8254 58.6252 33.1048 58.5154C33.3841 58.3957 33.7034 58.4655 33.9229 58.6751L41.905 66.6571C42.1943 66.9465 42.1943 67.4254 41.905 67.7147L33.9229 75.6968C33.7832 75.8365 33.5837 75.9163 33.3941 75.9163ZM22.1694 69.4309H33.3941C33.8032 69.4309 34.1424 69.7701 34.1424 70.1792V73.362L40.3185 67.1859L34.1424 61.0098V64.1927C34.1424 64.6017 33.8032 64.941 33.3941 64.941H22.1694V69.4309Z" fill="#284B9C"/>
      <path d="M64.3246 78.72C75.8965 78.72 85.2775 69.339 85.2775 57.7671C85.2775 46.1951 75.8965 36.8142 64.3246 36.8142C52.7526 36.8142 43.3717 46.1951 43.3717 57.7671C43.3717 69.339 52.7526 78.72 64.3246 78.72Z" fill="white"/>
      <path d="M85.2774 57.767C85.2774 54.0753 84.3196 50.6131 82.6433 47.5999C79.6301 45.9237 76.1679 44.9658 72.4762 44.9658C60.9022 44.9658 51.5233 54.3447 51.5233 65.9187C51.5233 69.6104 52.4812 73.0726 54.1574 76.0858C57.1706 77.7621 60.6328 78.7199 64.3245 78.7199C75.8985 78.7199 85.2774 69.341 85.2774 57.767Z" fill="#BAE1A2"/>
      <path d="M64.3245 79.4683C52.3614 79.4683 42.6234 69.7302 42.6234 57.7671C42.6234 45.804 52.3614 36.0659 64.3245 36.0659C76.2876 36.0659 86.0257 45.804 86.0257 57.7671C86.0257 69.7302 76.2876 79.4683 64.3245 79.4683ZM64.3245 37.5626C53.1796 37.5626 44.12 46.6222 44.12 57.7671C44.12 68.912 53.1796 77.9717 64.3245 77.9717C75.4695 77.9717 84.5291 68.912 84.5291 57.7671C84.5291 46.6222 75.4695 37.5626 64.3245 37.5626Z" fill="#284B9C"/>
      <path d="M75.9883 63.8135L70.6004 62.3967H64.3345V78.71C70.2911 78.71 75.6491 76.2156 79.4705 72.2246V68.3134C79.4705 66.1982 78.0437 64.3424 75.9983 63.8135H75.9883Z" fill="white"/>
      <path d="M58.0586 62.3967L52.6608 63.8135C50.6154 64.3523 49.1886 66.1982 49.1886 68.3134V72.2246C53 76.2156 58.368 78.71 64.3246 78.71V62.3967H58.0586Z" fill="white"/>
      <path d="M75.9884 63.8135L74.5815 63.4443C74.7212 63.8833 74.801 64.3423 74.801 64.8212V67.7646C74.801 68.3832 74.5616 68.9919 74.1126 69.4209C71.4286 71.9751 68.0662 73.831 64.3246 74.6791V78.71C70.2812 78.71 75.6391 76.2156 79.4605 72.2246V68.3134C79.4605 66.1981 78.0338 64.3423 75.9884 63.8135Z" fill="#B8D6EC"/>
      <path d="M64.3246 62.3967H59.6651V74.0206C59.6651 74.6791 59.1263 75.2179 58.4678 75.188C55.275 75.0084 52.2717 74.1004 49.6177 72.6437C53.3992 76.3853 58.5775 78.72 64.3146 78.72V62.4067L64.3246 62.3967Z" fill="#B8D6EC"/>
      <path d="M64.3245 79.4684C63.9155 79.4684 63.5762 79.1292 63.5762 78.7201V62.4068C63.5762 61.9977 63.9155 61.6584 64.3245 61.6584H70.5905C70.6503 61.6584 70.7202 61.6584 70.78 61.6784L76.1779 63.0952C78.5526 63.7138 80.2088 65.869 80.2088 68.3235V72.2347C80.2088 72.4242 80.139 72.6138 79.9993 72.7535C75.8586 77.0838 70.2911 79.4684 64.3245 79.4684ZM65.0729 63.1551V77.9618C70.2412 77.7722 75.0504 75.657 78.7122 71.9353V68.3235C78.7122 66.5475 77.5149 64.991 75.7987 64.542L70.5007 63.1451H65.0828L65.0729 63.1551Z" fill="#284B9C"/>
      <path d="M64.3246 79.4684C58.358 79.4684 52.7905 77.0838 48.6498 72.7535C48.5201 72.6138 48.4403 72.4242 48.4403 72.2347V68.3235C48.4403 65.869 50.0966 63.7138 52.4712 63.0952L57.8691 61.6784C57.929 61.6584 57.9988 61.6584 58.0587 61.6584H64.3246C64.7337 61.6584 65.0729 61.9977 65.0729 62.4068V78.7201C65.0729 79.1292 64.7337 79.4684 64.3246 79.4684ZM49.9369 71.9353C53.5987 75.657 58.3979 77.7722 63.5763 77.9618V63.1551H58.1584L52.8604 64.5519C51.1442 65.0009 49.9469 66.5574 49.9469 68.3334V71.9453L49.9369 71.9353Z" fill="#284B9C"/>
      <path d="M64.3246 68.2935C64.3246 68.2935 70.5506 64.9311 70.5905 62.3968C69.2735 61.7183 68.2757 60.4911 68.0063 59.0044C67.9664 58.7849 67.9365 57.408 67.9065 57.1985H60.7327C60.7027 57.4379 60.6628 58.8548 60.6129 59.1142C60.3336 60.5709 59.3558 61.7582 58.0487 62.3968C58.1086 64.9211 64.3146 68.2935 64.3146 68.2935H64.3246Z" fill="white"/>
      <path d="M68.0063 59.0044C67.9664 58.7849 67.9365 57.408 67.9065 57.1985H63.8357C63.8556 57.418 64.2947 58.7251 64.3246 58.9446C64.4942 60.2217 65.5718 61.7782 66.4797 62.6263C66.789 62.9156 66.9587 63.3347 66.9088 63.7637C66.6593 65.7692 64.6638 67.655 64.0951 68.1538C64.2348 68.2337 64.3246 68.2836 64.3246 68.2836C64.3246 68.2836 70.5506 64.9211 70.5905 62.3868C69.2735 61.7083 68.2757 60.4811 68.0063 58.9944V59.0044Z" fill="#B8D6EC"/>
      <path d="M64.3245 69.0417C64.2048 69.0417 64.0751 69.0117 63.9653 68.9519C62.8678 68.3532 57.3702 65.2302 57.3103 62.4166C57.3103 62.1272 57.4699 61.8578 57.7294 61.7281C58.8668 61.1694 59.655 60.1616 59.8845 58.9743C59.9045 58.8446 59.9344 58.216 59.9444 57.8768C59.9643 57.5276 59.9743 57.2183 59.9843 57.1185C60.0242 56.7393 60.3435 56.46 60.7226 56.46H67.8965C68.2756 56.46 68.5949 56.7393 68.6448 57.1185C68.6548 57.2083 68.6648 57.5176 68.6847 57.8668C68.7047 58.2659 68.7147 58.7348 68.7346 58.8745C68.9541 60.0718 69.7823 61.1394 70.9297 61.7281C71.1791 61.8578 71.3388 62.1172 71.3388 62.4066C71.2988 65.2302 65.7812 68.3532 64.6837 68.9419C64.574 69.0018 64.4542 69.0317 64.3245 69.0317V69.0417ZM58.8967 62.7957C59.4156 64.1826 62.1694 66.218 64.3245 67.4453C66.4996 66.218 69.2634 64.1826 69.7623 62.7957C68.4652 61.9576 67.5473 60.6306 67.2679 59.1539C67.238 59.0042 67.228 58.7249 67.1981 57.9666H61.451C61.4211 58.7149 61.4011 59.0841 61.3612 59.2736C61.0718 60.7403 60.1938 61.9975 58.8967 62.8057V62.7957Z" fill="#284B9C"/>
      <path d="M71.5982 51.0422C71.5982 46.2231 68.3456 43.4792 64.3246 43.4792C60.3036 43.4792 57.051 46.2231 57.051 51.0422C57.051 55.8614 60.3036 59.7726 64.3246 59.7726C68.3456 59.7726 71.5982 55.8614 71.5982 51.0422Z" fill="white"/>
      <path d="M66.0706 43.6687C66.6294 44.7463 66.9487 46.0533 66.9487 47.55C66.9487 52.3691 63.696 56.2803 59.675 56.2803C59.256 56.2803 58.8369 56.2304 58.4378 56.1407C59.7648 58.3357 61.9 59.7725 64.3345 59.7725C68.3555 59.7725 71.6082 55.8613 71.6082 51.0421C71.6082 46.9413 69.2535 44.3572 66.0806 43.6687H66.0706Z" fill="#B8D6EC"/>
      <path d="M64.3246 49.2562C61.4311 47.9391 59.2161 47.7396 57.5698 47.999C57.2405 48.897 57.051 49.9147 57.051 51.0521C57.051 55.8713 60.3036 59.7825 64.3246 59.7825C68.3456 59.7825 71.5982 55.8713 71.5982 51.0521C71.5982 50.4535 71.5484 49.8947 71.4586 49.3659C69.5129 50.1142 67.0086 50.4834 64.3246 49.2562Z" fill="white"/>
      <path d="M71.4585 49.3559C70.77 49.6253 70.0117 49.8348 69.1936 49.9446C68.6947 54.1851 65.6715 57.4377 61.9998 57.4377C60.7825 57.4377 59.6251 57.0686 58.6174 56.43C59.9544 58.4555 62.0097 59.7625 64.3245 59.7625C68.3455 59.7625 71.5982 55.8513 71.5982 51.0322C71.5982 50.4335 71.5483 49.8748 71.4585 49.3459V49.3559Z" fill="#B8D6EC"/>
      <path d="M64.3246 60.5209C59.9045 60.5209 56.3026 56.2705 56.3026 51.0422C56.3026 49.8649 56.4922 48.7474 56.8614 47.7297C56.9512 47.4703 57.1806 47.2907 57.45 47.2508C59.5852 46.9215 61.9998 47.3705 64.6339 48.5678C66.6892 49.5057 68.8943 49.5356 71.1792 48.6576C71.3887 48.5778 71.6182 48.5977 71.8177 48.7075C72.0173 48.8172 72.147 49.0068 72.1869 49.2263C72.2867 49.805 72.3465 50.4136 72.3465 51.0422C72.3465 56.2705 68.7446 60.5209 64.3246 60.5209ZM58.1285 48.6775C57.909 49.4159 57.7992 50.2041 57.7992 51.0422C57.7992 55.4423 60.7227 59.0243 64.3246 59.0243C67.9265 59.0243 70.8499 55.4423 70.8499 51.0422C70.8499 50.8127 70.8499 50.5833 70.8299 50.3638C68.4653 51.0622 66.1804 50.9225 64.0153 49.9347C61.8402 48.937 59.8646 48.5179 58.1285 48.6775Z" fill="#284B9C"/>
      <path d="M64.3246 60.5209C59.9045 60.5209 56.3026 56.2705 56.3026 51.0423C56.3026 46.1433 59.6052 42.731 64.3246 42.731C69.044 42.731 72.3465 46.1533 72.3465 51.0423C72.3465 56.2705 68.7446 60.5209 64.3246 60.5209ZM64.3246 44.2276C60.4233 44.2276 57.7992 46.9714 57.7992 51.0423C57.7992 55.4424 60.7227 59.0243 64.3246 59.0243C67.9265 59.0243 70.8499 55.4424 70.8499 51.0423C70.8499 46.9614 68.2258 44.2276 64.3246 44.2276Z" fill="#284B9C"/>
      <path d="M75.689 24.5818H74.3121C73.903 24.5818 73.5638 24.2426 73.5638 23.8335C73.5638 23.4244 73.903 23.0852 74.3121 23.0852H75.689C76.0981 23.0852 76.4373 23.4244 76.4373 23.8335C76.4373 24.2426 76.0981 24.5818 75.689 24.5818Z" fill="#284B9C"/>
      <path d="M80.2887 24.5818H78.9118C78.5027 24.5818 78.1635 24.2426 78.1635 23.8335C78.1635 23.4244 78.5027 23.0852 78.9118 23.0852H80.2887C80.6978 23.0852 81.037 23.4244 81.037 23.8335C81.037 24.2426 80.6978 24.5818 80.2887 24.5818Z" fill="#284B9C"/>
      <path d="M77.2954 27.575C76.8863 27.575 76.5471 27.2357 76.5471 26.8266V25.4497C76.5471 25.0407 76.8863 24.7014 77.2954 24.7014C77.7045 24.7014 78.0437 25.0407 78.0437 25.4497V26.8266C78.0437 27.2357 77.7045 27.575 77.2954 27.575Z" fill="#284B9C"/>
      <path d="M77.2954 22.9753C76.8863 22.9753 76.5471 22.6361 76.5471 22.227V20.8501C76.5471 20.441 76.8863 20.1018 77.2954 20.1018C77.7045 20.1018 78.0437 20.441 78.0437 20.8501V22.227C78.0437 22.6361 77.7045 22.9753 77.2954 22.9753Z" fill="#284B9C"/>
      <path d="M80.6778 35.5672H79.3009C78.8918 35.5672 78.5526 35.228 78.5526 34.8189C78.5526 34.4098 78.8918 34.0706 79.3009 34.0706H80.6778C81.0869 34.0706 81.4261 34.4098 81.4261 34.8189C81.4261 35.228 81.0869 35.5672 80.6778 35.5672Z" fill="#284B9C"/>
      <path d="M85.2774 35.5672H83.9005C83.4915 35.5672 83.1522 35.228 83.1522 34.8189C83.1522 34.4098 83.4915 34.0706 83.9005 34.0706H85.2774C85.6865 34.0706 86.0258 34.4098 86.0258 34.8189C86.0258 35.228 85.6865 35.5672 85.2774 35.5672Z" fill="#284B9C"/>
      <path d="M82.2842 38.5603C81.8751 38.5603 81.5359 38.2211 81.5359 37.812V36.4351C81.5359 36.026 81.8751 35.6868 82.2842 35.6868C82.6933 35.6868 83.0325 36.026 83.0325 36.4351V37.812C83.0325 38.2211 82.6933 38.5603 82.2842 38.5603Z" fill="#284B9C"/>
      <path d="M82.2842 33.9507C81.8751 33.9507 81.5359 33.6114 81.5359 33.2024V31.8255C81.5359 31.4164 81.8751 31.0771 82.2842 31.0771C82.6933 31.0771 83.0325 31.4164 83.0325 31.8255V33.2024C83.0325 33.6114 82.6933 33.9507 82.2842 33.9507Z" fill="#284B9C"/>
      <path d="M78.6823 80.4661H77.3054C76.8963 80.4661 76.5571 80.1269 76.5571 79.7178C76.5571 79.3087 76.8963 78.9695 77.3054 78.9695H78.6823C79.0914 78.9695 79.4306 79.3087 79.4306 79.7178C79.4306 80.1269 79.0914 80.4661 78.6823 80.4661Z" fill="#284B9C"/>
      <path d="M83.2819 80.4661H81.905C81.4959 80.4661 81.1567 80.1269 81.1567 79.7178C81.1567 79.3087 81.4959 78.9695 81.905 78.9695H83.2819C83.691 78.9695 84.0302 79.3087 84.0302 79.7178C84.0302 80.1269 83.691 80.4661 83.2819 80.4661Z" fill="#284B9C"/>
      <path d="M80.2887 83.4592C79.8796 83.4592 79.5403 83.12 79.5403 82.7109V81.334C79.5403 80.9249 79.8796 80.5857 80.2887 80.5857C80.6977 80.5857 81.037 80.9249 81.037 81.334V82.7109C81.037 83.12 80.6977 83.4592 80.2887 83.4592Z" fill="#284B9C"/>
      <path d="M80.2887 78.8499C79.8796 78.8499 79.5403 78.5106 79.5403 78.1015V76.7246C79.5403 76.3156 79.8796 75.9763 80.2887 75.9763C80.6977 75.9763 81.037 76.3156 81.037 76.7246V78.1015C81.037 78.5106 80.6977 78.8499 80.2887 78.8499Z" fill="#284B9C"/>
    </svg>
  );
}