export default function EfficienzaEnergetica() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M77.28 59.75c7.97 0 14.44-6.17 14.44-13.79 0-7.62-6.46-13.79-14.44-13.79-7.98 0-14.44 6.17-14.44 13.79 0 7.62 6.46 13.79 14.44 13.79zM50.26 91.97c6.83 0 12.37-5.29 12.37-11.82s-5.54-11.82-12.37-11.82c-6.83 0-12.37 5.29-12.37 11.82s5.54 11.82 12.37 11.82zM35.62 55.4c11.39 0 20.62-8.82 20.62-19.7 0-10.88-9.23-19.7-20.62-19.7S15 24.82 15 35.7c0 10.88 9.23 19.7 20.62 19.7z"
      ></path>
      <path
        fill="#284B9C"
        d="M80.902 40.28h-4.12c-.42 0-.77-.34-.77-.74 0-.4.35-.74.77-.74h4.12c.42 0 .77.34.77.74 0 .4-.35.74-.77.74z"
      ></path>
      <path
        fill="#284B9C"
        d="M78.832 42.25c-.42 0-.77-.34-.77-.74v-3.94c0-.4.35-.74.77-.74.42 0 .77.34.77.74v3.94c0 .4-.35.74-.77.74zM29.902 28.28h-4.12c-.42 0-.77-.33-.77-.74s.35-.74.77-.74h4.12c.42 0 .77.34.77.74 0 .4-.35.74-.77.74z"
      ></path>
      <path
        fill="#284B9C"
        d="M27.832 30.25c-.42 0-.77-.34-.77-.74v-3.94c0-.4.35-.74.77-.74.42 0 .77.34.77.74v3.94c0 .4-.35.74-.77.74zM69.902 23.28h-4.12c-.42 0-.77-.33-.77-.74s.35-.74.77-.74h4.12c.42 0 .77.34.77.74 0 .4-.35.74-.77.74z"
      ></path>
      <path
        fill="#284B9C"
        d="M67.832 25.25c-.42 0-.77-.34-.77-.74v-3.94c0-.4.35-.74.77-.74.42 0 .77.34.77.74v3.94c0 .4-.35.74-.77.74z"
      ></path>
      <mask
        id="mask0_629_12777"
        style={{ maskType: "alpha" }}
        width="38"
        height="73"
        x="13"
        y="13"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#D9D9D9"
          d="M0 0H38V73H0z"
          transform="matrix(-1 0 0 1 51 13)"
        ></path>
      </mask>
      <g mask="url(#mask0_629_12777)">
        <path
          fill="#fff"
          d="M76.75 81.19h-52V48.31l26-21.12 26 21.12v32.88z"
        ></path>
        <path
          fill="#284B9C"
          d="M76.75 81.94h-52c-.41 0-.75-.34-.75-.75V48.31c0-.23.1-.44.28-.58l26-21.12c.28-.22.67-.22.95 0l26 21.12c.18.14.28.36.28.58v32.87c0 .41-.34.75-.75.75h-.01v.01zM25.5 80.45H76V48.68L50.75 28.16 25.5 48.67v31.78z"
        ></path>
        <path
          fill="#284B9C"
          d="M82.75 81.94h-64c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h64c.41 0 .75.34.75.75s-.34.75-.75.75z"
        ></path>
        <path
          fill="#B8D6EC"
          d="M18.75 53.19l32-26 32 26v-10l-32-26-32 26v10z"
        ></path>
        <path
          fill="#fff"
          d="M18.75 53.19l32-26 32 26v-5L52.01 23.21c-.74-.6-1.79-.6-2.52 0L18.75 48.19v5z"
        ></path>
        <path
          fill="#284B9C"
          d="M82.75 53.94c-.17 0-.34-.06-.47-.17L50.75 28.16 19.22 53.77c-.22.18-.53.22-.79.09a.753.753 0 01-.43-.68v-10c0-.23.1-.44.28-.58l32-25.99c.28-.22.67-.22.95 0l32 26c.18.14.28.36.28.58v10c0 .29-.17.55-.43.68-.1.05-.21.07-.32.07h-.01zm-32-27.5c.17 0 .33.06.47.17L82 51.62v-8.07L50.75 18.16 19.5 43.55v8.07l30.78-25.01c.14-.11.3-.17.47-.17z"
        ></path>
      </g>
      <path fill="#BAE1A2" d="M64.75 40.19v-10h-12v10h12z"></path>
      <path fill="#fff" d="M64.75 30.19v5h-8c-.55 0-1-.45-1-1v-4h9z"></path>
      <path
        fill="#284B9C"
        d="M52 40.19v-10c0-.41.34-.75.75-.75h12c.41 0 .75.34.75.75v10c0 .41-.34.75-.75.75h-12c-.41 0-.75-.34-.75-.75zm1.5-9.25v8.5H64v-8.5H53.5z"
      ></path>
      <path fill="#BAE1A2" d="M58.75 27.19v-10h-6v10h6z"></path>
      <path fill="#fff" d="M58.75 17.19v5h-2c-.55 0-1-.45-1-1v-4h3z"></path>
      <path
        fill="#284B9C"
        d="M52 27.19v-10c0-.41.34-.75.75-.75h6c.41 0 .75.34.75.75v10c0 .41-.34.75-.75.75h-6c-.41 0-.75-.34-.75-.75zm1.5-9.25v8.5H58v-8.5h-4.5z"
      ></path>
      <path fill="#BAE1A2" d="M70.75 53.19v-10h-18v10h18z"></path>
      <path fill="#fff" d="M70.75 43.19v5h-14c-.55 0-1-.45-1-1v-4h15z"></path>
      <path
        fill="#284B9C"
        d="M52 53.19v-10c0-.41.34-.75.75-.75h18c.41 0 .75.34.75.75v10c0 .41-.34.75-.75.75h-18c-.41 0-.75-.34-.75-.75zm1.5-9.25v8.5H70v-8.5H53.5z"
      ></path>
      <path fill="#BAE1A2" d="M76.75 67.19v-10h-24v10h24z"></path>
      <path fill="#fff" d="M76.75 57.19v5h-20c-.55 0-1-.45-1-1v-4h21z"></path>
      <path
        fill="#284B9C"
        d="M52 67.19v-10c0-.41.34-.75.75-.75h24c.41 0 .75.34.75.75v10c0 .41-.34.75-.75.75h-24c-.41 0-.75-.34-.75-.75zm1.5-9.25v8.5H76v-8.5H53.5z"
      ></path>
      <path fill="#BAE1A2" d="M82.75 81.19v-10h-30v10h30z"></path>
      <path fill="#fff" d="M82.75 71.19v5h-26c-.55 0-1-.45-1-1v-4h27z"></path>
      <path
        fill="#284B9C"
        d="M52 81.19v-10c0-.41.34-.75.75-.75h30c.41 0 .75.34.75.75v10c0 .41-.34.75-.75.75h-30c-.41 0-.75-.34-.75-.75zm1.5-9.25v8.5H82v-8.5H53.5z"
      ></path>
    </svg>
  );
}