import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

import Image from "next/legacy/image";

import Button from "@mui/material/Button";
import { MenuItemModel } from "./Menu.model";
import { useTranslation } from "next-i18next";
import { getThumbnailAlt, getThumbnailUrl } from "@helper/media";

export const MenuItemCard: React.FC<{item: MenuItemModel}> = ({ item }) => {
  const { t } = useTranslation();

  const image = {
    url: getThumbnailUrl(item?.field_menu_image) || '/assets/images/img-placeholder-01.jpg',
    alt: getThumbnailAlt(item?.field_menu_image) || item?.title,
  }
  
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          aspectRatio: '392 / 222',
        }}
      >
        <Image
          src={image?.url}
          alt={image?.alt}
          layout="fill"
          objectFit="cover"
        />
      </Box>

      {item?.field_menu_subtitle && <Typography variant="overline" textAlign="center" component="div" mt={24}>{item?.field_menu_subtitle}</Typography>}
      {item?.title && <Typography variant="h4" textTransform='initial' textAlign="center" mt={10}>{item?.title}</Typography>}

      {item?.url && (
        <Button
          variant="outlined"
          href={item?.url}
          sx={{ marginTop: 34 }}
        >
          {item?.field_cta_label ?? t("discoverMore")}
        </Button>
      )}
    </Box>
  );
}
