import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";

import styles from "@components/Common/Header/Header.module.scss";
import Row from "@components/Modules/Utils/Row/Row";

import React from "react";
import { MenuItemModel } from "../Menu.model";
import MenuItemExpanded from "../MenuItem/MenuItemExpanded";

const LayoutExpanded: React.FC<{item: MenuItemModel}> = ({item}) => {
  const columns: MenuItemModel[] = item?.items?.filter(el => el?.field_layout === "column");

  return (
    <Container maxWidth={false} className={styles.dropdownMenu}>
      <Row spacing={0}>

        {columns.map((col: MenuItemModel, index: number) => {
          return (
            <Grid key={col.id} item lg={4} position="relative" borderRight={index < 2 ? '1px solid #DFE1E6' : null}>
              {col.items?.length && 
                <List disablePadding component="div">
                  {col.items.map((item: MenuItemModel) => <MenuItemExpanded key={item.id} item={item}/> )}
                </List>
              }
            </Grid>
          );
        })}

      </Row>
    </Container>
  )
}

export default LayoutExpanded