import { getIconName } from "@helper/media"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import React from "react"
import * as Icons from '@components/UI/CustomIcons';
import { MenuItemModel, MenuLayoutVariants } from "../Menu.model"


const MenuItemExpanded: React.FC<{item: MenuItemModel}> = ({item}) => {

  const layout: MenuLayoutVariants = item.field_layout;
  const iconName = getIconName(item.field_menu_image)
  const Icon = iconName ? Icons[iconName] : null;
  
  return (
    <React.Fragment key={item.id}>
      <ListItemButton
        {...item?.url && {
          component: "a",
          href: item.url
        }}
      >

        {iconName && (
          <ListItemIcon
            sx={{
              minWidth: 48,
              width: 48,
              height: 48,
              'svg': {
                width: 'auto',
                height: 'auto',
              }
            }}
          >
            <Icon />
          </ListItemIcon>
        )}
        
        <ListItemText
          primary={item.title}
          {...layout === 'icon' && ({
            primaryTypographyProps: {
              variant: 'bodyS',
              color: 'secondary',
              fontWeight: '500',
            }
          })}
        />
      </ListItemButton>

      {item?.items?.length && (
        <List component="div" disablePadding>
          {item?.items?.map((subitem: MenuItemModel) => <MenuItemExpanded key={subitem.id} item={subitem}/> )}
        </List>
      )}
    </React.Fragment>
  )
}

export default MenuItemExpanded