
import ContactBar from './BarType/ContactBar';
import RegisterBar from './BarType/RegisterBar';

interface StickyBarProps {
  type: 'register' | 'contact-us' | 'deactivated';
}

export const StickyBar:React.FC<StickyBarProps> = ({type}) => {
  switch (type) {
    case 'register':
      return <RegisterBar />

    case 'deactivated':
      return null;

    case 'contact-us':
    default:
      return <ContactBar />
  }
}

export default StickyBar;