import styles from "@components/Common/Header/Header.module.scss";
import { useScrollLock } from "@hooks/useScrollLock";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { MenuItemModel } from "./Menu.model";
import MenuLayout from "./MenuLayout";
import Link from "next/link";
import { getUrlAttributes } from "@helper/media";

const useIsActive = (url: string) => {
  const {asPath, locale} = useRouter();
  return `/${locale}${asPath}`.includes(url);
};

export const MenuItemPrimary: React.FC<{item: MenuItemModel}> = ({ item }) => {
  const isActive: boolean = useIsActive(item.url);
  const [dropdown, setDropdown] = useState<boolean>(false);
  const { lockScroll, unlockScroll } = useScrollLock();

  const {href, title} = getUrlAttributes(item);

  useEffect(() => {
    setDropdown(!!item?.items?.length);

    const timeoutId = setTimeout(() => {
      setDropdown(!!item?.items?.length);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [item?.items?.length]);

  return (
    <Box
      className={`${styles.menuItem} ${dropdown ? styles.dropdown : ''}`}
      onMouseEnter={lockScroll}
      onMouseLeave={unlockScroll}
    >
      <Button
        component={Link}
        href={href}
        title={title}
        className={`${styles.item} ${isActive ? styles.itemActive : ''}`}
        variant="link"
        color="secondary"
      >
        {title}
      </Button>

      {item?.items?.length > 0 && <MenuLayout variant={item?.field_layout} item={item} />}
    </Box>
  );
};