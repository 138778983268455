export default function AutoproduzioneSostenibile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M78.764 62.836c8.276 0 14.984-6.708 14.984-14.983S87.04 32.87 78.764 32.87c-8.274 0-14.983 6.708-14.983 14.983s6.709 14.983 14.983 14.983zM35.41 92c7.092 0 12.842-5.75 12.842-12.843s-5.75-12.843-12.843-12.843-12.843 5.75-12.843 12.843S28.316 92 35.41 92zM31.405 54.81c11.821 0 21.404-9.584 21.404-21.405C52.81 21.583 43.226 12 31.405 12 19.583 12 10 21.583 10 33.405c0 11.821 9.583 21.404 21.405 21.404z"
      ></path>
      <path fill="#fff" d="M64.85 74.876H39.164v8.562H64.85v-8.562z"></path>
      <path
        fill="#B8D6EC"
        d="M47.726 79.157H64.85v4.281H45.586v-2.14c0-1.178.963-2.14 2.14-2.14z"
      ></path>
      <path
        fill="#284B9C"
        d="M64.852 84.241H39.166a.809.809 0 01-.803-.803v-8.562c0-.438.364-.802.803-.802h25.686c.438 0 .802.364.802.802v8.562a.808.808 0 01-.802.803zm-24.883-1.605h24.08v-6.957h-24.08v6.957z"
      ></path>
      <path
        fill="#284B9C"
        d="M75.553 84.241h-47.09a.808.808 0 01-.803-.803c0-.438.364-.802.803-.802h47.09c.439 0 .803.364.803.802a.809.809 0 01-.803.803z"
      ></path>
      <path
        fill="#fff"
        d="M69.687 42.117l4.698-4.699-4.698-4.698a6.39 6.39 0 01-1.883-4.538v-6.646h-6.647a6.389 6.389 0 01-4.537-1.884l-4.699-4.698-4.698 4.698a6.452 6.452 0 01-4.538 1.884H36.04v6.646a6.39 6.39 0 01-1.884 4.538l-4.698 4.698 4.698 4.699a6.39 6.39 0 011.884 4.537v6.647h6.646c1.702 0 3.34.674 4.538 1.883l4.698 4.699 4.699-4.699a6.452 6.452 0 014.537-1.883h6.647v-6.647a6.39 6.39 0 011.883-4.537z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M61.156 53.311h6.647v-6.646c0-1.701.674-3.339 1.883-4.538l4.698-4.698-4.698-4.698a6.389 6.389 0 01-1.883-4.538v-6.646h-6.647a6.42 6.42 0 01-4.099-1.477c-7.448 3.35-12.639 10.82-12.639 19.51 0 7.556 3.928 14.18 9.835 17.99l2.355-2.354a6.453 6.453 0 014.538-1.883l.01-.022z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.911 60.696a.794.794 0 01-.567-.236l-4.698-4.698a5.587 5.587 0 00-3.97-1.648h-6.647a.808.808 0 01-.803-.803v-6.646a5.587 5.587 0 00-1.648-3.97l-4.698-4.699a.802.802 0 010-1.134l4.698-4.699a5.587 5.587 0 001.648-3.97v-6.646c0-.44.364-.803.803-.803h6.646a5.587 5.587 0 003.97-1.648l4.7-4.698a.802.802 0 011.134 0l4.698 4.698a5.587 5.587 0 003.97 1.648h6.647c.439 0 .802.364.802.803v6.646c0 1.498.589 2.91 1.648 3.97l4.699 4.699a.802.802 0 010 1.134l-4.698 4.699a5.587 5.587 0 00-1.649 3.97v6.646a.808.808 0 01-.802.803h-6.646a5.587 5.587 0 00-3.971 1.648l-4.698 4.698a.793.793 0 01-.568.236zm-15.09-8.187h5.844c1.926 0 3.745.749 5.105 2.119l4.13 4.13 4.132-4.13a7.147 7.147 0 015.105-2.12h5.843v-5.843c0-1.926.75-3.746 2.12-5.105l4.13-4.131-4.13-4.131a7.21 7.21 0 01-2.12-5.105v-5.844h-5.843a7.147 7.147 0 01-5.105-2.119L51.9 16.1l-4.131 4.13a7.165 7.165 0 01-5.105 2.12H36.82v5.843c0 1.926-.749 3.746-2.119 5.105l-4.131 4.131 4.131 4.131a7.19 7.19 0 012.12 5.105v5.844z"
      ></path>
      <path
        fill="#fff"
        d="M51.91 48.656c6.206 0 11.237-5.032 11.237-11.238 0-6.206-5.031-11.237-11.238-11.237-6.206 0-11.237 5.03-11.237 11.237 0 6.206 5.031 11.238 11.237 11.238z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M63.147 37.418c0-1.723-.396-3.34-1.091-4.795a11.096 11.096 0 00-4.795-1.091c-6.208 0-11.238 5.03-11.238 11.237 0 1.723.396 3.34 1.092 4.795a11.252 11.252 0 004.795 1.092c6.207 0 11.237-5.03 11.237-11.238z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.911 49.458c-6.635 0-12.04-5.404-12.04-12.04 0-6.635 5.405-12.04 12.04-12.04 6.636 0 12.04 5.405 12.04 12.04 0 6.636-5.404 12.04-12.04 12.04zm0-22.475c-5.758 0-10.435 4.677-10.435 10.435 0 5.758 4.677 10.435 10.435 10.435 5.758 0 10.435-4.677 10.435-10.435 0-5.758-4.677-10.435-10.435-10.435z"
      ></path>
      <path
        fill="#fff"
        d="M83.042 42.77H20.968a3.21 3.21 0 00-3.21 3.21v25.686a3.21 3.21 0 003.21 3.21h62.074a3.21 3.21 0 003.21-3.21V45.98a3.21 3.21 0 00-3.21-3.21z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M30.602 55.612h55.652v16.054a3.206 3.206 0 01-3.211 3.21H30.602V55.612z"
      ></path>
      <path
        fill="#284B9C"
        d="M83.044 75.68H20.97a4.015 4.015 0 01-4.013-4.014V45.98a4.015 4.015 0 014.013-4.013h62.074a4.015 4.015 0 014.013 4.013v25.686a4.015 4.015 0 01-4.013 4.013zM20.97 43.571a2.412 2.412 0 00-2.408 2.408v25.686a2.412 2.412 0 002.408 2.408h62.074a2.412 2.412 0 002.408-2.408V45.98a2.412 2.412 0 00-2.408-2.408H20.97z"
      ></path>
      <path
        fill="#fff"
        d="M79.832 48.12H24.18c-.591 0-1.07.48-1.07 1.07v19.265c0 .591.479 1.07 1.07 1.07h55.652c.59 0 1.07-.479 1.07-1.07V49.191c0-.591-.48-1.07-1.07-1.07z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M32.742 52.401H28.46c-.589 0-1.07.482-1.07 1.07v5.352h5.35V52.4zM32.742 63.104H28.46c-.589 0-1.07.481-1.07 1.07v5.351h5.35v-6.421zM80.902 68.455v-5.351h-4.281c-.589 0-1.07.481-1.07 1.07v5.351h4.28c.59 0 1.071-.481 1.071-1.07zM80.902 52.401h-4.281c-.589 0-1.07.482-1.07 1.07v5.352h5.351V52.4zM42.375 63.104h-4.281c-.589 0-1.07.481-1.07 1.07v5.351h5.35v-6.421zM71.273 52.401h-4.28c-.59 0-1.071.482-1.071 1.07v5.352h5.351V52.4zM42.375 52.401h-4.281c-.589 0-1.07.482-1.07 1.07v5.352h5.35V52.4zM71.273 63.104h-4.28c-.59 0-1.071.481-1.071 1.07v5.351h5.351v-6.421zM61.639 52.401h-4.377c-.589 0-1.07.482-1.07 1.07v5.352h5.447V52.4zM61.639 63.104h-4.377c-.589 0-1.07.481-1.07 1.07v5.351h5.447v-6.421zM51.911 63.104h-4.184c-.59 0-1.07.481-1.07 1.07v5.351h5.254v-6.421zM51.911 52.401h-4.184c-.59 0-1.07.482-1.07 1.07v5.352h5.254V52.4z"
      ></path>
      <path
        fill="#284B9C"
        d="M32.743 59.626h-9.632a.809.809 0 01-.802-.803V49.19c0-1.028.845-1.873 1.872-1.873h8.562c.44 0 .803.364.803.803v10.702a.809.809 0 01-.803.803zm-8.83-1.606h8.028v-9.097h-7.76a.265.265 0 00-.267.268v8.83z"
      ></path>
      <path
        fill="#284B9C"
        d="M32.743 70.328h-8.562a1.882 1.882 0 01-1.872-1.873v-9.632c0-.44.363-.803.802-.803h9.632c.44 0 .803.364.803.803v10.702a.809.809 0 01-.803.803zm-8.83-10.703v8.83c0 .15.119.267.268.267h7.76v-9.097h-8.027zM79.833 70.328h-8.562a.808.808 0 01-.802-.803V58.823c0-.44.364-.803.802-.803h9.632c.44 0 .803.364.803.803v9.632a1.882 1.882 0 01-1.873 1.873zm-7.759-1.606h7.76c.15 0 .267-.117.267-.267v-8.83h-8.027v9.097z"
      ></path>
      <path
        fill="#284B9C"
        d="M80.903 59.626h-9.632a.808.808 0 01-.802-.803V48.12c0-.438.364-.802.802-.802h8.562c1.028 0 1.873.845 1.873 1.873v9.632a.809.809 0 01-.803.803zm-8.829-1.606h8.027v-8.83a.265.265 0 00-.268-.267h-7.759v9.097zM42.376 70.328h-9.632a.809.809 0 01-.803-.803V58.823c0-.44.364-.803.803-.803h9.632c.439 0 .803.364.803.803v10.702a.809.809 0 01-.803.803zm-8.83-1.606h8.028v-9.097h-8.027v9.097z"
      ></path>
      <path
        fill="#284B9C"
        d="M71.27 59.626H61.64a.809.809 0 01-.803-.803V48.12c0-.438.364-.802.803-.802h9.632c.439 0 .802.364.802.803v10.702a.809.809 0 01-.802.803zm-8.829-1.606h8.027v-9.097h-8.027v9.097zM42.376 59.626h-9.632a.809.809 0 01-.803-.803V48.12c0-.438.364-.802.803-.802h9.632c.439 0 .803.364.803.803v10.702a.809.809 0 01-.803.803zm-8.83-1.606h8.028v-9.097h-8.027v9.097z"
      ></path>
      <path
        fill="#284B9C"
        d="M71.27 70.328H61.64a.809.809 0 01-.803-.803V58.823c0-.44.364-.803.803-.803h9.632c.439 0 .802.364.802.803v10.702a.809.809 0 01-.802.803zm-8.829-1.606h8.027v-9.097h-8.027v9.097z"
      ></path>
      <path
        fill="#284B9C"
        d="M61.64 59.626h-9.728a.809.809 0 01-.803-.803V48.12c0-.438.364-.802.803-.802h9.729c.438 0 .802.364.802.803v10.702a.809.809 0 01-.802.803zm-8.925-1.606h8.123v-9.097h-8.123v9.097z"
      ></path>
      <path
        fill="#284B9C"
        d="M61.64 70.328h-9.728a.809.809 0 01-.803-.803V58.823c0-.44.364-.803.803-.803h9.729c.438 0 .802.364.802.803v10.702a.809.809 0 01-.802.803zm-8.925-1.606h8.123v-9.097h-8.123v9.097z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.913 70.328h-9.536a.809.809 0 01-.803-.803V58.823c0-.44.364-.803.803-.803h9.536c.439 0 .802.364.802.803v10.702a.809.809 0 01-.802.803zm-8.733-1.606h7.93v-9.097h-7.93v9.097z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.913 59.626h-9.536a.809.809 0 01-.803-.803V48.12c0-.438.364-.802.803-.802h9.536c.439 0 .802.364.802.803v10.702a.809.809 0 01-.802.803zM43.18 58.02h7.93v-9.097h-7.93v9.097z"
      ></path>
      <path
        fill="#fff"
        d="M20.969 35.278a2.14 2.14 0 100-4.281 2.14 2.14 0 000 4.28z"
      ></path>
      <path
        fill="#284B9C"
        d="M20.97 36.08a2.942 2.942 0 01-2.943-2.943 2.941 2.941 0 012.944-2.943 2.941 2.941 0 012.943 2.943 2.941 2.941 0 01-2.944 2.943zm0-4.28a1.338 1.338 0 10.002 2.676 1.338 1.338 0 00-.002-2.677z"
      ></path>
      <path
        fill="#fff"
        d="M28.46 22.435a2.14 2.14 0 100-4.281 2.14 2.14 0 000 4.28z"
      ></path>
      <path
        fill="#284B9C"
        d="M28.463 23.238a2.941 2.941 0 01-2.944-2.944 2.942 2.942 0 012.944-2.943 2.941 2.941 0 012.943 2.944 2.941 2.941 0 01-2.943 2.943zm0-4.281a1.338 1.338 0 100 2.676 1.338 1.338 0 000-2.676z"
      ></path>
      <path
        fill="#fff"
        d="M73.422 19.224a2.14 2.14 0 100-4.28 2.14 2.14 0 000 4.28z"
      ></path>
      <path
        fill="#284B9C"
        d="M73.424 20.027a2.941 2.941 0 01-2.944-2.943 2.941 2.941 0 012.944-2.943 2.942 2.942 0 012.943 2.943 2.941 2.941 0 01-2.943 2.943zm0-4.281a1.338 1.338 0 100 2.677 1.338 1.338 0 000-2.677z"
      ></path>
      <path
        fill="#fff"
        d="M81.984 29.926a2.14 2.14 0 100-4.28 2.14 2.14 0 000 4.28z"
      ></path>
      <path
        fill="#284B9C"
        d="M81.986 30.73a2.941 2.941 0 01-2.943-2.944 2.941 2.941 0 012.943-2.943 2.941 2.941 0 012.943 2.943 2.942 2.942 0 01-2.943 2.943zm0-4.282a1.338 1.338 0 10.001 2.677 1.338 1.338 0 000-2.677z"
      ></path>
    </svg>
  );
}