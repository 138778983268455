import React from "react";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import styles from "@components/Common/Header/Header.module.scss";

import { MenuItemModel } from "./Menu.model";

export const MenuItemSecondary: React.FC<{item: MenuItemModel}> = ({ item }) => {

  const { t } = useTranslation();
  return (
    <ListItem disablePadding style={{ position: 'static' }} className={styles.dropdownMenuItem}>
      <Link href={item?.url} passHref legacyBehavior>
        <ListItemButton component="a" style={{ paddingLeft: 0 }}>
          <ListItemText primary={item.title} />
          {item?.items?.length > 0 && (
            <ListItemIcon>
              <ArrowForwardIos fontSize="small" color="primary" />
            </ListItemIcon>
          )}
        </ListItemButton>
      </Link>
      {item?.items?.length > 0 && (
        <div className={styles.dropdownSubMenu}>
          <Typography variant="bodyS" component="div" className={styles.dropdownTitle}>{t('header.menu.whatWeOffer')}</Typography>
          <List disablePadding>
            {item.items.map((submenu: MenuItemModel) => <MenuItemSecondary key={submenu.id} item={submenu} /> )}
          </List>
        </div>
      )}
    </ListItem>
  );
}
