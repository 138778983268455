export default function RuoloCliente() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M36.586 90.808c8.468 0 15.332-7.086 15.332-15.826s-6.864-15.826-15.332-15.826-15.332 7.085-15.332 15.826c0 8.74 6.864 15.826 15.332 15.826zM78.856 56.397c7.259 0 13.142-6.073 13.142-13.565 0-7.492-5.883-13.565-13.142-13.565-7.257 0-13.141 6.073-13.141 13.565 0 7.492 5.884 13.565 13.141 13.565z"
      ></path>
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M33.903 61.044c12.096 0 21.903-10.123 21.903-22.61 0-12.486-9.807-22.608-21.903-22.608C21.806 15.826 12 25.948 12 38.435c0 12.486 9.806 22.609 21.903 22.609z"
      ></path>
      <path
        fill="#fff"
        d="M51.157 84.717c17.58 0 31.833-14.711 31.833-32.858 0-18.148-14.252-32.86-31.833-32.86-17.58 0-31.833 14.712-31.833 32.86 0 18.147 14.252 32.858 31.833 32.858z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M82.992 51.858c0-7.959-2.747-15.248-7.298-20.944a30.961 30.961 0 00-12.592-2.677c-17.578 0-31.832 14.713-31.832 32.858 0 7.96 2.747 15.25 7.298 20.945a30.961 30.961 0 0012.591 2.677c17.579 0 31.833-14.713 31.833-32.859z"
      ></path>
      <path
        fill="#fff"
        d="M70.588 60.718l-7.451-1.326a5.664 5.664 0 01-1.415-.45H40.594c-.448.206-.92.352-1.414.45l-7.452 1.326c-4.197.743-7.274 4.43-7.416 8.8C29.96 78.656 39.876 84.73 51.159 84.73s21.187-6.073 26.846-15.213c-.142-4.357-3.219-8.044-7.416-8.799z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M78.004 69.517v-.097a54.258 54.258 0 00-9.904-.925c-12.532 0-24.11 4.26-33.424 11.451 4.81 3.019 10.446 4.771 16.494 4.771 11.283 0 21.186-6.073 26.846-15.212l-.012.012z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.16 85.63c-11.224 0-21.54-5.841-27.589-15.626a1.024 1.024 0 01-.141-.523c.153-4.783 3.584-8.848 8.146-9.663l7.452-1.327a5.41 5.41 0 001.214-.377.936.936 0 01.366-.073h21.116c.13 0 .247.024.365.073.389.183.79.304 1.214.377l7.452 1.327c4.562.815 7.993 4.88 8.146 9.663 0 .182-.047.365-.141.523C72.712 79.79 62.395 85.63 51.17 85.63h-.011zM25.21 69.262c5.742 9.115 15.421 14.543 25.95 14.543 10.528 0 20.196-5.428 25.95-14.543-.248-3.81-3.007-6.998-6.674-7.655l-7.451-1.327a6.832 6.832 0 01-1.45-.426h-20.75c-.472.195-.955.341-1.45.426l-7.452 1.327c-3.655.657-6.425 3.846-6.673 7.655z"
      ></path>
      <path
        fill="#fff"
        d="M58.35 54.804l-.755-3.08H44.72l-.754 3.08c-.46 1.862-1.71 3.37-3.372 4.137l10.563 4.345 10.553-4.345c-1.651-.754-2.913-2.275-3.36-4.137z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M61.722 58.941c-1.65-.754-2.912-2.275-3.36-4.137l-.26-1.071h-8.76l-.813 3.31a6.584 6.584 0 01-2.818 3.992l5.47 2.251 10.553-4.345h-.012z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.159 64.199c-.106 0-.224-.024-.33-.06l-10.564-4.345a.896.896 0 01-.554-.828c0-.365.2-.706.519-.852 1.438-.657 2.487-1.947 2.865-3.517l.754-3.08a.89.89 0 01.86-.693h12.875c.401 0 .755.28.861.694l.755 3.079c.389 1.582 1.426 2.86 2.865 3.517.33.146.53.487.518.852a.896.896 0 01-.554.828l-10.552 4.344a.76.76 0 01-.33.061h.012zm-8.618-5.44l8.618 3.541 8.607-3.541a7.124 7.124 0 01-2.276-3.724l-.59-2.385H45.395l-.578 2.385c-.366 1.485-1.167 2.787-2.276 3.724z"
      ></path>
      <path
        fill="#fff"
        d="M63.916 38.764c0 8.86-5.706 16.027-12.757 16.027-7.05 0-12.757-7.18-12.757-16.027 0-8.848 5.707-13.533 12.757-13.533s12.757 4.673 12.757 13.533z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M63.914 38.764c0-7.35-3.938-11.805-9.302-13.12-5.094 1.46-8.784 5.866-8.784 12.974 0 7.107 3.903 13.472 9.232 15.395 5.14-2.07 8.854-8.118 8.854-15.261v.012z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.16 55.704c-7.521 0-13.64-7.606-13.64-16.94 0-8.641 5.482-14.446 13.64-14.446 8.16 0 13.642 5.805 13.642 14.446 0 9.346-6.12 16.94-13.642 16.94zm0-29.56c-7.215 0-11.872 4.953-11.872 12.62 0 8.336 5.33 15.115 11.873 15.115 6.543 0 11.872-6.779 11.872-15.115 0-7.668-4.657-12.62-11.873-12.62zM67.525 80.969c-.484 0-.884-.414-.884-.913v-9.541c0-.499.4-.913.884-.913.483 0 .884.414.884.913v9.541c0 .5-.4.913-.884.913zM34.794 80.969c-.483 0-.884-.414-.884-.913v-9.541c0-.499.401-.913.884-.913.484 0 .885.414.885.913v9.541c0 .5-.401.913-.885.913z"
      ></path>
      <path
        fill="#fff"
        d="M51.157 63.286l-7.91 3.785-2.653-8.13 10.563 4.345zM51.16 63.286l7.935 3.797 2.629-8.142-10.564 4.345z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M44.203 60.572l1.71 5.22 5.093-2.42-6.803-2.8zM54.767 64.917l-.106.049 4.433 2.117 1.544-4.795-6.118 2.52.247.109z"
      ></path>
      <path
        fill="#284B9C"
        d="M43.246 67.984a.92.92 0 01-.354-.073.848.848 0 01-.483-.548l-2.653-8.13a.914.914 0 01.236-.949.858.858 0 01.931-.182l10.564 4.344c.33.134.542.463.554.828 0 .365-.188.706-.519.852l-7.91 3.785a.802.802 0 01-.366.085v-.012zm-1.203-7.46l1.722 5.293 5.152-2.458-6.886-2.836h.012z"
      ></path>
      <path
        fill="#284B9C"
        d="M59.092 67.996a.769.769 0 01-.365-.085l-7.946-3.797c-.319-.146-.52-.487-.52-.852s.225-.694.555-.828L61.38 58.09a.854.854 0 01.931.182c.248.244.342.62.236.95l-2.617 8.141a.915.915 0 01-.484.548.878.878 0 01-.354.073v.012zm-5.706-4.637l5.176 2.47 1.71-5.305-6.886 2.835z"
      ></path>
      <path
        fill="#fff"
        d="M46.228 33.482s7.298 6.51 16.872.024c-1.804-5.428-6.461-8.275-11.943-8.275-5.943 0-10.93 3.346-12.344 9.711.4.317 3.607 2.629 7.415-1.46z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M46.232 33.482s7.298 6.51 16.871.024c-.153-.45-.318-.864-.507-1.278-2.334-2.58-5.694-3.918-9.443-3.918-5.235 0-9.715 2.592-11.684 7.557 1.297.037 2.959-.45 4.763-2.385z"
      ></path>
      <path
        fill="#284B9C"
        d="M54.541 37.279c-3.997 0-6.968-1.716-8.23-2.605-4.114 3.87-7.627 1.303-8.028.986a.954.954 0 01-.33-.925c1.426-6.425 6.484-10.417 13.204-10.417 6.155 0 10.942 3.322 12.78 8.896a.928.928 0 01-.353 1.059c-3.325 2.251-6.414 3.006-9.043 3.006zm-8.312-4.71c.2 0 .413.073.578.22.271.242 6.638 5.707 15.22.34-1.744-4.393-5.753-6.986-10.87-6.986-5.718 0-9.903 3.116-11.33 8.373.979.524 3.148 1.132 5.766-1.68a.88.88 0 01.636-.28v.013z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.158 85.63c-18.038 0-32.717-15.152-32.717-33.771 0-18.62 14.679-33.772 32.718-33.772 18.038 0 32.717 15.152 32.717 33.772 0 18.62-14.679 33.771-32.718 33.771zm0-65.718c-17.06 0-30.948 14.337-30.948 31.947s13.889 31.946 30.948 31.946c17.06 0 30.95-14.337 30.95-31.947s-13.89-31.946-30.95-31.946z"
      ></path>
      <path
        fill="#284B9C"
        d="M33.612 39.725h-4.716c-.483 0-.884-.414-.884-.913 0-.499.4-.912.884-.912h4.716c.483 0 .884.413.884.912 0 .5-.4.913-.884.913z"
      ></path>
      <path
        fill="#284B9C"
        d="M31.255 42.159c-.483 0-.884-.414-.884-.913v-4.868c0-.499.401-.912.884-.912.484 0 .885.413.885.912v4.868c0 .5-.401.913-.885.913zM74.135 51.03H69.42c-.483 0-.884-.414-.884-.913 0-.5.401-.913.884-.913h4.716c.484 0 .885.414.885.913 0 .499-.401.912-.885.912z"
      ></path>
      <path
        fill="#284B9C"
        d="M71.779 53.464c-.484 0-.885-.414-.885-.913v-4.868c0-.5.401-.913.885-.913.483 0 .884.414.884.913v4.868c0 .499-.4.913-.884.913zM28.897 51.895h-4.716c-.483 0-.884-.414-.884-.913 0-.499.4-.912.884-.912h4.716c.484 0 .884.413.884.912 0 .5-.4.913-.884.913z"
      ></path>
      <path
        fill="#284B9C"
        d="M26.54 54.329c-.483 0-.884-.414-.884-.913v-4.868c0-.499.401-.913.885-.913.483 0 .884.414.884.913v4.868c0 .5-.401.913-.884.913z"
      ></path>
    </svg>
  );
}