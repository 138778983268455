export default function EconomiaCircolare() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M81.44 50.58c7.97 0 14.44-6.17 14.44-13.79 0-7.62-6.46-13.79-14.44-13.79C73.46 23 67 29.17 67 36.79c0 7.62 6.46 13.79 14.44 13.79zM50.26 89.97c6.83 0 12.37-5.29 12.37-11.82s-5.54-11.82-12.37-11.82c-6.83 0-12.37 5.29-12.37 11.82s5.54 11.82 12.37 11.82zM35.62 53.4c11.39 0 20.62-8.82 20.62-19.7 0-10.88-9.23-19.7-20.62-19.7S15 22.82 15 33.7c0 10.88 9.23 19.7 20.62 19.7z"
      ></path>
      <path
        fill="#284B9C"
        d="M83.019 75.91h-4.12c-.42 0-.77-.33-.77-.74s.35-.74.77-.74h4.12c.42 0 .77.33.77.74s-.35.74-.77.74z"
      ></path>
      <path
        fill="#284B9C"
        d="M80.961 77.88c-.42 0-.77-.33-.77-.74V73.2c0-.4.35-.74.77-.74.42 0 .77.33.77.74v3.94c0 .4-.35.74-.77.74zM84.1 27.09h-4.12c-.42 0-.77-.33-.77-.74s.35-.74.77-.74h4.12c.42 0 .77.33.77.74s-.35.74-.77.74z"
      ></path>
      <path
        fill="#284B9C"
        d="M82.04 29.06c-.42 0-.77-.33-.77-.74v-3.94c0-.4.35-.74.77-.74.42 0 .77.33.77.74v3.94c0 .4-.35.74-.77.74zM25.9 23.28h-4.13c-.42 0-.77-.34-.77-.74 0-.4.35-.74.77-.74h4.12c.42 0 .77.34.77.74 0 .4-.35.74-.77.74h.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M23.829 25.25c-.42 0-.77-.34-.77-.74v-3.94c0-.4.35-.74.77-.74.42 0 .77.34.77.74v3.94c0 .4-.35.74-.77.74z"
      ></path>
      <path
        fill="#fff"
        d="M51.5 82C70.002 82 85 67.228 85 49c0-18.227-14.988-33-33.5-33S18 30.773 18 49c0 18.228 14.998 33 33.5 33z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M61.668 17.83l-.78 1.17c11.19 5.2 18.96 16.52 18.96 29.67 0 14.5-9.44 26.8-22.5 31.1-1.75.57-3.57.99-5.43 1.27l-.43.64c3.57 0 7.01-.58 10.23-1.64 13.06-4.3 22.5-16.59 22.5-31.1 0-14.51-9.45-26.83-22.55-31.11z"
      ></path>
      <path
        fill="#fff"
        d="M60.901 18.99l-4 6c10.97 2.45 19.16 12.24 19.16 23.95 0 9.9-5.87 18.43-14.32 22.32v-4.93l-9.8 14.7c1.86-.28 3.68-.7 5.43-1.27 13.06-4.3 22.5-16.59 22.5-31.1 0-13.15-7.77-24.47-18.96-29.67h-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.5 82.44a.773.773 0 01-.64-1.2L61.09 65.9c.18-.29.53-.41.86-.31.33.1.54.4.54.74v3.69c7.8-4.08 12.79-12.22 12.79-21.08 0-11.03-7.8-20.79-18.56-23.2a.742.742 0 01-.54-.46c-.09-.24-.06-.5.07-.72l4.78-7.16c.19-.29.55-.41.88-.31A33.42 33.42 0 0185 48.93c0 14.53-9.26 27.29-23.03 31.82-3.39 1.11-6.9 1.68-10.47 1.68v.01zm9.46-13.57l-8.01 12.01c2.91-.13 5.77-.65 8.53-1.57a31.893 31.893 0 0021.97-30.36c0-13.84-8.59-25.74-21.47-30.2l-3.85 5.77c10.88 2.96 18.68 13.06 18.68 24.43 0 9.86-5.8 18.89-14.77 23.02a.78.78 0 01-.74-.05.793.793 0 01-.35-.64v-2.39l.01-.02z"
      ></path>
      <path
        fill="#fff"
        d="M22.86 49.26c0-14.5 9.44-26.79 22.5-31.09 1.83-.6 3.74-1.03 5.7-1.3l.44-.65c-3.57 0-7.01.58-10.23 1.64-13.06 4.28-22.5 16.58-22.5 31.08s9.46 26.83 22.55 31.12l.69-1.03C30.72 73.87 22.85 62.5 22.85 49.27h.01v-.01z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M26.95 48.94c0-9.9 5.87-18.43 14.32-22.32v4.93l9.79-14.69c-1.96.28-3.87.71-5.7 1.3-13.06 4.31-22.5 16.6-22.5 31.1 0 13.23 7.87 24.6 19.16 29.76l4.09-6.13c-10.97-2.46-19.15-12.24-19.15-23.95h-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M41.32 80.83c-.08 0-.16-.01-.24-.04A33.437 33.437 0 0118 48.94c0-14.54 9.26-27.29 23.03-31.82 3.39-1.11 6.9-1.68 10.47-1.68a.773.773 0 01.64 1.2L41.91 31.98c-.18.29-.53.41-.86.31-.33-.1-.54-.4-.54-.74v-3.69c-7.8 4.08-12.79 12.22-12.79 21.08 0 11.03 7.8 20.79 18.56 23.2.25.05.45.22.54.46.09.24.06.5-.07.72l-4.78 7.16c-.14.21-.39.34-.63.34h-.01l-.01.01zm8.73-63.82c-2.91.13-5.77.65-8.53 1.57a31.885 31.885 0 00-21.99 30.36c0 13.84 8.6 25.74 21.48 30.2l3.85-5.77c-10.88-2.96-18.68-13.06-18.68-24.43 0-9.86 5.8-18.89 14.77-23.02a.78.78 0 01.74.05c.21.14.35.39.35.64V29l8.01-11.99z"
      ></path>
      <path
        fill="#fff"
        d="M31.87 28.88h-9.28c0 11.53 9.35 20.88 20.88 20.88h9.28c0-11.53-9.35-20.88-20.88-20.88zM73.63 41.64h9.28c0 11.53-9.35 20.88-20.88 20.88h-9.28c0-11.53 9.35-20.88 20.88-20.88z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M22.59 28.88c0 11.53 8.1 20.88 18.1 20.88h12.06c-15.96-4.3-30.16-20.88-30.16-20.88z"
      ></path>
      <path
        fill="#284B9C"
        d="M52.749 50.63h-9.28c-11.99 0-21.75-9.76-21.75-21.75 0-.48.39-.87.87-.87h9.28c11.99 0 21.75 9.76 21.75 21.75 0 .48-.39.87-.87.87zm-29.27-20.88c.45 10.64 9.25 19.14 19.99 19.14h8.39c-.45-10.63-9.25-19.14-19.99-19.14h-8.39z"
      ></path>
      <path
        fill="#284B9C"
        d="M52.747 50.63c-.07 0-.15 0-.23-.03-9.96-2.69-19.2-9.98-25.2-15.61a.86.86 0 01-.03-1.23.86.86 0 011.23-.03c5.85 5.51 14.84 12.61 24.45 15.2a.87.87 0 01-.23 1.71l.01-.01z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M82.91 41.64c0 11.53-8.1 20.88-18.1 20.88H52.75c15.96-4.3 30.16-20.88 30.16-20.88z"
      ></path>
      <path
        fill="#284B9C"
        d="M62.029 63.39h-9.28a.87.87 0 01-.87-.87c0-11.99 9.76-21.75 21.75-21.75h9.28c.48 0 .87.39.87.87 0 11.99-9.76 21.75-21.75 21.75zm-8.39-1.74h8.39c10.74 0 19.53-8.5 19.99-19.14h-8.39c-10.74 0-19.53 8.51-19.99 19.14z"
      ></path>
      <path
        fill="#284B9C"
        d="M52.747 63.39c-.38 0-.73-.26-.84-.64-.13-.46.15-.94.61-1.07 9.62-2.59 18.61-9.7 24.45-15.2.35-.32.9-.31 1.23.03.32.35.31.9-.03 1.23-6 5.64-15.23 12.93-25.2 15.61-.08.02-.15.03-.23.03l.01.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M52.749 77.75a.87.87 0 01-.87-.87V49.76a.87.87 0 011.74 0v27.12c0 .48-.39.87-.87.87z"
      ></path>
    </svg>
  );
}