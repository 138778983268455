import { useFormApi } from "@data-driven-forms/react-form-renderer";

import Button from "@mui/material/Button";
import Row from "@components/Modules/Utils/Row/Row";
import Grid from "@mui/material/Grid";
import { useTranslation } from "next-i18next";
import Box from "@mui/material/Box";
import Link from "next/link";
import { theme } from "@styles/mui/theme";

export const NewsletterFormTemplate = ({formFields, submitLabel = ''} ) => {

  const { handleSubmit, getState } = useFormApi();
  const { submitting, valid } = getState();
  const isValid = submitting || !valid;
  const {t} = useTranslation();

  return (
    <form onSubmit={handleSubmit}>
      <Row spacing={12}>

        <Grid item xs={12} lg={7}>
          <Row spacing={12}>
            { formFields }
          </Row>
        </Grid>

        <Grid item lg={1} />

        <Grid item xs={12} lg={4} display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end">
          <Box sx={{
            mb: 25,
            color: theme.palette.common.black,
            fontWeight: 300,
            width: '100%',
            textAlign: 'center'
          }}>
            {t('newsletter.fields.privacy.1')}
            <Link href={'https://email.edisonnext.it/hubfs/Newsletter/A4%20informativa_2024_04_Newsletter.pdf'} target='_blank'>
              {t('newsletter.fields.privacy.2')}
            </Link>
            {' '}
            {t('newsletter.fields.privacy.3')}
          </Box>

          <Button type="submit" variant="contained" color="primary" fullWidth disabled={isValid}>
            {t('newsletter.fields.submit')}
          </Button>
        </Grid>
      </Row>
      

    </form>
  )
}

export default NewsletterFormTemplate;