export default function MobilitaSostenibile() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M78.283 50.75c7.972 0 14.435-6.175 14.435-13.792 0-7.616-6.463-13.791-14.435-13.791s-14.435 6.175-14.435 13.791c0 7.617 6.463 13.792 14.435 13.792zM47.26 89.97c6.833 0 12.373-5.293 12.373-11.821 0-6.529-5.54-11.821-12.373-11.821-6.834 0-12.373 5.292-12.373 11.82 0 6.53 5.54 11.822 12.373 11.822zM32.622 53.404c11.389 0 20.621-8.821 20.621-19.702C53.243 22.82 44.011 14 32.622 14 21.232 14 12 22.82 12 33.702c0 10.881 9.233 19.702 20.622 19.702z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M82.627 67.899l-13.354-3.126h-44.66l-6.62 2.234c-1.37.463-2.282 1.75-2.282 3.216v7.695c0 1.557 1.046 2.911 2.537 3.284l6.5 1.624h54.54l5.242-1.545c1.424-.418 2.404-1.75 2.404-3.25v-4.637c0-2.63-1.792-4.909-4.307-5.495z"
      ></path>
      <path
        fill="#fff"
        d="M78.788 66.996l-9.504-2.223h-44.67l-6.622 2.234c-1.369.463-2.281 1.75-2.281 3.216v5.834l9.036 2.256h48.977l5.242-1.546c1.424-.417 2.403-1.748 2.403-3.25v-3.215c0-1.58-1.068-2.945-2.581-3.295v-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M79.287 83.673H24.746c-.067 0-.133 0-.2-.023l-6.5-1.625c-1.869-.462-3.171-2.155-3.171-4.107v-7.695c0-1.828 1.146-3.441 2.849-4.017l6.621-2.234a.737.737 0 01.268-.045H69.27c.067 0 .123 0 .19.023l13.354 3.125c2.916.688 4.963 3.284 4.963 6.319v4.637c0 1.873-1.235 3.543-3.005 4.062l-5.241 1.546a.839.839 0 01-.234.034h-.01zm-54.44-1.693h54.318l5.119-1.512a2.558 2.558 0 001.803-2.437v-4.637c0-2.246-1.503-4.164-3.662-4.672L69.171 65.62H24.746l-6.488 2.189a2.542 2.542 0 00-1.714 2.403v7.695c0 1.173.78 2.189 1.903 2.46l6.4 1.602v.011z"
      ></path>
      <path
        fill="#fff"
        d="M31.423 89.596c3.688 0 6.677-3.03 6.677-6.77 0-3.738-2.99-6.77-6.677-6.77-3.687 0-6.677 3.032-6.677 6.77 0 3.74 2.99 6.77 6.677 6.77z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M33.294 76.327a6.781 6.781 0 011.48 4.243c0 3.735-2.994 6.77-6.677 6.77a6.73 6.73 0 01-1.859-.271 6.605 6.605 0 005.197 2.527c3.684 0 6.677-3.035 6.677-6.77a6.767 6.767 0 00-4.818-6.499z"
      ></path>
      <path
        fill="#284B9C"
        d="M31.422 90.443c-4.14 0-7.512-3.42-7.512-7.616 0-4.198 3.372-7.617 7.512-7.617s7.512 3.42 7.512 7.617-3.372 7.616-7.512 7.616zm0-13.54c-3.227 0-5.843 2.663-5.843 5.924 0 3.26 2.616 5.923 5.843 5.923s5.842-2.663 5.842-5.923c0-3.261-2.626-5.924-5.842-5.924z"
      ></path>
      <path
        fill="#284B9C"
        d="M31.421 83.955a1.12 1.12 0 001.113-1.129 1.12 1.12 0 00-1.113-1.128 1.12 1.12 0 00-1.112 1.129 1.12 1.12 0 001.112 1.128z"
      ></path>
      <path
        fill="#fff"
        d="M72.61 89.596c3.688 0 6.678-3.03 6.678-6.77 0-3.738-2.99-6.77-6.677-6.77-3.688 0-6.677 3.032-6.677 6.77 0 3.74 2.989 6.77 6.677 6.77z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M74.47 76.327a6.781 6.781 0 011.48 4.243c0 3.735-2.994 6.77-6.677 6.77a6.73 6.73 0 01-1.859-.271 6.605 6.605 0 005.197 2.527c3.684 0 6.677-3.035 6.677-6.77a6.767 6.767 0 00-4.818-6.499z"
      ></path>
      <path
        fill="#284B9C"
        d="M72.61 90.443c-4.14 0-7.512-3.42-7.512-7.616 0-4.198 3.372-7.617 7.511-7.617 4.14 0 7.512 3.42 7.512 7.617s-3.372 7.616-7.512 7.616zm0-13.54c-3.217 0-5.843 2.663-5.843 5.924 0 3.26 2.626 5.923 5.842 5.923 3.217 0 5.843-2.663 5.843-5.923 0-3.261-2.626-5.924-5.843-5.924z"
      ></path>
      <path
        fill="#284B9C"
        d="M72.609 83.955a1.12 1.12 0 001.113-1.129 1.12 1.12 0 00-1.113-1.128 1.12 1.12 0 00-1.113 1.129 1.12 1.12 0 001.113 1.128zM58.888 72.254h-4.452a.847.847 0 010-1.692h4.452a.847.847 0 010 1.692zM42.575 72.254h-4.451a.847.847 0 010-1.692h4.451a.847.847 0 010 1.692z"
      ></path>
      <path
        fill="#fff"
        d="M54.738 50.105H33.75c-1.28 0-2.437.734-3.005 1.907l-6.132 12.762h44.66l-9.27-12.062a6.638 6.638 0 00-5.265-2.607z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M69.272 64.774l-9.27-12.062a6.638 6.638 0 00-5.264-2.607h-2.17l7.111 9.241c.29.373.023.914-.434.914H26.783l-2.17 4.514h44.66z"
      ></path>
      <path
        fill="#284B9C"
        d="M69.272 65.62H24.613a.837.837 0 01-.712-.395.865.865 0 01-.044-.824l6.131-12.76c.69-1.445 2.17-2.382 3.75-2.382h20.99a7.408 7.408 0 015.92 2.934l9.27 12.062c.2.26.233.598.089.89a.835.835 0 01-.746.475h.011zM25.95 63.927h41.61l-8.224-10.696a5.755 5.755 0 00-4.608-2.28H33.75c-.946 0-1.836.565-2.248 1.422l-5.553 11.554z"
      ></path>
      <path
        fill="#284B9C"
        d="M50.543 65.62a.839.839 0 01-.823-.711L47.305 50.24a.842.842 0 01.69-.97.833.833 0 01.957.699l2.415 14.668a.836.836 0 01-.69.97h-.134v.012zM33.513 65.62c-.066 0-.133 0-.211-.023a.847.847 0 01-.601-1.026l3.706-14.668a.833.833 0 011.013-.61.846.846 0 01.6 1.027l-3.705 14.668a.834.834 0 01-.813.632h.011z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M78.041 42.196h4.452c2.46 0 4.451 2.02 4.451 4.513v6.77H73.59v-6.77c0-2.493 1.992-4.513 4.451-4.513z"
      ></path>
      <path
        fill="#fff"
        d="M78.041 42.196h4.452v5.642c0 .62-.501 1.128-1.113 1.128h-7.79v-2.257c0-2.493 1.992-4.513 4.451-4.513z"
      ></path>
      <path
        fill="#284B9C"
        d="M86.943 54.325H73.588a.847.847 0 01-.834-.846v-6.77c0-2.956 2.37-5.36 5.286-5.36h4.451c2.916 0 5.287 2.404 5.287 5.36v6.77a.847.847 0 01-.835.846zm-12.52-1.692h11.685v-5.924c0-2.02-1.625-3.667-3.617-3.667H78.04c-1.992 0-3.617 1.648-3.617 3.667v5.924z"
      ></path>
      <path
        fill="#284B9C"
        d="M84.16 61.095a.847.847 0 01-.835-.846v-5.924h-6.12v5.924a.847.847 0 01-.835.846.847.847 0 01-.835-.846v-6.77c0-.463.379-.846.835-.846h7.79c.456 0 .835.383.835.846v6.77a.847.847 0 01-.835.846zM22.876 72.254a.857.857 0 01-.656-.316c-4.808-6.093-7.345-13.46-7.345-21.28 0-18.82 15.101-34.131 33.664-34.131 15.302 0 28.7 10.47 32.573 25.466a.846.846 0 01-.6 1.038.84.84 0 01-1.025-.61C75.815 28.172 63.084 18.22 48.54 18.22c-17.639 0-31.995 14.556-31.995 32.44 0 7.435 2.415 14.43 6.978 20.23a.842.842 0 01-.657 1.365h.011z"
      ></path>
      <path
        fill="#284B9C"
        d="M24.1 70.415a1.597 1.597 0 01-.246 2.234c-.678.553-1.669.44-2.203-.248a1.597 1.597 0 01.245-2.234 1.546 1.546 0 012.203.248zM62.023 38.91h-4.125c-.423 0-.773-.335-.773-.74 0-.403.35-.738.773-.738h4.125c.422 0 .773.335.773.739 0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M59.96 40.88c-.422 0-.773-.335-.773-.74V36.2c0-.403.351-.738.774-.738.423 0 .773.335.773.738v3.94c0 .405-.35.74-.773.74zM46.097 29.088h-4.125c-.422 0-.773-.335-.773-.74 0-.403.35-.738.773-.738h4.125c.423 0 .773.335.773.739 0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M44.035 31.058c-.423 0-.773-.335-.773-.739v-3.94c0-.404.35-.74.773-.74.423 0 .773.336.773.74v3.94c0 .404-.35.739-.773.739zM29.898 44.276h-4.125c-.422 0-.773-.335-.773-.739 0-.404.35-.739.773-.739h4.125c.422 0 .773.335.773.739 0 .404-.35.739-.773.739z"
      ></path>
      <path
        fill="#284B9C"
        d="M27.836 46.246c-.423 0-.773-.335-.773-.739v-3.94c0-.404.35-.74.773-.74.423 0 .773.336.773.74v3.94c0 .404-.35.739-.773.739z"
      ></path>
    </svg>
  );
}