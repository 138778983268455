export default function Success() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M75.7 91.64c7.731 0 14-6.268 14-14s-6.269-14-14-14c-7.733 0-14 6.268-14 14s6.267 14 14 14zM27 81.89c6.627 0 12-5.373 12-12s-5.373-12-12-12-12 5.373-12 12 5.373 12 12 12zM48.371 53c11.046 0 20-8.954 20-20s-8.954-20-20-20-20 8.954-20 20 8.954 20 20 20z"
      ></path>
      <path
        fill="#fff"
        d="M77.73 49.64h-11l-3 34h16l-2-34zM66.11 56.64H55.73l-3 27h11l2.38-27z"
      ></path>
      <path fill="#fff" d="M54.95 63.64h-9.22l-3 20h10l2.22-20z"></path>
      <path
        fill="#fff"
        d="M44.68 70.64h-9.95l-3 13h11l1.95-13zM33.11 77.64h-8.38l-3 6h10l1.38-6z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M79.732 83.64l-2-34h-3.82l1.7 28.88c.07 1.15-.85 2.12-2 2.12h-9.61l-.26 3h16-.01z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M61.852 56.64l-1.96 22.18a2 2 0 01-1.99 1.82h-4.83l-.33 3h11l2.38-27h-4.27zM50.62 63.64l-1.69 15.22a2.003 2.003 0 01-1.99 1.78h-3.76l-.45 3h10l2.22-20h-4.33z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M40.23 70.64l-1.25 8.3a2 2 0 01-1.98 1.7h-4.58l-.69 3h11l1.95-13h-4.45z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M28.42 77.64l-.33 1.45a2 2 0 01-1.95 1.55h-2.91l-1.5 3h10l1.39-6h-4.7z"
      ></path>
      <path
        fill="#284B9C"
        d="M79.732 84.39h-16c-.21 0-.41-.09-.55-.24a.75.75 0 01-.19-.57l3-34c.03-.39.36-.68.75-.68h11c.4 0 .73.31.75.71l2 34a.76.76 0 01-.2.56c-.14.15-.34.24-.55.24l-.01-.02zm-15.18-1.5h14.38l-1.91-32.5h-9.61l-2.87 32.5h.01zM82.141 57.39c-.39 0-.72-.31-.75-.71l-.16-2.78a.75.75 0 01.71-.79c.4-.03.77.29.79.71l.16 2.78a.75.75 0 01-.71.79h-.04zM83.249 76.23c-.39 0-.72-.31-.75-.71l-.9-15.37a.75.75 0 01.71-.79c.4-.03.77.29.79.71l.9 15.37a.75.75 0 01-.71.79h-.04z"
      ></path>
      <path
        fill="#284B9C"
        d="M63.73 84.39h-11a.75.75 0 01-.56-.25.772.772 0 01-.19-.58l3-27a.75.75 0 01.75-.67h10.38c.21 0 .41.09.55.24.14.16.21.36.19.57l-2.38 27c-.03.39-.36.68-.75.68l.01.01zm-10.16-1.5h9.48l2.25-25.5h-8.89l-2.83 25.5h-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M52.732 84.39h-10c-.22 0-.43-.09-.57-.26a.729.729 0 01-.17-.6l3-20c.05-.37.37-.64.74-.64h9.22c.21 0 .42.09.56.25.14.16.21.37.19.58l-2.22 20a.75.75 0 01-.75.67zm-9.13-1.5h8.46l2.06-18.5h-7.74l-2.78 18.5z"
      ></path>
      <path
        fill="#284B9C"
        d="M42.73 84.39h-11c-.23 0-.44-.1-.59-.28a.733.733 0 01-.14-.64l3-13c.08-.34.38-.58.73-.58h9.95c.22 0 .43.09.57.26.14.17.21.38.17.6l-1.95 13c-.05.37-.37.64-.74.64zm-10.06-1.5h9.41l1.73-11.5h-8.48l-2.65 11.5h-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M31.73 84.39h-10c-.26 0-.5-.13-.64-.36a.733.733 0 01-.03-.73l3-6a.77.77 0 01.67-.42h8.38c.23 0 .44.1.59.28.14.18.2.41.14.64l-1.39 6c-.08.34-.38.58-.73.58l.01.01zm-8.79-1.5h8.19l1.04-4.5h-6.98l-2.25 4.5z"
      ></path>
      <path
        fill="#fff"
        d="M22.82 30.76a2.17 2.17 0 01-.28-1.08 2.19 2.19 0 013.66-1.62 3.94 3.94 0 013.9-3.43c2.18 0 3.94 1.76 3.94 3.94 0 .25-.03.49-.07.72a3.325 3.325 0 014 3.25c0 1.83-1.49 3.32-3.32 3.32H23.31c-1.42 0-2.58-1.16-2.58-2.58 0-1.26.9-2.3 2.09-2.53v.01z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M37.97 32.55c0-1.83-1.49-3.32-3.32-3.32-.23 0-.46.03-.68.07.04-.24.07-.48.07-.72 0-.32-.05-.63-.12-.92a3.927 3.927 0 00-3.72 3.41 2.19 2.19 0 00-3.66 1.62c0 .39.1.76.28 1.08-1.05.2-1.88 1.05-2.05 2.11h9.88c1.83 0 3.32-1.49 3.32-3.32v-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M34.65 36.62H23.31c-1.84 0-3.33-1.49-3.33-3.33 0-1.29.75-2.44 1.87-2.99-.04-.2-.07-.41-.07-.61a2.945 2.945 0 013.93-2.77 4.7 4.7 0 014.38-3.03c2.55 0 4.64 2.05 4.69 4.59 2.18.07 3.93 1.87 3.93 4.07s-1.83 4.07-4.07 4.07h.01zm-9.93-8.37c-.79 0-1.44.64-1.44 1.44 0 .25.06.49.19.7a.743.743 0 01-.51 1.1c-.86.16-1.48.92-1.48 1.79 0 1.01.82 1.83 1.83 1.83h11.34a2.57 2.57 0 002.57-2.57 2.57 2.57 0 00-3.1-2.51c-.25.05-.5-.02-.68-.2a.737.737 0 01-.21-.67c.04-.2.06-.4.06-.59 0-1.76-1.43-3.19-3.19-3.19-1.6 0-2.95 1.19-3.16 2.78-.04.28-.22.51-.49.61-.26.1-.56.04-.76-.15-.27-.24-.61-.38-.96-.38l-.01.01z"
      ></path>
      <path
        fill="#fff"
        d="M39.82 42.76a2.17 2.17 0 01-.28-1.08 2.19 2.19 0 013.66-1.62 3.94 3.94 0 013.9-3.43c2.18 0 3.94 1.76 3.94 3.94 0 .25-.03.49-.07.72a3.325 3.325 0 014 3.25c0 1.83-1.49 3.32-3.32 3.32H40.31c-1.42 0-2.58-1.16-2.58-2.58 0-1.26.9-2.3 2.09-2.53v.01z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M54.97 44.55c0-1.83-1.49-3.32-3.32-3.32-.23 0-.46.03-.68.07.04-.24.07-.48.07-.72 0-.32-.05-.63-.12-.92a3.927 3.927 0 00-3.72 3.41 2.19 2.19 0 00-3.66 1.62c0 .39.1.76.28 1.08-1.05.2-1.88 1.04-2.05 2.11h9.88c1.83 0 3.32-1.49 3.32-3.32v-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M51.65 48.62H40.31a3.329 3.329 0 01-1.46-6.32c-.04-.2-.07-.41-.07-.61a2.945 2.945 0 013.93-2.77 4.7 4.7 0 014.38-3.03c2.55 0 4.64 2.05 4.69 4.59 2.18.07 3.93 1.87 3.93 4.07s-1.83 4.07-4.07 4.07h.01zm-9.92-8.37c-.79 0-1.44.64-1.44 1.44 0 .25.06.49.19.71a.743.743 0 01-.51 1.1c-.86.17-1.48.92-1.48 1.79 0 1.01.82 1.83 1.83 1.83h11.34a2.57 2.57 0 002.57-2.57c0-1.59-1.45-2.86-3.1-2.51-.25.05-.5-.02-.68-.2a.737.737 0 01-.21-.67c.04-.21.06-.4.06-.59 0-1.76-1.43-3.19-3.19-3.19-1.6 0-2.96 1.19-3.16 2.78-.04.28-.22.51-.49.61-.26.1-.56.04-.76-.15-.27-.24-.61-.37-.96-.37l-.01-.01z"
      ></path>
      <path
        fill="#fff"
        d="M26.82 57.76a2.17 2.17 0 01-.28-1.08 2.19 2.19 0 013.66-1.62 3.94 3.94 0 013.9-3.43c2.18 0 3.94 1.76 3.94 3.94 0 .25-.03.49-.07.72a3.325 3.325 0 014 3.25c0 1.83-1.49 3.32-3.32 3.32H27.31c-1.42 0-2.58-1.16-2.58-2.58 0-1.26.9-2.3 2.09-2.53v.01z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M41.97 59.55c0-1.83-1.49-3.32-3.32-3.32-.23 0-.46.03-.68.07.04-.23.07-.48.07-.72 0-.32-.05-.63-.12-.92a3.927 3.927 0 00-3.72 3.41 2.19 2.19 0 00-3.66 1.62c0 .39.1.76.28 1.08-1.05.2-1.88 1.05-2.05 2.11h9.88c1.83 0 3.32-1.49 3.32-3.32v-.01z"
      ></path>
      <path
        fill="#284B9C"
        d="M38.65 63.62H27.31a3.329 3.329 0 01-1.46-6.32c-.04-.2-.07-.41-.07-.61a2.945 2.945 0 013.93-2.77 4.7 4.7 0 014.38-3.03c2.55 0 4.64 2.05 4.69 4.59 2.18.07 3.93 1.87 3.93 4.07s-1.83 4.07-4.07 4.07h.01zm-9.93-8.37c-.79 0-1.44.64-1.44 1.44 0 .25.06.49.19.71a.743.743 0 01-.51 1.1c-.86.16-1.48.92-1.48 1.79 0 1.01.82 1.83 1.83 1.83h11.34a2.57 2.57 0 002.57-2.57c0-1.59-1.4-2.87-3.1-2.51-.25.05-.5-.02-.68-.2a.737.737 0 01-.21-.67c.04-.2.06-.4.06-.59 0-1.76-1.43-3.19-3.19-3.19-1.6 0-2.96 1.19-3.16 2.78-.04.28-.22.51-.49.61-.26.1-.56.04-.76-.15-.27-.24-.61-.38-.96-.38h-.01z"
      ></path>
      <path
        fill="#fff"
        d="M71.73 45.63c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M71.73 19.63c-.64 0-1.27.06-1.88.15 4.67 1.97 7.95 6.59 7.95 11.98 0 6.54-4.83 11.93-11.12 12.85 1.55.66 3.26 1.02 5.05 1.02 7.18 0 13-5.82 13-13s-5.82-13-13-13z"
      ></path>
      <path
        fill="#284B9C"
        d="M71.73 46.38c-7.58 0-13.75-6.17-13.75-13.75s6.17-13.75 13.75-13.75 13.75 6.17 13.75 13.75-6.17 13.75-13.75 13.75zm0-26c-6.75 0-12.25 5.5-12.25 12.25s5.5 12.25 12.25 12.25 12.25-5.5 12.25-12.25-5.5-12.25-12.25-12.25z"
      ></path>
      <path
        fill="#fff"
        d="M80.01 30.83l-3.33-3.33-6.37 6.35-3.53-3.54-3.33 3.33 6.86 6.86 9.7-9.67z"
      ></path>
      <path
        fill="#284B9C"
        d="M70.31 41.25c-.19 0-.38-.07-.53-.22l-6.86-6.86a.754.754 0 010-1.06l3.33-3.33c.29-.29.77-.29 1.06 0l3 3 5.84-5.82c.29-.29.77-.29 1.06 0l3.33 3.33a.75.75 0 010 1.06l-9.7 9.67c-.15.15-.34.22-.53.22v.01zm-5.8-7.61l5.8 5.8 8.64-8.62-2.27-2.27-5.84 5.82c-.29.29-.77.29-1.06 0l-3-3-2.27 2.27zM40.11 23.43h-1.38c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h1.38c.41 0 .75.34.75.75s-.34.75-.75.75zM44.732 23.43h-1.38c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h1.38c.41 0 .75.34.75.75s-.34.75-.75.75zM41.73 26.43c-.41 0-.75-.34-.75-.75V24.3c0-.41.34-.75.75-.75s.75.34.75.75v1.38c0 .41-.34.75-.75.75zM41.73 21.81c-.41 0-.75-.34-.75-.75v-1.38c0-.41.34-.75.75-.75s.75.34.75.75v1.38c0 .41-.34.75-.75.75zM50.11 31.43h-1.38c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h1.38c.41 0 .75.34.75.75s-.34.75-.75.75zM54.732 31.43h-1.38c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h1.38c.41 0 .75.34.75.75s-.34.75-.75.75zM51.73 34.43c-.41 0-.75-.34-.75-.75V32.3c0-.41.34-.75.75-.75s.75.34.75.75v1.38c0 .41-.34.75-.75.75zM51.73 29.81c-.41 0-.75-.34-.75-.75v-1.38c0-.41.34-.75.75-.75s.75.34.75.75v1.38c0 .41-.34.75-.75.75zM24.11 44.43h-1.38c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h1.38c.41 0 .75.34.75.75s-.34.75-.75.75zM28.72 44.43h-1.38c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h1.38c.41 0 .75.34.75.75s-.34.75-.75.75zM25.719 47.43c-.41 0-.75-.34-.75-.75V45.3c0-.41.34-.75.75-.75s.75.34.75.75v1.38c0 .41-.34.75-.75.75zM25.719 42.81c-.41 0-.75-.34-.75-.75v-1.38c0-.41.34-.75.75-.75s.75.34.75.75v1.38c0 .41-.34.75-.75.75z"
      ></path>
    </svg>
  );
}