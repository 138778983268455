export default function Numeri(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill={props.color ? props.color : "#ccdae4"}
        d="M56.504 85.33c7.732 0 14-6.268 14-14s-6.268-14-14-14-14 6.268-14 14 6.268 14 14 14zM80.106 49.89c6.627 0 12-5.373 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.627 5.372 12 12 12zM39.055 54c11.045 0 20-8.954 20-20s-8.955-20-20-20c-11.046 0-20 8.954-20 20s8.954 20 20 20z"
      ></path>
      <path
        fill="#284B9C"
        d="M5.345 74.25h-1.52A.832.832 0 013 73.424c0-.451.374-.825.826-.825h1.519c.45 0 .825.374.825.825a.832.832 0 01-.825.826zM10.419 74.25h-1.52a.832.832 0 01-.825-.826c0-.451.374-.825.826-.825h1.519c.451 0 .825.374.825.825a.832.832 0 01-.825.826zM7.119 77.552a.832.832 0 01-.826-.826v-1.519c0-.45.374-.825.826-.825.45 0 .825.374.825.825v1.52a.832.832 0 01-.825.825zM7.119 72.466a.832.832 0 01-.826-.825v-1.52c0-.45.374-.825.826-.825.45 0 .825.375.825.826v1.519a.832.832 0 01-.825.825zM78.344 93.25h-1.518a.831.831 0 01-.826-.826c0-.451.374-.825.826-.825h1.519c.45 0 .825.374.825.825a.832.832 0 01-.826.826zM83.419 93.25h-1.52a.832.832 0 01-.825-.826c0-.451.374-.825.826-.825h1.519c.451 0 .825.374.825.825a.832.832 0 01-.825.826zM80.118 96.552a.831.831 0 01-.825-.826v-1.519c0-.45.374-.825.825-.825.452 0 .826.374.826.825v1.52a.831.831 0 01-.826.825zM80.118 91.466a.831.831 0 01-.825-.825v-1.52c0-.45.374-.825.825-.825.452 0 .826.375.826.826v1.519a.831.831 0 01-.826.825zM59.344 17.25h-1.519a.831.831 0 01-.825-.826c0-.451.374-.825.825-.825h1.52c.45 0 .825.374.825.825a.832.832 0 01-.826.826zM64.419 17.25h-1.52a.832.832 0 01-.825-.826c0-.451.375-.825.826-.825h1.519c.451 0 .825.374.825.825a.832.832 0 01-.825.826zM61.118 20.552a.831.831 0 01-.825-.826v-1.519c0-.45.374-.825.825-.825.452 0 .826.374.826.825v1.52a.831.831 0 01-.826.825zM61.118 15.466a.831.831 0 01-.825-.825v-1.52c0-.45.374-.825.825-.825.452 0 .826.375.826.826v1.519a.831.831 0 01-.826.825z"
      ></path>
    </svg>
  );
}