import Typography from "@mui/material/Typography";
import ArrowOutward from "@mui/icons-material/ArrowOutward";

import { MenuItemPrimary } from "@components/Common/Menu/MenuItemPrimary";
import MenuItemMobile from "@components/Common/Menu/MenuItemMobile";
import Link from "next/link";
import { getUrlAttributes } from "./media";


export const renderMainMenu = (menu) => menu?.map((item) => <MenuItemPrimary key={item.id} item={item} /> )

export const renderMainMenuMobile = (menu, close, parentOpen) => menu?.filter(el => el.field_layout !== 'card').map((item, index) => <MenuItemMobile key={index} item={item} close={close} parentOpen={parentOpen}/> )

export const renderFooterMenu = (menu) => {
  return (

    menu.map((item, index) =>  {
      
      let external: boolean = item?.options?.external && !item.url.includes(process.env.NEXT_PUBLIC_BASE_URL);
      const {href, title} = getUrlAttributes(item);
      return(
        <Typography key={index} variant="bodyM" component={Link} href={href} title={title} target={external ? '_blank' : '_self'} mb={4}>
          {title} {external && <ArrowOutward />}
        </Typography>
      )
    })
  )
}

export const renderCopyrightMenu = (menu) => menu?.map((item, index) => {
  const {href, title, target} = getUrlAttributes(item);

  return (
    <Link key={index} href={href} target={target} title={title}>
      {item.title}
    </Link>
  )
})