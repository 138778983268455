import styles from "@components/Common/Header/Header.module.scss";
import Row from "@components/Modules/Utils/Row/Row";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import { useTranslation } from "next-i18next";
import Image from "next/legacy/image";
import { MenuItemSecondary } from "../MenuItemSecondary";

import { getThumbnailUrl } from "@helper/media";
import { MenuItemModel } from "../Menu.model";

const LayoutDefault: React.FC<{item: MenuItemModel}> = ({item}) => {
  const { t } = useTranslation();
  const isAziende: boolean = item.title == t('companies') ? true : false;
  
  const companies = {
    title: t('header.menu.caseHistory.michelin.title'),
    image: `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/sites/default/files/2022-09/michelin-italia.png`,
    url: t('header.menu.caseHistory.michelin.ctaUrl')
  }
  const publicAdministrations = {
    title: t('header.menu.caseHistory.cremona.title'),
    image: `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/sites/default/files/2022-09/EDN-img-half-21.jpg`,
    url: t('header.menu.caseHistory.cremona.ctaUrl')
  }

  const items: MenuItemModel[] = item?.items?.filter(el => el?.field_layout !== 'card')
  const card: MenuItemModel | undefined = item?.items?.find(el => el?.field_layout === 'card') || {
    id: isAziende ? companies.title : publicAdministrations.title,
    title: isAziende ? companies.title : publicAdministrations.title,
    url: isAziende ? companies.url : publicAdministrations.url,
    field_menu_subtitle: t('header.menu.caseHistory.label'),
  };
  const image: string = card?.field_menu_image ? getThumbnailUrl(card?.field_menu_image) : null

  return (
    <div className={styles.dropdownMenu}>
      <Container maxWidth={false}>
        <Row spacing={0} justifyContent={'space-between'}>

          <Grid item lg={4} position="relative" borderRight={'1px solid #DFE1E6'}>
            <List disablePadding>
              {items?.map((item: MenuItemModel) => <MenuItemSecondary key={item.id} item={item} />)}
            </List>
          </Grid>
          
          <Grid item lg={4}>
            <div className={styles.case}>
              <div className={styles.caseImage}>
                <Image
                  src={ image ? image : isAziende ? companies.image : publicAdministrations.image }
                  alt={card?.title}
                  layout="fill"
                  objectFit="cover"
                  quality={100} />
              </div>
              <Typography variant="overline" component="div" mt={24}>
                { card?.field_menu_subtitle }
              </Typography>
              <Typography variant="h4" textTransform='initial' mt={10}>
                { card?.title }
              </Typography>
              <Button
                variant="outlined"
                href={ card?.url }
                sx={{ marginTop: 34 }}
              >{t("discoverMore")}</Button>
            </div>
          </Grid>

        </Row>
      </Container>
    </div>
  )
}

export default LayoutDefault