export default function Social() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="104"
      fill="none"
      viewBox="0 0 104 104"
    >
      <path
        fill="#000"
        fillOpacity="0.1"
        d="M34.462 92c8.22 0 14.884-6.664 14.884-14.884s-6.664-14.883-14.884-14.883-14.884 6.663-14.884 14.883c0 8.22 6.664 14.884 14.884 14.884zM23.758 47.349c7.045 0 12.757-5.712 12.757-12.757 0-7.046-5.712-12.758-12.758-12.758C16.712 21.834 11 27.546 11 34.592c0 7.045 5.712 12.757 12.758 12.757zM71.079 54.525c11.743 0 21.262-9.52 21.262-21.262C92.341 21.52 82.821 12 71.08 12c-11.743 0-21.263 9.52-21.263 21.263 0 11.742 9.52 21.262 21.263 21.262z"
      ></path>
      <path
        fill="#fff"
        d="M84.092 80.04H46.223l-12.098 6.379v-6.38h-4.253a2.132 2.132 0 01-2.126-2.125V35.389c0-1.17.957-2.127 2.126-2.127h54.22c1.169 0 2.126.957 2.126 2.127v42.525c0 1.17-.957 2.126-2.126 2.126z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M84.09 80.04H51.133c0-18.785 15.234-34.02 34.02-34.02h1.063v31.894c0 1.17-.957 2.126-2.126 2.126z"
      ></path>
      <path
        fill="#284B9C"
        d="M34.121 87.216a.844.844 0 01-.414-.117.789.789 0 01-.383-.68v-5.582h-3.455a2.922 2.922 0 01-2.924-2.923V35.389a2.922 2.922 0 012.924-2.924h54.22a2.922 2.922 0 012.923 2.924v42.525a2.922 2.922 0 01-2.924 2.923H46.422L34.504 87.12a.775.775 0 01-.372.096h-.01zM29.87 34.06a1.33 1.33 0 00-1.329 1.329v42.525a1.33 1.33 0 001.329 1.329h4.252c.436 0 .798.361.798.797v5.06l10.929-5.762a.776.776 0 01.372-.095h37.868a1.33 1.33 0 001.33-1.33V35.39a1.33 1.33 0 00-1.33-1.33h-54.22z"
      ></path>
      <path
        fill="#fff"
        d="M18.176 26.884h57.408v41.462c0 1.169-.956 2.126-2.126 2.126H20.302a2.132 2.132 0 01-2.126-2.126V26.884z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M51.134 41.767h24.452v26.578c0 1.17-.957 2.127-2.126 2.127H22.43c0-15.84 12.864-28.705 28.704-28.705z"
      ></path>
      <path
        fill="#fff"
        d="M20.302 18.379h53.156c1.17 0 2.126.956 2.126 2.126v6.379H18.176v-6.38c0-1.169.957-2.125 2.126-2.125z"
      ></path>
      <path
        fill="#B8D6EC"
        d="M33.06 22.631h42.525v4.253H30.934v-2.126c0-1.17.956-2.127 2.126-2.127z"
      ></path>
      <path
        fill="#284B9C"
        d="M73.459 71.27H20.302a2.922 2.922 0 01-2.923-2.924V26.884c0-.436.361-.798.797-.798h57.409c.436 0 .797.362.797.798v41.462a2.922 2.922 0 01-2.923 2.923zM18.974 27.68v40.665a1.33 1.33 0 001.328 1.328H73.46a1.33 1.33 0 001.328-1.328V27.68H18.974z"
      ></path>
      <path
        fill="#284B9C"
        d="M75.585 27.681H18.176a.803.803 0 01-.797-.797v-6.38a2.922 2.922 0 012.923-2.923H73.46a2.922 2.922 0 012.923 2.924v6.379a.803.803 0 01-.797.797zm-56.611-1.595h55.813v-5.581a1.33 1.33 0 00-1.328-1.329H20.302a1.33 1.33 0 00-1.328 1.329v5.581z"
      ></path>
      <path
        fill="#284B9C"
        d="M59.637 23.428a.797.797 0 01-.563-1.36c.308-.298.829-.298 1.127 0a.815.815 0 01.233.563.834.834 0 01-.223.564.84.84 0 01-.574.233zM64.156 22.631c0-.436.362-.797.798-.797.446 0 .797.361.797.797a.79.79 0 01-.797.798.796.796 0 01-.798-.798zM70.27 23.428a.796.796 0 01-.797-.797c0-.212.085-.404.234-.563.308-.298.84-.298 1.127 0a.827.827 0 01.244.563.807.807 0 01-.245.564.797.797 0 01-.563.233z"
      ></path>
      <path
        fill="#fff"
        d="M63.892 59.84h-9.568l-7.442 6.38-7.442-6.38h-9.568a2.132 2.132 0 01-2.126-2.126v-20.2c0-1.169.957-2.125 2.126-2.125h34.02c1.17 0 2.127.956 2.127 2.126v20.2c0 1.169-.957 2.125-2.127 2.125z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M63.89 59.84H54.32l-7.442 6.38-7.442-6.38H33.06c0-7.048 5.709-12.757 12.757-12.757h20.2v10.631c0 1.17-.957 2.127-2.127 2.127z"
      ></path>
      <path
        fill="#284B9C"
        d="M46.879 67.017a.81.81 0 01-.521-.192l-7.219-6.187h-9.27a2.922 2.922 0 01-2.924-2.924v-20.2a2.922 2.922 0 012.924-2.923h34.02a2.922 2.922 0 012.923 2.924v20.2a2.922 2.922 0 01-2.923 2.923h-9.27l-7.22 6.187a.789.789 0 01-.52.192zm-17.01-30.831a1.33 1.33 0 00-1.329 1.329v20.2a1.33 1.33 0 001.329 1.328h9.568c.191 0 .372.064.52.191l6.922 5.933 6.92-5.933a.789.789 0 01.522-.19h9.568a1.33 1.33 0 001.329-1.33v-20.2a1.33 1.33 0 00-1.33-1.328h-34.02z"
      ></path>
      <path
        fill="#fff"
        d="M42.628 42.544a4.864 4.864 0 011.032-1.202 4.71 4.71 0 00-.957-.106c-.372 0-.734.053-1.074.127.393.34.733.734 1 1.18z"
      ></path>
      <path
        fill="#fff"
        d="M55.917 46.041c0-2.657-2.18-4.816-4.859-4.816a4.85 4.85 0 00-4.178 2.371 4.864 4.864 0 00-4.178-2.37c-2.679 0-4.858 2.157-4.858 4.815 0 5.667 9.036 11.131 9.036 11.131s9.037-5.464 9.037-11.13z"
      ></path>
      <path
        fill="#BAE1A2"
        d="M51.06 41.236c-.373 0-.734.053-1.085.128 1.02.882 1.69 2.168 1.69 3.614 0 4.253-5.07 8.388-7.622 10.185a30.928 30.928 0 002.839 2.01s9.036-5.465 9.036-11.132c0-2.657-2.18-4.816-4.858-4.816v.011z"
      ></path>
      <path
        fill="#fff"
        d="M46.881 43.607a4.888 4.888 0 013.094-2.243 4.843 4.843 0 00-3.168-1.191 4.803 4.803 0 00-3.147 1.17 4.85 4.85 0 013.221 2.264z"
      ></path>
      <path
        fill="#284B9C"
        d="M46.88 57.98a.844.844 0 01-.414-.117c-.383-.234-9.42-5.773-9.42-11.822 0-3.093 2.542-5.613 5.657-5.613 1.594 0 3.115.68 4.178 1.84a5.688 5.688 0 014.178-1.84c3.115 0 5.656 2.52 5.656 5.613 0 6.05-9.037 11.588-9.42 11.822a.814.814 0 01-.414.117zm-4.177-15.947c-2.244 0-4.061 1.797-4.061 4.019 0 4.497 6.516 9.058 8.239 10.195 1.722-1.127 8.239-5.698 8.239-10.195 0-2.211-1.818-4.019-4.061-4.019a4.095 4.095 0 00-3.498 1.978c-.287.489-1.084.489-1.371 0a4.095 4.095 0 00-3.498-1.978h.01zM83.026 73.704h-4.252a.803.803 0 01-.797-.797c0-.436.361-.798.797-.798h4.252c.436 0 .798.362.798.797a.803.803 0 01-.798.798z"
      ></path>
      <path
        fill="#284B9C"
        d="M80.899 75.83a.803.803 0 01-.797-.797V70.78c0-.436.361-.797.797-.797.436 0 .797.361.797.797v4.252a.803.803 0 01-.797.798zM80.899 67.314a.797.797 0 100-1.594.797.797 0 000 1.594zM80.899 63.062a.797.797 0 100-1.595.797.797 0 000 1.595zM80.899 57.746a.797.797 0 100-1.594.797.797 0 000 1.594zM80.899 50.304a.797.797 0 100-1.594.797.797 0 000 1.594zM53.769 76.585H43.372a.803.803 0 01-.798-.798c0-.436.362-.797.798-.797h10.397c.436 0 .797.362.797.797a.803.803 0 01-.797.798zM60.373 76.585h-2.35a.803.803 0 01-.796-.798c0-.436.361-.797.797-.797h2.35c.435 0 .797.362.797.797a.803.803 0 01-.798.798z"
      ></path>
    </svg>
  );
}